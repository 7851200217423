import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { Loader } from "shared/ui/Loader";
import { useAppSelector } from "app/store/hooks/redux";

export type ColumnHeaderType = {
    name: string;
    width: string;
}

type PropsType = {
    columns: ColumnHeaderType[];
    children: ReactNode;
    isFetching?: boolean;
}

const Table = ({children, columns, isFetching}: PropsType) => {
    const ref = useRef<HTMLDivElement>(null);
    const {isTableStick} = useAppSelector(state => state.userReducer);
    const [height, setHeight] = useState(0);

    useLayoutEffect(() => {
        if (ref.current != null) {
            setHeight(ref.current.clientHeight);
        }
    }, [children])
    
    return (
        <div className={styles.table}>
            {(isTableStick && (height == 0 ? false : height > 20)) && <div className={styles.sticksContainer}>
                {columns.map((column, idx) =>
                    <div className={styles.stick} style={{marginRight: "calc(" + column.width + " - 1px)", backgroundColor: idx == 0 ? "transparent" : ""}}/>
                )}
            </div>}
            <div className={styles.header}>
                {columns.map(column =>
                    <div className={styles.column} style={{width: column.width}}>{column.name}</div>
                )}
            </div>
            <div className={styles.body} ref={ref}>
                {isFetching && <Loader inlineContainerClasses={styles.loader}/>}
                {!isFetching && children}
            </div>
        </div>
    );
};

export {
    Table
};