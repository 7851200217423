import { EmployeeAccessLevelEnum } from "api";
import { UserRoles } from "app/store/consts";
import { LinkPagesEnum } from "app/store/enums/linkPages";

export type MenuItemType = {
    name: string, 
    path: string, 
    subItem?: Array<MenuItemType>,
    iconPath?: string,
    allowedFor?: Array<string>,
    notAllowedFor?: Array<string>,
    accessLevel?: EmployeeAccessLevelEnum,
};
export type MenuItemsType = Array<MenuItemType>;
export const menuItems: MenuItemsType = [
    {name: "Главная", path: LinkPagesEnum.Home, iconPath:"assets/icons/menu_home.svg"},
    {name: "Аналитика", path: LinkPagesEnum.Analytics, iconPath:"assets/icons/menu_dashboard.png"},
    {name: "Календарь", path: LinkPagesEnum.Calender, iconPath:"assets/icons/menu_dashboard.png"},
    // {name: "Терминал", path: LinkPagesEnum.Terminal, iconPath:"assets/icons/menu_dashboard.png", allowedFor: [UserRoles.employeeRole, UserRoles.warehouseOwnerRole]},
    {name: "Терминал", path: '', subItem: [
        {name: "Приёмка", path: LinkPagesEnum.Inspection},
        {name: "Перемещение", path: LinkPagesEnum.Placement},
        {name: "Упаковка", path: LinkPagesEnum.Package},
        {name: "Отгрузка", path: LinkPagesEnum.Ship},
    ], iconPath:"assets/icons/menu_bills.svg", allowedFor : [UserRoles.employeeRole, UserRoles.warehouseOwnerRole]},
    {name: "Накладные", path: '', subItem: [
        {name: "Приходные", path: LinkPagesEnum.AcceptanceBills},
        {name: "Транзитные", path: LinkPagesEnum.PlacementBills, allowedFor : [UserRoles.employeeRole, UserRoles.warehouseOwnerRole]},
        {name: "Упаковочные", path: LinkPagesEnum.PackageBills},
        {name: "Отгрузочные", path: LinkPagesEnum.ShipmentBills},
    ], iconPath:"assets/icons/menu_bills.svg"},
    // {name: "Инвентаризация", path: '', subItem: [
    //     {name: "Товар", path: LinkPagesEnum.Inventory},
    //     {name: "Расходники", path: LinkPagesEnum.Consumables}
    // ], iconPath:"assets/icons/menu_inventory.png",
    // allowedFor : [UserRoles.employeeRole, UserRoles.warehouseOwnerRole]},
    {name: "Расходники", path: LinkPagesEnum.Consumables, allowedFor : [UserRoles.employeeRole, UserRoles.warehouseOwnerRole]},
    {name: "Товары", path: LinkPagesEnum.Products, iconPath:"assets/icons/menu_goods.svg"},
    {name: "Склад", path: LinkPagesEnum.WarehouseScheme, iconPath:"assets/icons/menu_goods.svg", allowedFor: [UserRoles.employeeRole, UserRoles.warehouseOwnerRole]},
    {name: "Ячейки", path: LinkPagesEnum.Cells, iconPath:"assets/icons/menu_storage.png", allowedFor : [UserRoles.employeeRole, UserRoles.warehouseOwnerRole]},
    {name: "Процессы", path: LinkPagesEnum.Processes, iconPath:"assets/icons/menu_storage.png", allowedFor : [UserRoles.employeeRole, UserRoles.warehouseOwnerRole]},
    // {name: "Документы", path: '', subItem: [
    //     {name: "Счета", path: LinkPagesEnum.Soon},
    //     {name: "Отчеты", path: LinkPagesEnum.Documents}
    // ], iconPath:"assets/icons/menu_documents.svg"},
    {name: "Документы", path: LinkPagesEnum.Documents},
    {name: "Конструктор", path: LinkPagesEnum.WarehouseDesigner, allowedFor : [UserRoles.employeeRole, UserRoles.warehouseOwnerRole]},
    {name: "Интеграции", path: '', subItem: [
        {name: "Маркетплейсы", path: LinkPagesEnum.Integrations},
        {name: "Транспортные компании", path: LinkPagesEnum.Soon},
        {name: "Системы учета", path: LinkPagesEnum.Soon}
    ], iconPath:"assets/icons/menu_integration.svg"},
    {name: "Сотрудники", path: '', iconPath:"assets/icons/employees.svg", subItem: [
        {name: "Аккаунты", path: LinkPagesEnum.Employees},
        {name: "График", path: LinkPagesEnum.Soon},
        {name: "Оплата", path: LinkPagesEnum.Soon},
    ], allowedFor : [UserRoles.employeeRole, UserRoles.warehouseOwnerRole]},
    {name: "Счета", path: LinkPagesEnum.PaymentBills },
    // {name: "Организации", path: LinkPagesEnum.Organizations },
    {name: "Сотрудничество", path: LinkPagesEnum.Cooperations, allowedFor: [UserRoles.employeeRole, UserRoles.userRole], notAllowedFor: [UserRoles.warehouseOwnerRole]},
    {name: "Клиенты", path: LinkPagesEnum.Users, iconPath:"assets/icons/users.svg", allowedFor : [UserRoles.employeeRole, UserRoles.warehouseOwnerRole]},
    {name: "Настройки", path: LinkPagesEnum.Settings, iconPath:"assets/icons/menu_settings.svg"},
    {name: "База информации", path: LinkPagesEnum.InformationBase, iconPath:"assets/icons/menu_settings.svg"},
];