import { BaseSharedComponentType } from "types";
import styles from "./index.module.scss";
import clsx from "clsx";

type Props = {
    name: string;
    value?: string | number | undefined | null;
    valueInlineStyles?: React.CSSProperties;
    nameInlineStyles?: React.CSSProperties;
} & BaseSharedComponentType;

const ListItemText = ({name, value, inlineStyles, inlineClasses, valueInlineStyles, nameInlineStyles}: Props) => {
    return (
        <div className={clsx(styles.container, inlineClasses)} style={inlineStyles}>
            <div className={styles.name} style={nameInlineStyles}>
                {name}
            </div>
            <div className={styles.value} style={valueInlineStyles}>
                {value}
            </div>
        </div>
    )
}

export {
    ListItemText
}