import { Button } from "shared/ui/Button";
import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import { Container } from "shared/ui/Container";
import {Modal} from "shared/ui/Modal";
import { useState } from "react";
import { Input } from "shared/ui/Input";

const integrations = [
    {id: 1,title: "OZON", imgSrc: "./assets/images/ozon.png", onClick: () => {}},
    {id: 2,title: "WILDBERRIES", imgSrc: "./assets/images/wb.png", onClick: () => {}},
    {id: 3,title: "МОЙ СКЛАД", imgSrc: "./assets/images/ozon.png", onClick: () => {}},
    {id: 4,title: "1C", imgSrc: "./assets/images/ozon.png", onClick: () => {}},
    {id: 5,title: "САМОКАТ", imgSrc: "./assets/images/samokat.png", onClick: () => {}},
]

const IntegrationsPage = () => {
    const [token, setToken] = useState("");
    const [selectedServiceForIntegration, setSelectedServiceForIntegration] = useState<number | null>(null);
    const [isCreateIntegration, setIsCreateIntegration] = useState(false);

    return (
        <Layout>
            <div className={styles.wrapper}>
                {integrations.map((integration) =>
                    <Container inlineClasses={styles.container}>
                        <div className={styles.top}>
                            <img className={styles.image} src={integration.imgSrc}/>
                            <div className={styles.title}>
                                {integration.title}
                            </div>
                        </div>
                        <Button
                            text="Установить интеграцию"
                            onClick={() => {
                                setSelectedServiceForIntegration(integration.id)
                                setIsCreateIntegration(true)
                            }}
                            />
                    </Container>
                )}
            </div>
            <Modal
                    isCloseCloseIcon
                    isOpen={isCreateIntegration}
                    close={() => setIsCreateIntegration(false)}
                >
                <div>
                    <div className={styles.title}>
                        Создание интеграции с сервисом {selectedServiceForIntegration? integrations.find(i => i.id === selectedServiceForIntegration)?.title : ""}
                    </div>
                    <Input  
                        classNamesContainer={styles.input}
                        placeholder="token"
                        value={token}
                        onChange={e => setToken(e.target.value)}
                        />
                    <Button
                        inlineStyles={{margin: "0 auto"}}
                        text="Создать интеграцию"
                        onClick={() => {
                            setIsCreateIntegration(false)
                            setSelectedServiceForIntegration(null);
                        }}
                    />
                </div>
            </Modal>
        </Layout>
    );
};

export {
    IntegrationsPage
}