import { getPercentColor } from "functions/color";
import styles from "./index.module.scss";

type Props = {
    percent?: number | undefined | null;
}

const PercentBar = ({percent}: Props) => (
    <div className={styles.percentBar} style={{backgroundColor: getPercentColor(percent)}}>
        {percent}%
    </div>
)

export {
    PercentBar
}