import { isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import {AlertMessage, AlertType, alertsSlice} from '../store/reducers/alertsSlice'
import {store} from "../ui"

type ApiRespons ={
  data? : Details
}

type Details = {
  Title? : string,
  Detail: string
}

type BaseMeta = {
  baseQueryMeta: BaseQueryMeta
}

type BaseQueryMeta = {
  request: BaseRequest
}

type BaseRequest = {
  method: string
}

export const rtkQueryAlertMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (window.location.pathname != "/information") 
    {
    const {setAlert, removeAlert} = alertsSlice.actions;
    
    if (isRejectedWithValue(action)) {
      const details = (action.payload as ApiRespons).data;
      
      if (details?.Detail || details?.Title)
      {
        const alert = {
          title: details?.Title, 
          message: details?.Detail,
          type: AlertType.Error,
          identifier:action.meta.requestId
          } as AlertMessage;

        store.dispatch(setAlert(alert))
        
        setTimeout(() => {
          store.dispatch(removeAlert(alert.identifier))
          }, 30000)
      }
      else{
        // const alert: AlertMessage = {
        //   title: "Неизвестная ошибка",
        //   message: "Обратитесь в поддержку", 
        //   type: AlertType.Error,
        //   identifier:action.meta.requestId
        // };

        // store.dispatch(setAlert(alert))
        
        // setTimeout(() => {
        //   store.dispatch(removeAlert(alert.identifier))
        //   }, 2500)
        }
      }
    else if(isFulfilled(action) && ((action.meta as any as BaseMeta).baseQueryMeta.request.method == "POST" || (action.meta as any as BaseMeta).baseQueryMeta.request.method == "PUT")){
        // const alert = {
        //   title: "Успешно",
        //   message: "Успешно", 
        //   type: AlertType.Ok,
        //   identifier:action.meta.requestId
        // } as AlertMessage;

        // store.dispatch(setAlert(alert))
        
        // setTimeout(() => {
        //   store.dispatch(removeAlert(alert.identifier))
        //   }, 2500)
      }
      
    }
    return next(action)
  }