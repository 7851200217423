import Layout from "widgets/Layout/Layout";
import React, { useState } from "react";
import { tableHeadName } from "pages/AcceptanceBills/consts";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import {Button} from "shared/ui/Button";
import { SearchInput } from "shared/ui/SearchInput";
import { MultipleSelect } from "shared/ui/MultipleSelect";
import { Sort } from "shared/ui/Sort";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { Table } from "widgets/Table";
import { getBillStatus } from "functions/billStatus";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { useGetBillsQuery, usePostDocumentsExcelBillsMutation } from "api";
import { FileExportIcon } from "shared/ui/icons/icons";
import { BASE_URL_FILES, IS_PC } from "app/store/consts";
import { OptionType } from "shared/ui/Select";
import { BillType } from "enums/billType";
import { getLocalDateTime } from "functions/time";
import { useAppSelector } from "app/store/hooks/redux";
import { ActionsSelector } from "features/ActionsSelector";
import { List } from "widgets/mobile/List";
import { ListItem } from "widgets/mobile/List/components/Item";
import { ListItemText } from "widgets/mobile/List/components/ListItemText";
import { ActionsSelector as ActionsSelectorMobile } from "features/mobile/ActionsSelector";
import { ButtonsContainer } from "shared/ui-mobile/ButtonsContainer";

const options: OptionType[] = [
    {key: 1, value: "ID"},
    {key: 2, value: "Статус"},
    {key: 3, value: "Юр лицо"},
    {key: 4, value: "Склад"},
    {key: 5, value: "Создано"},
    {key: 6, value: "Комментарий"},
];

const ShipmentBillsPage = () => {
    const {isClient} = useAppSelector(state => state.userReducer);

    const navigate = useNavigate();
    const {data: bills, refetch, isFetching: isBillsFetching} = useGetBillsQuery({billType: BillType.Shipment});

    const [selectedBillId, setSelectedBillId] = useState<number | null>(null);

    const [selectedOptions, setSelectedOptions] = useState<number[] | null>([1]);
    const [searchText, setSearchText] = useState("");

    const filteredData = bills?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el.id, key: 1},
        {value: el.status, key: 2},
        {value: el.organizationName, key: 3},
        {value: el.warehouseName, key: 4},
        {value: el.dateCreated, key: 5},
        {value: el.note, key: 6},
    ]));
    
    const [selectedOption, setSelectedOption] = useState(1);

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2.id, value1: el1.id, key: 1},
        {value2: el2.status,  value1: el1.status, key: 2},
        {value2: el2.organizationName, value1: el1.organizationName, key: 3},
        {value2: el2.warehouseName, value1: el1.warehouseName, key: 4},
        {value2: el2.dateCreated, value1: el1.dateCreated, key: 5},
        {value2: el2.note, value1: el1.note, key: 6},
    ], selectedOption, isDesc));
    
    const [event, setEvent] = useState<React.MouseEvent<HTMLDivElement, MouseEvent> | null>(null);
    const [getExcelName] = usePostDocumentsExcelBillsMutation();
    
    if (IS_PC) {
    return (
        <Layout>
            <>
                <div className={styles.header}>
                    <div style={{display: "flex"}}>
                        <Button inlineStyles={{marginRight: 10}} text="Обновить" onClick={refetch} />
                        {isClient && <Button inlineStyles={{marginRight: 10}} text="Добавить накладную" onClick={() => navigate(LinkPagesEnum.AddingShipmentBill)}/>}
                        <Button text="Скачать шаблон" onClick={() => window.open(BASE_URL_FILES + "productsTemplate.xlsx","_blank")}/>
                    </div>
                    <div style={{display: 'flex'}}>
                        <SearchInput 
                            value={searchText} 
                            onChange={setSearchText}
                            inlineStyles={{marginRight: 10}}
                            classNames={styles.searchInput}
                            />
                        <MultipleSelect 
                            text="Параметры"
                            options={options} 
                            selectedOptionKeys={selectedOptions} 
                            setSelectedOptions={setSelectedOptions}
                            inlineStyles={{marginRight: 10}}
                            inlineClasses={styles.select}
                            />
                        <Sort 
                            options={options}
                            selectedOptionKey={selectedOption}
                            setSelectedOption={setSelectedOption}
                            setIsDesc={setIsDesc}
                            isDesc={isDesc}
                            selectClassNames={styles.select}
                            />
                    </div>
                </div>
                <Table columns={tableHeadName} isFetching={isBillsFetching}>
                    {
                        sortedData?.map(el => (
                            <TableItem  hover inlineStyles={{marginBottom: 10}} onClick={(e) => {
                                setEvent(e); 
                                setSelectedBillId(el.id!)
                            }}>
                                <TableItemContainer width={tableHeadName[0].width}>{el.id}</TableItemContainer>
                                <TableItemContainer width={tableHeadName[1].width}>{getBillStatus(el.status)}</TableItemContainer>
                                <TableItemContainer width={tableHeadName[2].width}>{el.organizationName}</TableItemContainer>
                                <TableItemContainer width={tableHeadName[3].width}>{el.warehouseName}</TableItemContainer>
                                <TableItemContainer width={tableHeadName[4].width}>{getLocalDateTime(el.dateCreated)}</TableItemContainer>
                                <TableItemContainer width={tableHeadName[5].width}>{el.note}</TableItemContainer>
                                {/* <TableItemContainer width={tableHeadName[6].width}><FileExportIcon onClick={e => {
                                e.stopPropagation();
                                setSelectedBillId(el.id!);
                            }}/></TableItemContainer> */}
                            </TableItem>
                        ))
                    }
                </Table>
                <ActionsSelector
                    event={event!}
                    close={() => setSelectedBillId(null)}
                    isOpen={selectedBillId != null}
                    actions={[
                        {
                            name: isClient ? "Редактировать" : "Посмотреть", 
                            func: () => {
                                navigate(`${LinkPagesEnum.RedactShipmentBill}?billId=${selectedBillId}`)
                        }},
                        {
                            name: "Экспортировать", 
                            func: async () => {
                                const res = await getExcelName({billsFilter: {
                                    billId: selectedBillId
                                }}).unwrap();

                                window.open(BASE_URL_FILES + res.name, '_blank');
                        }},
                    ]}
                    />
            </>
        </Layout>    
    )}

    return (
        <Layout>
            <ButtonsContainer>
                <Button inlineStyles={{marginRight: 10}} text="Обновить" onClick={refetch} />
                {isClient && <Button inlineStyles={{marginRight: 10}} text="Добавить накладную" onClick={() => navigate(LinkPagesEnum.AddingShipmentBill)}/>}
            </ButtonsContainer>
            <List>
                {sortedData?.map(el => (
                    <ListItem
                        onClick={(e) => {
                            setEvent(e); 
                            setSelectedBillId(el.id!)
                        }}
                        >
                            <ListItemText
                                name="ID:"
                                value={el.id}
                                inlineStyles={{marginBottom: 5}}
                                />
                            <ListItemText
                                name="Статус:"
                                value={getBillStatus(el.status)}
                                inlineStyles={{marginBottom: 5}}
                                />
                            <ListItemText
                                name="Юр лицо:"
                                value={el.organizationName}
                                inlineStyles={{marginBottom: 5}}
                                />
                            <ListItemText
                                name="Склад:"
                                value={el.warehouseName}
                                inlineStyles={{marginBottom: 5}}
                                />
                            <ListItemText
                                name="Создано:"
                                value={el.dateCreated}
                                inlineStyles={{marginBottom: 5}}
                                />
                            <ListItemText
                                name="Комментарий:"
                                value={el.note}
                                inlineStyles={{marginBottom: 5}}
                                />
                    </ListItem>
                ))}
            </List>
            <ActionsSelectorMobile
                    event={event!}
                    close={() => setSelectedBillId(null)}
                    isOpen={selectedBillId != null}
                    actions={[
                        {
                            name: isClient ? "Редактировать" : "Посмотреть", 
                            func: () => {
                                navigate(`${LinkPagesEnum.RedactShipmentBill}?billId=${selectedBillId}`)
                        }},
                        {
                            name: "Экспортировать", 
                            func: async () => {
                                const res = await getExcelName({billsFilter: {
                                    billId: selectedBillId
                                }}).unwrap();

                                window.open(BASE_URL_FILES + res.name, '_blank');
                        }},
                    ]}
                    />
        </Layout>
    );
};

export { ShipmentBillsPage };