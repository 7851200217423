import React from 'react';
import styles from "./index.module.scss";
import { useNavigate } from 'react-router-dom';
import { LinkPagesEnum } from 'app/store/enums/linkPages';

type Props = {
    isOpen: boolean;
    onClose: () => void;

}

const TechSupportModal = ({isOpen, onClose}: Props) => {
    const navigate = useNavigate();

    return (
        isOpen 
        ?
        <>
            <div className={styles.container}>
                <div className={styles.item} onClick={() => {}}>DIF Prime</div>
                <div className={styles.item} onClick={() => navigate(LinkPagesEnum.Support)}>Написать в поддержку</div>
                <a className={styles.item} href="https://t.me/difwmsru" target="_blank">Написать в Telegram</a>
                <div className={styles.item} onClick={() => navigate(LinkPagesEnum.InformationBase)}>Документация</div>
                <a className={styles.item} href="tel:+79196165333">+7 (919) 616-53-33</a>
            </div>
            <div
                onClick={(e) => {
                    onClose()
                    e.stopPropagation();
                }}
                className={styles.background}
                />
        </>
        : null
    );
};

export {
    TechSupportModal
}