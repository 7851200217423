import Layout from "widgets/Layout/Layout"
import styles from "./index.module.scss";
import { Button } from "shared/ui/Button";
import { useNavigate } from "react-router-dom";
import { CreateWarehouseInputModel, useGetWarehouseOrganizationsQuery, usePostWarehouseCreateMutation, usePostWarehouseImageMutation } from "api";
import { useState } from "react";
import { ImageSelector } from "features/ImageSelector";
import { MiniTitle } from "shared/ui/MiniTitle";
import { TextArea } from "shared/ui/TextArea";
import { Input } from "shared/ui/Input";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { Select } from "shared/ui/Select";
import { useAppSelector } from "app/store/hooks/redux";
import { LinkPagesEnum } from "app/store/enums/linkPages";

const AddingWarehousePage = () => {
    const { warehouseOrganizationId} = useAppSelector(state => state.userReducer);

    const navigate = useNavigate();
    const [createWarehouse] = usePostWarehouseCreateMutation();
    const [createdWarehouse, setCreatedWarehouse] = useState<CreateWarehouseInputModel>({
        organizationId: warehouseOrganizationId ?? undefined
    });

    const [createImage] = usePostWarehouseImageMutation();
    
    const handleSubmit = async () => {
        try {
            const warehouseId = await createWarehouse({createWarehouseInputModel: createdWarehouse}).unwrap();
            
            await createImage({body: {
                WarehouseId: warehouseId,
                File: currentImage,
            }}).unwrap();

            setCreatedWarehouse({});
            navigate(LinkPagesEnum.Home);
        }
        catch {

        }
    }

    const [currentImage, setCurrentImage] = useState<File>();
    const [previewImage, setPreviewImage] = useState("");

    const {data: warehouseOrganizations} = useGetWarehouseOrganizationsQuery();

    return (
        <Layout>
            <div>
                <div className={styles.header}>
                    <Button
                        inlineStyles={{marginRight: 10}}
                        onClick={() => navigate(-1)}
                        text="Назад"
                        />
                    <Button 
                        text="Создать склад" 
                        onClick={handleSubmit} 
                        />
                </div>
                <div style={{display: "flex"}}>
                    <div className={styles.container} style={{marginRight: 40, marginBottom: 10}}>
                        <MiniTitle inlineStyles={{marginBottom: 10}}>
                            Галерея
                        </MiniTitle>
                        <ImageSelector
                            inlineClasses={styles.imageSelector}
                            setCurrentImage={setCurrentImage}
                            setPreviewImage={setPreviewImage}
                            previewImage={previewImage}
                            />
                            
                        <MiniTitle inlineStyles={{marginBottom: 10}}>
                            Карта
                        </MiniTitle>
                        <YMaps>
                            <Map width="100%" height={300} defaultState={{ center: [createdWarehouse.latitude != null ? Number(createdWarehouse.latitude) : 55.75, createdWarehouse.latitude != null ? Number(createdWarehouse.longitude) : 37.57], zoom: 9 }} >
                                <Placemark geometry={[createdWarehouse.latitude, createdWarehouse.longitude]} />
                                {/* <Placemark defaultGeometry={[55.751574, 37.573856]} /> */}
                            </Map>
                        </YMaps>
                    </div>
                    <div className={styles.container}>
                        <MiniTitle inlineStyles={{marginBottom: 10}}>
                            О складе
                        </MiniTitle>
                        <div className={styles.fields}>
                        <Input
                            value={createdWarehouse.name}
                            onChange={e => setCreatedWarehouse({...createdWarehouse, name: e.target.value})}
                            placeholder="Название склада"
                            classNamesContainer={styles.field}
                            />
                        <Select 
                            placeholder="Складская организация"
                            options={warehouseOrganizations?.map(el =>({value: el.name!, key: el.id!}))}
                            onChange={e => setCreatedWarehouse({...createdWarehouse, organizationId: e.key})}
                            selectedOptionKey={createdWarehouse.organizationId}
                            classNames={styles.field}
                            />
                        </div>
                        <TextArea
                            inlineStylesContainer={{width: "100%", height: 150, marginBottom: 20}}
                            placeholder="Опишите ваш склад"
                            value={createdWarehouse.description}
                            onChange={e => setCreatedWarehouse({...createdWarehouse, description: e.target.value})}
                            />
                        <MiniTitle inlineStyles={{marginBottom: 10}}>
                            Геоданные
                        </MiniTitle>
                        <div className={styles.fields}>
                            <Input
                                value={createdWarehouse.latitude}
                                onChange={e => setCreatedWarehouse({...createdWarehouse, latitude: e.target.value})}
                                placeholder="Широта"
                                classNamesContainer={styles.field}
                                />
                            <Input
                                value={createdWarehouse.longitude}
                                onChange={e => setCreatedWarehouse({...createdWarehouse, longitude: e.target.value})}
                                placeholder="Долгота"
                                classNamesContainer={styles.field}
                                />
                            <Input
                                value={createdWarehouse.address}
                                onChange={e => setCreatedWarehouse({...createdWarehouse, address: e.target.value})}
                                placeholder="Адрес"
                                classNamesContainer={styles.field}
                                />
                        </div>
                        <MiniTitle inlineStyles={{marginBottom: 10}}>
                            Контакты
                        </MiniTitle>
                        <div className={styles.fields}>
                            <Input
                                value={createdWarehouse.telephone}
                                onChange={e => setCreatedWarehouse({...createdWarehouse, telephone: e.target.value})}
                                placeholder="Номер телефона"
                                classNamesContainer={styles.field}
                                />
                            <Input
                                value={createdWarehouse.email}
                                onChange={e => setCreatedWarehouse({...createdWarehouse, email: e.target.value})}
                                placeholder="Электронная почта"
                                classNamesContainer={styles.field}
                                />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export {
    AddingWarehousePage
};