import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserRoles } from "../consts";
import { MeOutput } from "api";

export type UserState = {
    roles: UserRoles[];
    token: string | null;
    isClient: boolean;
    isEmployee: boolean;
    isWarehouseOwner: boolean;
} & MeOutput;

const initialState: UserState = {
    roles: [],
    token: localStorage.getItem("authToken"),
    isClient: false,
    isEmployee: false,
    isWarehouseOwner: false,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setMe(state, action:PayloadAction<MeOutput>){
            let me = {...action.payload} as UserState;
            me.roles = [];
            // if(me.adminId != null){
            //     me.roles.push(UserRoles.adminRole)
            // }
            if(me.clientId != null){
                me.roles.push(UserRoles.userRole)
                me.isClient = true;
            }
            if(me.employeeId != null){
                me.roles.push(UserRoles.employeeRole)
                me.isEmployee = true;
                me.isClient = false;
            }
            if(me.warehouseOwnerId != null){
                me.roles.push(UserRoles.warehouseOwnerRole)
                me.isWarehouseOwner = true;
                me.isClient = false;
                me.isEmployee = false;
            }
            me.token = state.token
            return {...me, } as UserState;
        },
        setToken(state, action:PayloadAction<string | null>){
            return {...state, token: action.payload} as UserState;
        },
    }
});

export default userSlice.reducer;