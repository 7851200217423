import { ReactNode } from "react";
import styles from "./index.module.scss";
import { BaseSharedComponentType } from "types";
import clsx from "clsx";

type Props = {
    children: ReactNode;
    onClick?: (el: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    marginBottom?: string | undefined | null;
} & BaseSharedComponentType;

const ListItem = ({ children, onClick, inlineClasses, inlineStyles, marginBottom="10px" }: Props) => {
    return (
        <div className={clsx(styles.item, inlineClasses, onClick && styles.hover)} style={{marginBottom: marginBottom!, ...inlineStyles}} onClick={onClick}>
            {children}
        </div>
    )
}

export {
    ListItem
}