
export const getPercentColor = (percent: number | undefined | null): string => {
	if (percent == null) return "#cacaca";

	if (percent <= 15) return "#FF6C6C";
	if (percent <= 40) return "#FFA96A";
	if (percent <= 70) return "#F0802E";
	if (percent <= 99) return "#AFD642";
	if (percent > 99) return "#6CCD6A";
	return "#FF6C6C";
}