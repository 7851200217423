import Layout from "widgets/Layout/Layout";

const SoonPage = () => (
  <Layout>
    <div style={{display: "flex", width: "100%", height: "calc(100vh - 160px)", justifyContent: "center", alignItems: "center"}}>
      Скоро будет добавлено !
    </div>
  </Layout>
);

export { SoonPage };