import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import { CreateKitModel, DifConsumables, DifProduct, ProductOutputModel, useGetConsumablesQuery, useGetOrganizationsQuery, useGetProductsQuery, usePostProductsImageMutation, usePostProductsKitMutation } from "api";
import React, { useState } from "react";
import { Input } from "shared/ui/Input";
import { Select } from "shared/ui/Select";
import { Button } from "shared/ui/Button";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { TableItem } from "widgets/Table/components/Item";
import {Modal} from "shared/ui/Modal";
import { SearchInput } from "shared/ui/SearchInput";
import TitleForm from "shared/ui/TitleForm/TitleForm";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { ImageSelector } from "features/ImageSelector";
import { NavigateButton } from "shared/ui/NavigateButton";
import { getImageUrl, getImageUrlByStrings } from "functions/image";
import { getProductQuantity } from "functions/product";
import { ProductModal } from "features/Modals/Product";
import { RedCloseIcon } from "shared/ui/icons/icons";
import { useAppSelector } from "app/store/hooks/redux";

export const tableHeadName: Array<ColumnHeaderType> = [
    {name: 'Изображение', width: '20%'},
    {name: 'Штрихкод', width: '15%'},
    {name: 'Наименование', width: '20%'},
    {name: "Артикул", width: "10%" },
    {name: 'Остаток', width: '10%'},
    {name: 'Количество', width: '10%'},
    {name: 'Расходник/Кол-во', width: '15%'},
];

const AddingKitPage = () => {
    const {organizationId} = useAppSelector(state => state.userReducer);
    
    const {data: consumables} = useGetConsumablesQuery(undefined);
    
    const [createdKit, setCreatedKit] = useState<CreateKitModel>({
        organizationId: organizationId ?? undefined,
    });
    const {data: organizations} = useGetOrganizationsQuery();

    const {data: productsByOrganization} = useGetProductsQuery({organizationId: createdKit.organizationId!}, {skip: createdKit.organizationId == null});

    const[addProductImage] = usePostProductsImageMutation();

    const [addKit] = usePostProductsKitMutation();

    const [currentImage, setCurrentImage] = useState<File>();
    const [previewImage, setPreviewImage] = useState("");

    const [selects, setSelects] = useState<{c: DifConsumables, count: number}[]>([]);

    const navigate = useNavigate();
    const addKitHandler = async () => {
        try {
            const a = await addKit({
                createKitModel: {...createdKit,
                    childs: selectedProducts.map(x => ({
                        producId: x.id, 
                        quantity: quantities.find(y => y.productId == x.id)?.quantity, 
                        createKitConsumableModels: consumablesForAddedproducts?.filter(x2 => x2.pId == x.id).map(c => ({cunsumableId: c.selectedConsumableId, quantity: c.quantity}))
                    })),
                    createKitConsumableModels: selects.map(select => ({cunsumableId: select.c.id!, quantity: select.count!}))
                }
            }).unwrap();

            await addProductImage({
                body:{
                    File: currentImage,
                    ProductId: a.kitId
                }
            }).unwrap();

            setCreatedKit({});
            navigate(LinkPagesEnum.Products);
        } catch (err) {
            // Handle error
        }
    };

    const [addProductModalVisible, setAddProductModalVisible] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState<ProductOutputModel[]>([]);
    const [quantities, setQuantities] = useState<{productId: number; quantity: number;}[]>([]);
    const [consumablesForAddedproducts, setConsumablesForAddedproducts] = useState<{pId: number, idx: Date, selectedConsumableId: number, name: string, quantity: number}[]>([]);
    const [consumablesModalIdVisible, setConsumablesModalIdVisible] = useState<number | null>();
    const [consumableIdModal, setConsumableIdModal] = useState<number | null>();
    const [consumableQuantityModal, setConsumableQuantityModal] = useState<number | null>();
    return (
        <Layout>
                <>
                    <div style={{display: "flex"}}>
                        <NavigateButton
                            to={LinkPagesEnum.Products}
                            text="Назад"
                            inlineStyles={{marginRight: 10}} 
                            className={styles.headerButtons}
                            />
                        <Button text="Создать" className={styles.headerButtons} onClick={addKitHandler}/>
                    </div>
                    <div style={{display: "flex", alignItems: "start"}}>
                        <ImageSelector
                            inlineClasses={styles.imageSelector}
                            previewImage={previewImage}
                            setPreviewImage={setPreviewImage}
                            setCurrentImage={setCurrentImage}
                            />
                            <div className={styles.modal__fieldsContainer}>
                                <Input placeholder="Название" value={createdKit.name} onChange={e => setCreatedKit({...createdKit, name: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                <Input placeholder="Артикул" value={createdKit.article} onChange={e => setCreatedKit({...createdKit, article: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                <Input placeholder="Цвет" value={createdKit.color} onChange={e => setCreatedKit({...createdKit, color: e.target.value })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                <Input placeholder="Внешний баркод" value={createdKit.factoryBarcode} onChange={e => setCreatedKit({...createdKit,factoryBarcode: e.target.value })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                <Input placeholder="Заводской баркод" value={createdKit.barcode} onChange={e => setCreatedKit({...createdKit, barcode: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                <Input placeholder="Цена обслуживания" value={createdKit.serviceProductPrice?.toString()} onChange={e => setCreatedKit({...createdKit, serviceProductPrice: Number(e.target.value)})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                <Select placeholder="Организация" options={organizations?.map(el => ({value: el.name!, key: el.id!})) ?? []} selectedOptionKey={createdKit.organizationId} onChange={val => setCreatedKit({...createdKit, organizationId: val.key})} classNames={styles.modal__field}/>
                            </div>
                            <div className={styles.kitConsumables}>
                                {selects?.map((el, idx) => 
                                    <div className={styles.kitConsumable}>
                                        <Select classNames={styles.kitConsumableSelect} selectedOptionKey={el.c.id} options={consumables!.map(x => ({value: x.name!, key: x.id!}))} onChange={(val) => {setSelects(prevState => {
                                            const selectedOption = consumables!.find(x => x.id === val.key)!;
                                            const newArr = prevState.map((el2, idx2) => idx == idx2 ? {c: selectedOption, count: 0} : el2);
                                            return newArr;
                                        })}} placeholder={"Расходник " + (idx + 1)}/>
                                        <Input classNamesContainer={styles.kitConsumableInput} placeholder="Кол-во" type="number" value={el.count.toString()} onChange={e => setSelects(prev2 => prev2.map((x4, idx2) => idx == idx2 ? {...x4, count: Number(e.target.value)} : x4))}/>
                                    </div>)}
                                {/* {consumables != null && <Select classNames={styles.kitConsumableSelect} placeholder="Расходник" selectedOption={currentSelect!.name!} options={consumables!.map(x => x.name!)} onChange={val => setCurrnetSelect(consumables!.find(x => x.name  == val)!)}/>} */}
                                <Button text="Добавить расходник" onClick={() => setSelects(prevState => [...prevState, {c: {name: ""}, count: 0}])}/>
                            </div>
                    </div>
                    <Button text="Добавить товар" className={styles.addProductButton} onClick={() => setAddProductModalVisible(true)}/>
                    <Table columns={tableHeadName}>
                        {
                            selectedProducts?.map(el => {
                                const quantity = quantities.find(x => x.productId === el.id!);

                                return (
                                <TableItem inlineStyles={{marginBottom: 10}} onClick={() => {}}>
                                    <>
                                        <TableItemContainer width={tableHeadName[0].width}><img className={styles.image} src={getImageUrlByStrings(el.images)} alt={el.name!} /> </TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[1].width}><div className={styles.name}> {el.barcode} </div> </TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[2].width}>{el.name} </TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[3].width}>{el.article}</TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[4].width}>{el.quantity} </TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[5].width}><Input type="number" inlineStyles={{width: 63}} onChange={val => setQuantities(prevState => prevState.map(x => x.productId == el.id ? {...x, quantity: Number(val.target.value)} : x))} value={quantity?.quantity?.toString()}/></TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[6].width}>
                                            <div>
                                                {consumablesForAddedproducts.filter(x => x.pId == el.id && x.name != "").map(f => 
                                                    <div className={styles.consumable}>
                                                        {f.name}/{f.quantity} ед. 
                                                        <RedCloseIcon inlineStyles={{cursor: "pointer", marginLeft: "5px"}} width="15px" height="15px"  onClick={() => setConsumablesForAddedproducts(consumablesForAddedproducts.filter(y => y.idx != f.idx))}/> 
                                                    </div>)}
                                                
                                                <Button text="Добавить" inlineStyles={{marginTop: 10}} onClick={() => {
                                                    setConsumablesModalIdVisible(el.id);
                                                }}/>
                                            </div>
                                        </TableItemContainer> 
                                    </>
                                </TableItem>
                            )})
                        }
                    </Table>
                    <Modal
                        isCloseCloseIcon
                        isOpen={consumablesModalIdVisible != null}
                        close={() => setConsumablesModalIdVisible(null)}
                        >
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <Select 
                                    classNames={styles.kitConsumableSelect} 
                                    selectedOptionKey={consumableIdModal} 
                                    options={consumables?.map(x => ({value: x.name!, key: x.id!}))} 
                                    onChange={(val) => {setConsumableIdModal(val.key)}}
                                    placeholder="Расходник"/>
                                <Input
                                    value={consumableQuantityModal?.toString()}
                                    type="number"
                                    onChange={val => setConsumableQuantityModal(Number(val.target.value))}
                                    />
                                <Button
                                    inlineStyles={{marginTop: 10}}
                                    onClick={() => {
                                        setConsumablesForAddedproducts(prevState => [...prevState, 
                                            {
                                                quantity: consumableQuantityModal!, 
                                                pId: consumablesModalIdVisible!, 
                                                selectedConsumableId: consumableIdModal!, 
                                                idx: new Date(),
                                                name: consumables?.find(x => x.id === consumableIdModal)?.name!
                                            }]);
                                        setConsumablesModalIdVisible(null);
                                    }} 
                                    text="Добавить"/>
                            </div>
                    </Modal>
                    <ProductModal
                        products={productsByOrganization}
                        isOpen={addProductModalVisible}
                        close={() => setAddProductModalVisible(false)}
                        selectedProducts={selectedProducts}
                        onProductClick={(el) => {
                            if (selectedProducts.includes(el))
                            {
                                setQuantities(prevState => prevState.filter(x => x.productId!= el.id));
                                setSelectedProducts(prevState => prevState.filter(x => x.id!= el.id));
                            }
                            else {
                                setQuantities(prevState => [...prevState, {productId: el.id!, quantity: 0}]);
                                setSelectedProducts(prevState => [...prevState, el]);
                            }
                        }}
                        />
                </>
        </Layout>
    );
};

export {
    AddingKitPage,
};