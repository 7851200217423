import { CSSProperties } from "react";
import { Button } from "../Button";
import { useNavigate } from "react-router-dom";

type Props = {
    to: string;
    text: string,
    disabled?: boolean,
    className?: string,
    inlineStyles?: CSSProperties;
};

const NavigateButton = ({to, text, disabled, className, inlineStyles}: Props) => {
    const navigate = useNavigate();
    
    return (
        <Button
            text={text}
            onClick={() => navigate(to)}
            disabled={disabled}
            className={className}
            inlineStyles={inlineStyles}
            />
    );
};


export {
    NavigateButton
};