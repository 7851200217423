import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import {Modal} from "shared/ui/Modal";
import {InspectionProduct} from "../../components/Product";
import {Button} from "shared/ui-mobile/Button";
import styles from "./index.module.scss";
import { BASE_URL } from "app/store/consts";
import { TerminalScan } from "widgets/TerminalScan";
import { getPercentColor } from "functions/color";
import { useGetProcessesInspectionProcessModelQuery, useGetProcessesSetEmployeeInProcessAndGetEmployeesInProcessQuery, usePostProcessesRedactProcessEmployeeMutation } from "api";
import Layout from "widgets/Layout/Layout";
import { TerminalCounterModal } from "features/Modals/TerminalCounter";
import { PercentBar } from "entities/PercentBar";

export type ProcessRealTimeEmployeeType = {
    processId: number;
    employeeId: number;
    name: string | null;
    email: string;
};

const InspectionProcessPage = () => { 
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [processId] = useState(Number.parseInt(searchParams.get("processId")!));

    const [isModalForEndInspectionOpen, setIsModalForEndInspectionOpen] = useState(false);
    const { data: inspectionProcessModel, refetch } = useGetProcessesInspectionProcessModelQuery({processId: processId});
    const {data: processRealTimeEmployees, isError: isRealTimeEmployeeError} = useGetProcessesSetEmployeeInProcessAndGetEmployeesInProcessQuery({processId: processId}, {pollingInterval: 3000});

    const [redactProcessEmployeeQuery] = usePostProcessesRedactProcessEmployeeMutation();

    const onBarcodeScan = (barcode: string) => {
        var productBillByBarcode = inspectionProcessModel?.products?.find(item => item.barcode == barcode);
              
        if (!productBillByBarcode) {
            productBillByBarcode = inspectionProcessModel?.products?.find(item => item.factoryBarcode == barcode)
            if (!productBillByBarcode)
                return;
        }

        redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: productBillByBarcode.productProcessId!, isIncrement: true}})
        .then(
            () => {
                refetch();
            }
        );
    }

    const onIncrementPress = async (index:number) => {
        if (!inspectionProcessModel?.products)
        {
            return;
        }

        await redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: inspectionProcessModel?.products[index].productProcessId!, isIncrement: true}});
        refetch();
    }

    const onDecrementPress = async (index:number) => {
        if (!inspectionProcessModel?.products)
        {
            return;
        }
        
        await redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: inspectionProcessModel?.products[index].productProcessId!, isIncrement: false}});
        refetch();
    }

    const onSubmit = async (requiredFull: boolean) => {
        var res = await fetch(BASE_URL + `/processes/inspection/complete?processId=${processId}&requiredFull=${requiredFull}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
            body: JSON.stringify({processId, requiredFull}),
            method: 'POST',
        });
        
        if (res.ok) 
        {
            navigate("/processes");
        }
    }

    const [isModalOpened, setIsModalOpened] = useState(false);

    const [ awaitQuantity, setAwaitQuantity ] = useState(0);
    const [ quantity, setQuantity ] = useState(0);
    const [ quantityValue, setQuantityValue] = useState(0);
    const [ indexSelectedElement, setIndexSelectedElement] = useState(0);
    const [isAdd, setIsAdd] = useState(true);

    const onSomeIncrementPress = async () => {
        if (!inspectionProcessModel?.products)
        {
            return;
        }

        await redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: inspectionProcessModel?.products[indexSelectedElement].productProcessId!, isIncrement: true, quantity: quantityValue}});
        refetch();
        
        setQuantity(inspectionProcessModel?.products[indexSelectedElement].acceptanceQuantity! + quantityValue);
    }

    const onSetPress = async () => {
        if (!inspectionProcessModel?.products)
        {
            return;
        }

        const selectedProduct = inspectionProcessModel?.products[indexSelectedElement];

        if (quantityValue > selectedProduct.acceptanceQuantity!)
        {
            await redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: selectedProduct.productProcessId!, isIncrement: true, quantity: quantityValue - selectedProduct.acceptanceQuantity!}});
        }
        else 
        {
            await redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: selectedProduct.productProcessId!, isIncrement: false, quantity: selectedProduct.acceptanceQuantity! - quantityValue}});
        }
        refetch();
        
        setQuantity(quantityValue);
    }
    const isFocusTerminalScan = isModalOpened || isModalForEndInspectionOpen
    const isFullBill = () => {
        if (inspectionProcessModel?.products?.find(pr => pr.quantity != pr.acceptanceQuantity) != null) {
            return false;
        }
        return true;
    }

    return (
        <Layout>
            <div className={styles.inspection}>
                <div className={styles.employeesContainer}>
                    <div className={styles.employeesTitle}>
                        Кто ещё в этом процессе:
                    </div>
                    {!isRealTimeEmployeeError ? 
                        <div>
                            {
                                processRealTimeEmployees?.map((el, idx) => <span className={styles.employee} key={el.employeeId}><span className={styles.number}>{idx + 1}. </span>{el.name} ({el.email})</span>)
                            }
                        </div>
                        : <div>
                            Ошибка
                            </div>}
                </div>
                <PercentBar percent={inspectionProcessModel?.percent}/>
                <div className={styles.inspectionEnd}>
                    <div className={styles.productsContainer}>
                        {
                            inspectionProcessModel?.products?.map((el,index) => <InspectionProduct 
                                name={el.name} 
                                imgSrc={el.images != null ? el.images[0] : "assets/images/box.png" }
                                article={el.article} 
                                barcode={el.barcode} 
                                factoryBarcode={el.factoryBarcode}
                                awaitQuantity={el.quantity}
                                quantity={el.acceptanceQuantity}
                                onIncrement={()=> onIncrementPress(index)}
                                onDecrement={()=> onDecrementPress(index)}
                                onAdd={() => {
                                    setAwaitQuantity(el.quantity!);
                                    setQuantity(el.acceptanceQuantity!);
                                    setIsModalOpened(true);
                                    setIndexSelectedElement(index);
                                    setIsAdd(true);
                                }}
                                onSet={() => {
                                    setAwaitQuantity(el.quantity!);
                                    setQuantity(el.acceptanceQuantity!);
                                    setIsModalOpened(true);
                                    setIndexSelectedElement(index);
                                    setIsAdd(false);
                                }}
                                />
                            )
                        }
                    </div>
                    <Modal
                        inlineStyles={{maxWidth: "90%"}}
                        close={() => setIsModalForEndInspectionOpen(false)} 
                        isOpen={isModalForEndInspectionOpen}
                        >
                            <div className={styles.modalText}>
                                Завершить приёмку с расхождениями ? 
                            </div>
                            <Button 
                                text="Да"
                                onClick={() => onSubmit(false)}
                            />
                    </Modal>
                    <TerminalCounterModal
                            isModalOpened={isModalOpened}
                            awaitQuantity={awaitQuantity}
                            quantity={quantity}
                            setQuantityValue={setQuantityValue}
                            isAdd={isAdd}
                            onSomeIncrementPress={onSomeIncrementPress}
                            onSetPress={onSetPress}
                            setModalOpened={setIsModalOpened}
                            setAwaitQuantity={setAwaitQuantity}
                            setQuantity={setQuantity}
                            quantityValue={quantityValue}
                        />
                </div>
                <div className={styles.bottomButtonsContainer}>
                    <Button 
                        onClick={() => {
                            if (isFullBill()) {
                                onSubmit(true);
                            }
                            else {
                                setIsModalForEndInspectionOpen(true);
                            }
                        }} 
                        text="Завершить приёмку"
                        />
                </div>
                <TerminalScan isFocus={!isFocusTerminalScan} onChange={onBarcodeScan}/>
            </div>
        </Layout>
    )
};

export {InspectionProcessPage};