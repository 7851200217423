import { DifImageProductLink } from "api";
import { BASE_URL_FILES } from "app/store/consts"
import img from "shared/assets/images/box.png";


export const getImageUrl = (images: DifImageProductLink[] | null | undefined) => {
	if (images == null || images.length === 0) {
		return img;
	}

	return BASE_URL_FILES + images[0].image?.name;
}


export const getImageUrlByStrings = (images: string[] | null | undefined) => {
	if (images == null || images.length === 0) {
		return img;
	}

	return images[0];
}