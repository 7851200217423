import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { Container } from "shared/ui/Container";
import { Input } from "shared/ui/Input";
import { CursorIcon, MinusIcon, PlusIcon } from "shared/ui/icons/icons";
import { DrowItemType, useDeleteWarehouseSchemesBySchemeIdMutation, useGetWarehouseByIdSchemeQuery, usePostWarehouseByWarehouseIdCreateSchemeMutation, usePutWarehouseSchemesBySchemeIdMutation } from "api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "shared/ui/Button";
import { LinkPagesEnum } from "app/store/enums/linkPages";

export type WarehouseDrowItem = Omit<DrowItemType, "type" | "x" | "y" | "color" | "rotation"> & {height: number};

const SNAP_THRESHOLD = 10; // Define a threshold for snapping

enum DrowItemTypesEnum {
    Shipment = "Отгрузка",
    Movement = "Перемещение",
    Storage = "Хранение",
    Packaging = "Упаковка",
    Inspection = "Приёмка",
    Section = "Секция",
}

const getDrowItemTypesEnum = (type: DrowItemTypesEnum) => {
    if (type === DrowItemTypesEnum.Shipment) {
        return 0
    }
    else if (type === DrowItemTypesEnum.Movement) {
        return 1
    }
    else if (type === DrowItemTypesEnum.Storage) {
        return 2
    }
    else if (type === DrowItemTypesEnum.Packaging) {
        return 3
    }
    else if (type === DrowItemTypesEnum.Inspection) {
        return 4
    }
    else if (type === DrowItemTypesEnum.Section) {
        return 5
    }

    return 0;
}

type BarProps = {
    selectedActions: number;
    setSelectedActions: Dispatch<SetStateAction<number>>;
    setSelectedDrowItem: Dispatch<SetStateAction<DrowItemType | null>>;
    setDrowItems: Dispatch<SetStateAction<DrowItemType[]>>;
    selectedDropdownItem: DrowItemTypesEnum;
    setSelectedDropdownItem: Dispatch<SetStateAction<DrowItemTypesEnum>>;
};

const Bar = React.memo(({ selectedActions, setSelectedActions, setSelectedDrowItem, setDrowItems, selectedDropdownItem, setSelectedDropdownItem }: BarProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleDropdownItemClick = (item: DrowItemTypesEnum) => {
        setSelectedDropdownItem(item);
        setIsDropdownOpen(false);
    };

    return (
        <div className={styles.bar}>
            <div 
                onClick={() => {
                    setSelectedDrowItem(null);
                    setDrowItems(prevItems => prevItems.map(item => ({ ...item, isSelected: false })));
                    
                    if (selectedActions === 0) {
                        handleDropdownToggle();
                    }
                    setSelectedActions(0);
                }} 
                className={clsx(styles.bar__item, selectedActions === 0 && styles.active)}
            >
                <PlusIcon stroke={selectedActions === 0 ? "#000" : "#fff"} />
                <div className={clsx(styles.dropdown, isDropdownOpen && styles.dropdownOpen)}>
                    {Object.values(DrowItemTypesEnum).map(option => (
                        <div
                            key={option}
                            className={clsx(styles.dropdownItem, selectedDropdownItem === option && styles.active)}
                            onClick={() => handleDropdownItemClick(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            </div>
            <div 
                onClick={() => {
                    setSelectedActions(1);
                    setIsDropdownOpen(false);
                }} 
                className={clsx(styles.bar__item, selectedActions === 1 && styles.active)}
            >
                <CursorIcon stroke={selectedActions === 1 ? "#000" : "#fff"} />
            </div>
            <div 
                onClick={() => {
                    setSelectedActions(2);
                    setIsDropdownOpen(false);
                }} 
                className={clsx(styles.bar__item, selectedActions === 2 && styles.active)}
                style={{color: selectedActions === 2 ? "#000" : "#fff", fontSize: 45}}
            >
                -
            </div>
        </div>
    );
});

const getColorFortype = (option: DrowItemTypesEnum): string => {
    switch (option) {
        case DrowItemTypesEnum.Shipment:
            return "#FF5733"; // Example color for Shipment
        case DrowItemTypesEnum.Movement:
            return "#33FF57"; // Example color for Movement
        case DrowItemTypesEnum.Storage:
            return "#3357FF"; // Example color for Storage
        case DrowItemTypesEnum.Packaging:
            return "#FF33A1"; // Example color for Packaging
        case DrowItemTypesEnum.Inspection:
            return "#A133FF"; // Example color for Inspection
        case DrowItemTypesEnum.Section:
            return "rgba(51, 255, 245, 0.2)"; // Example color for Section
        default:
            return "#CEE7FF"; // Default color
    }
};

const WarehouseDesignerPage = () => {
    const [params] = useSearchParams();
    const warehouseId = params.get("warehouseId");

    const { data: warehouseScheme } = useGetWarehouseByIdSchemeQuery({id: parseInt(warehouseId!)}, {skip: warehouseId == null});

    useEffect(() => {
        if (warehouseScheme != null) {
            setWarehouseDrowItem({
                width: warehouseScheme.width!,
                height: warehouseScheme.height!,
                length: warehouseScheme.length!,
                id: warehouseScheme.id!,
                name: warehouseScheme.name!,
                isSelected: false
            });

            const mappedDrowItems = warehouseScheme.drowItems!.map(item => ({
                ...item,
                id: item.id!,
                floors: item.floors!.map(floor => ({height: floor.height!, name: floor.name!, id: floor.id!  })),
            }));
            setDrowItems(mappedDrowItems);
        }
        else {
            setDrowItems([]);
        }
    }, [warehouseScheme]);

    const [putWarehouseScheme, {isLoading: isUpdateLoading}] = usePutWarehouseSchemesBySchemeIdMutation();
    const [deleteWarehouseScheme] = useDeleteWarehouseSchemesBySchemeIdMutation();
    const [postWarehouseScheme, {isLoading: isAddLoading}] = usePostWarehouseByWarehouseIdCreateSchemeMutation();
    const navigate = useNavigate();
    const handleAddScheme = async () => {
        try {
            const id = await postWarehouseScheme({
                warehouseId: parseInt(warehouseId!), 
                schemeName: warehouseDrowItem.name, 
                width: warehouseDrowItem.width,
                length: warehouseDrowItem.length,
                height: warehouseDrowItem.height,
                body: drowItems
            }).unwrap();

            navigate(LinkPagesEnum.WarehouseDesigner + "?warehouseId=" + warehouseId + "&schemeId=" + id);
        } catch (error) {
            console.error("Failed to add warehouse scheme:", error);
        }
    };

    const handleUpdateScheme = async () => {
        try {
            await putWarehouseScheme({ schemeId: warehouseScheme!.id!, updateWarehouseSchemeInputModel: {
                newName: warehouseDrowItem.name,
                width: warehouseDrowItem.width,
                length: warehouseDrowItem.length,
                height: warehouseDrowItem.height,
                drowItems: drowItems
            } }).unwrap();
        } catch (error) {
            console.error("Failed to update warehouse scheme:", error);
        }
    };

    const handleDeleteScheme = async () => {
        try {
            await deleteWarehouseScheme({schemeId: warehouseScheme!.id!}).unwrap();
            navigate(LinkPagesEnum.WarehouseDesigner + "?warehouseId=" + warehouseId);
        } catch (error) {
            console.error("Failed to delete warehouse scheme:", error);
        }
    };
    
    const [containerZoom, setContainerZoom] = useState(1);
    const [selectedActions, setSelectedActions] = useState(1);
    const [selectedDropdownItem, setSelectedDropdownItem] = useState<DrowItemTypesEnum>(DrowItemTypesEnum.Inspection);
    const [drowItems, setDrowItems] = useState<DrowItemType[]>([]);
    const zoomPercentage = useMemo(() => (containerZoom * 100).toFixed(0), [containerZoom]);
    const [warehouseDrowItem, setWarehouseDrowItem] = useState<WarehouseDrowItem>({
        id: 0,
        name: "warehouse",
        width: 700,
        length: 700,
        height: 100,
        isSelected: true,
    });
    const handleDecreaseZoom = useCallback(() => setContainerZoom(prevZoom => Math.max(prevZoom * 0.9, 0.1)), []);
    const handleIncreaseZoom = useCallback(() => setContainerZoom(prevZoom => Math.min(prevZoom * 1.1, 10)), []);
    const handleResetZoom = useCallback(() => setContainerZoom(1), []);

    const [copiedItem, setCopiedItem] = useState<DrowItemType | null>(null);

    const [selectedDrowItem, setSelectedDrowItem] = useState<DrowItemType | null>(null);
    const [selectionBox, setSelectionBox] = useState<{ x: number; y: number; width: number; length: number } | null>(null);

    const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWarehouseDrowItem({
            ...warehouseDrowItem,
            width: parseInt(e.target.value) * 10 || 0
        });
    };

    const handlelengthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWarehouseDrowItem({
            ...warehouseDrowItem,
            length: parseInt(e.target.value) * 10 || 0
        });
    };
    
    const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWarehouseDrowItem({
            ...warehouseDrowItem,
            height: parseInt(e.target.value) * 10 || 0
        });
    };

    const limitPosition = (x: number, y: number, width: number, length: number): { x: number; y: number } => {
        const maxX = warehouseDrowItem.width - width;
        const maxY = warehouseDrowItem.length - length;
        return {
            x: Math.max(0, Math.min(x, maxX)),
            y: Math.max(0, Math.min(y, maxY))
        };
    };


    const selectedDrowItem2 = useMemo(() => {
        const selectedItems = drowItems.filter(item => item.isSelected);
        if (selectedItems.length > 1 || selectedItems.length === 0) {
            return null;
        }
        return selectedItems[0];
    }, [drowItems]);

    const handleSelectItem = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number) => {
        if (event.altKey) {
            const selectedItem = drowItems.find(item => item.id === id);
            if (selectedItem != null) {
                setCopiedItem(selectedItem);
            }
            else if (copiedItem) {
                const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
                const 
                    x = (event.clientX - rect.left) / containerZoom - copiedItem.width / 2,
                    y = (event.clientY - rect.top) / containerZoom - copiedItem.length / 2;

                const newItem = {
                    ...copiedItem,
                    id: drowItems.length + 1,
                    x: x, // Offset the pasted item slightly
                    y: y,
                    isSelected: true,
                };
                setDrowItems([...drowItems.map(el => ({...el, isSelected: false})), newItem]);
                setCopiedItem(newItem);
                setSelectedDrowItem(newItem);
            }
            return;
        }
        if (selectedActions === 1) {
            if (id === warehouseDrowItem.id) {
                setSelectedDrowItem(null);
                console.log("Selected warehouse");
                setWarehouseDrowItem({
                    ...warehouseDrowItem,
                    isSelected: true
                });
            } else {
                const selectedItem = drowItems.find(item => item.id === id);
                console.log("Selected item: ", selectedItem);
                
                setSelectedDrowItem(selectedItem || null);
                setDrowItems(drowItems.map(item => (item.id === id? {...item, isSelected: true } : {...item, isSelected: false })));
                setWarehouseDrowItem({
                    ...warehouseDrowItem,
                    isSelected: false
                });
            }
        }
        else if (selectedActions === 0) {
            const container = document.querySelector(`.${styles.warehouse}`) as HTMLElement;
            const rect = container.getBoundingClientRect();
            const x = (event.clientX - rect.left) / containerZoom;
            const y = (event.clientY - rect.top) / containerZoom;
            const newItem: DrowItemType = {
                id: drowItems.length + 1,
                name: `${drowItems.length + 1}`,
                width: 10,
                length: 10,
                x: 0,
                y: 0,
                rotation: 0,
                isSelected: true,
                color: getColorFortype(selectedDropdownItem),
                type: getDrowItemTypesEnum(selectedDropdownItem),
                floors: [{
                    height: 10,
                    name: "1",
                    id: 1,
                }]
            };

            newItem.x = x - newItem.width / 2;
            newItem.y = y - newItem.length / 2;

            setDrowItems([...drowItems, newItem]);
            setSelectedDrowItem(newItem);
            console.log("Created item: ", newItem);
            setWarehouseDrowItem({...warehouseDrowItem, isSelected: false })
            setSelectedActions(1);
        }
        else if (selectedActions === 2) {
            setDrowItems(prevItems => prevItems.filter(item => item.id !== id));
        }
    };

    const [dragOffset, setDragOffset] = useState<{ x: number; y: number } | null>(null);
    const [initialPositions, setInitialPositions] = useState<Map<number, { x: number; y: number }>>(new Map());

    const handleMouseDown = (event: React.MouseEvent, item: DrowItemType) => {
        if (selectedActions === 1) {
            event.stopPropagation();
            setSelectedDrowItem(item);
            const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
            setDragOffset({
                x: (event.clientX - rect.left) / containerZoom,
                y: (event.clientY - rect.top) / containerZoom
            });
            
            console.log("Selected item2: ", item);
            if (drowItems.filter(x => x.isSelected).length == 1)
                setDrowItems(drowItems.map(item2 => (item2.id === item.id? {...item, isSelected: true } : {...item2, isSelected: false })));
        }
    };

    const [firstSelectedBySelectionBoxDrowItem, setFirstSelectedBySelectionBoxDrowItem] = useState<DrowItemType | null>(null);

    const handleMouseMove = (event: MouseEvent) => {
        if (selectedDrowItem && dragOffset) {
            const container = document.querySelector(`.${styles.warehouse}`) as HTMLElement;
            if (container) {
                if (drowItems.filter(x => x.isSelected).length > 1) {
                    const rect = container.getBoundingClientRect();
                    const deltaX = (event.clientX - rect.left) / containerZoom - dragOffset.x;
                    const deltaY = (event.clientY - rect.top) / containerZoom - dragOffset.y;

                    setDrowItems(prevItems => prevItems.map(item => {
                        if (item.isSelected) {
                            const initialPosition = initialPositions.get(item.id);
                            
                            if (initialPosition) {
                                const limitedPosition = limitPosition(initialPosition.x + deltaX, initialPosition.y + deltaY, item.width, item.length);
                                let newX = limitedPosition.x;
                                let newY = limitedPosition.y;

                                // Check for snapping
                                // prevItems.forEach(otherItem => {
                                //     if (otherItem.id !== item.id) {
                                //         if (Math.abs(limitedPosition.x - otherItem.x) < SNAP_THRESHOLD) {
                                //             newX = otherItem.x;
                                //         }
                                //         if (Math.abs(limitedPosition.y - otherItem.y) < SNAP_THRESHOLD) {
                                //             newY = otherItem.y;
                                //         }
                                //     }
                                // });
                                return { ...item, x: newX, y: newY };
                            }
                        }
                        return item;
                    }));
                }
                else {
                    const rect = container.getBoundingClientRect();
                    let newX = (event.clientX - rect.left) / containerZoom - dragOffset.x;
                    let newY = (event.clientY - rect.top) / containerZoom - dragOffset.y;
                    const limitedPosition = limitPosition(newX, newY, selectedDrowItem.width, selectedDrowItem.length);

                    newX = limitedPosition.x;
                    newY = limitedPosition.y;
                    // Check for snapping
                    drowItems.forEach(item => {
                        if (item.id !== selectedDrowItem.id) {
                            if (Math.abs(limitedPosition.x - item.x) < SNAP_THRESHOLD) {
                                newX = item.x;
                            }
                            if (Math.abs(limitedPosition.y - item.y) < SNAP_THRESHOLD) {
                                newY = item.y;
                            }
                        }
                    });

                    setDrowItems(prevItems => prevItems.map(item =>
                        item.id === selectedDrowItem.id ? { ...item, x: newX, y: newY } : item
                    ));
                }

            }
        } else if (selectionBox) {
            const container = document.querySelector(`.${styles.warehouse}`) as HTMLElement;
            if (container) {
                const rect = container.getBoundingClientRect();
                const newWidth = (event.clientX - rect.left) / containerZoom - selectionBox.x;
                const newlength = (event.clientY - rect.top) / containerZoom - selectionBox.y;
                setSelectionBox({
                    ...selectionBox,
                    width: newWidth,
                    length: newlength
                });
                console.log(newWidth, newlength);
                // Determine which items are within the selection box
                const selectedItems = drowItems.map(item => {
                    const itemRect = {
                        left: item.x,
                        right: item.x + item.width,
                        top: item.y,
                        bottom: item.y + item.length
                    };
                    const selectionRect = {
                        left: Math.min(selectionBox.x, selectionBox.x + newWidth),
                        right: Math.max(selectionBox.x, selectionBox.x + newWidth),
                        top: Math.min(selectionBox.y, selectionBox.y + newlength),
                        bottom: Math.max(selectionBox.y, selectionBox.y + newlength)
                    };
                    const isSelected = (
                        itemRect.left < selectionRect.right &&
                        itemRect.right > selectionRect.left &&
                        itemRect.top < selectionRect.bottom &&
                        itemRect.bottom > selectionRect.top
                    );

                    if (firstSelectedBySelectionBoxDrowItem == null && isSelected) {
                        setFirstSelectedBySelectionBoxDrowItem(item);
                    }

                    return { ...item, isSelected };
                });
                console.log("Selected items: ", selectedItems);
                setDrowItems(selectedItems);
                if (firstSelectedBySelectionBoxDrowItem != null) {
                    // Store initial positions of all selected items
                    const positions = new Map<number, { x: number; y: number }>();
                    selectedItems.forEach(item => {
                        if (item.isSelected) {
                            positions.set(item.id, { x: item.x - firstSelectedBySelectionBoxDrowItem.x, y: item.y - firstSelectedBySelectionBoxDrowItem.y });
                        }
                    });
                    setInitialPositions(positions);
                }
                setSelectedDrowItem(null);
            }
        }
    };

    const handleMouseUp = () => {
        // setSelectedDrowItem(null);
        setDragOffset(null);
        setSelectionBox(null);
        // setInitialPositions(new Map());
        setFirstSelectedBySelectionBoxDrowItem(null);
    };

    const handleResizeMouseDown = (event: React.MouseEvent, corner: string) => {
        event.stopPropagation();
    
        const initialMousePosition = { x: event.clientX, y: event.clientY };
        const initialDimensions = { 
            width: selectedDrowItem!.width, 
            length: selectedDrowItem!.length,
            x: selectedDrowItem!.x,
            y: selectedDrowItem!.y
        };
        const aspectRatio = initialDimensions.width / initialDimensions.length;
    
        const handleResizeMouseMove = (moveEvent: MouseEvent) => {
            if (selectedDrowItem) {
                const deltaX = (moveEvent.clientX - initialMousePosition.x) / containerZoom;
                const deltaY = (moveEvent.clientY - initialMousePosition.y) / containerZoom;
    
                let newWidth = initialDimensions.width;
                let newLength = initialDimensions.length;
                let newX = initialDimensions.x;
                let newY = initialDimensions.y;
    
                if (moveEvent.shiftKey) {
                    // If Shift is pressed, maintain aspect ratio
                    const delta = Math.max(Math.abs(deltaX), Math.abs(deltaY));
                    let increase = deltaX > 0 || deltaY > 0;
    
                    switch (corner) {
                        case 'top-left':
                            newWidth = !increase ? initialDimensions.width + delta : initialDimensions.width - delta;
                            newLength = newWidth / aspectRatio;
                            newX = initialDimensions.x + (initialDimensions.width - newWidth);
                            newY = initialDimensions.y + (initialDimensions.length - newLength);
                            break;
                        case 'top-right':
                            increase = deltaX > 0 && deltaY < 0;
                            newWidth = increase ? initialDimensions.width + delta : initialDimensions.width - delta;
                            newLength = newWidth / aspectRatio;
                            newY = initialDimensions.y + (initialDimensions.length - newLength);
                            break;
                        case 'bottom-left':
                            increase = deltaX < 0 && deltaY > 0;
                            newWidth = increase ? initialDimensions.width + delta : initialDimensions.width - delta;
                            newLength = newWidth / aspectRatio;
                            newX = initialDimensions.x + (initialDimensions.width - newWidth);
                            break;
                        case 'bottom-right':
                            newWidth = increase ? initialDimensions.width + delta : initialDimensions.width - delta;
                            newLength = newWidth / aspectRatio;
                            break;
                    }
                } else {
                    // Normal resizing logic
                    const resizeLogic: { [key: string]: () => void } = {
                        'top-left': () => {
                            newWidth -= deltaX;
                            newLength -= deltaY;
                            newX += deltaX;
                            newY += deltaY;
                        },
                        'top-right': () => {
                            newWidth += deltaX;
                            newLength -= deltaY;
                            newY += deltaY;
                        },
                        'bottom-left': () => {
                            newWidth -= deltaX;
                            newLength += deltaY;
                            newX += deltaX;
                        },
                        'bottom-right': () => {
                            newWidth += deltaX;
                            newLength += deltaY;
                        }
                    };
    
                    if (resizeLogic[corner]) {
                        resizeLogic[corner]();
                    }
                }
    
                // Round the new dimensions to the nearest integer
                newWidth = Math.round(newWidth);
                newLength = Math.round(newLength);
                newX = Math.round(newX);
                newY = Math.round(newY);
    
                setDrowItems(prevItems => prevItems.map(item =>
                    item.id === selectedDrowItem.id ? { ...item, width: newWidth, length: newLength, x: newX, y: newY } : item
                ));
            }
        };
    
        const handleResizeMouseUp = () => {
            window.removeEventListener('mousemove', handleResizeMouseMove);
            window.removeEventListener('mouseup', handleResizeMouseUp);
        };
    
        window.addEventListener('mousemove', handleResizeMouseMove);
        window.addEventListener('mouseup', handleResizeMouseUp);
    };

    const handleContainerMouseDown = (event: React.MouseEvent) => {
        if (selectedActions == 1) {
            const container = document.querySelector(`.${styles.warehouse}`) as HTMLElement;
            if (container) {
                const rect = container.getBoundingClientRect();
                const startX = (event.clientX - rect.left) / containerZoom;
                const startY = (event.clientY - rect.top) / containerZoom;
                setSelectionBox({ x: startX, y: startY, width: 0, length: 0 });
            }
        }
    };

    const handleContainerZoom = (event: React.WheelEvent) => {
        event.preventDefault();
        const zoomFactor = event.deltaY > 0 ? 0.9 : 1.1;
        const newZoom = Math.max(0.1, Math.min(containerZoom * zoomFactor, 10));

        setContainerZoom(newZoom);
    };

    const handleDrowItemWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newWidth = parseFloat(e.target.value) * 10;
        setDrowItems(prevItems => prevItems.map(item =>
            item.id === selectedDrowItem?.id ? { ...item, width: newWidth } : item
        ));
    };

    const handleDrowItemlengthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newlength = parseFloat(e.target.value) * 10;
        setDrowItems(prevItems => prevItems.map(item =>
            item.id === selectedDrowItem?.id ? { ...item, length: newlength } : item
        ));
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newName = e.target.value;
        setDrowItems(prevItems => prevItems.map(item =>
            item.id === selectedDrowItem?.id ? { ...item, name: newName } : item
        ));
    };
    
    const handleXChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newX = parseFloat(e.target.value) * 10;
        setDrowItems(prevItems => prevItems.map(item =>
            item.id === selectedDrowItem?.id ? { ...item, x: newX } : item
        ));
    };

    const handleYChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newY = parseFloat(e.target.value) * 10;
        setDrowItems(prevItems => prevItems.map(item =>
            item.id === selectedDrowItem?.id ? { ...item, y: newY } : item
        ));
    };
    
    const calculateCapacity = (item: WarehouseDrowItem) => {
        return (item.width / 10) * (item.length / 10) * (item.height / 10);
    };
    
    const addFloorToDrowItem = () => {
        setDrowItems(prevItems => 
            prevItems.map(item => 
                item.id === selectedDrowItem2?.id
                    ? { 
                        ...item, 
                        floors: [...item.floors!, { name: "", height: 10, id: item.floors!.length + 1 }] 
                      } 
                    : item
            )
        );
    };
    const removeFloor = (floorId: number) => {
        setDrowItems(prevItems => 
            prevItems.map(item => 
                item.id === selectedDrowItem2?.id
                    ? { 
                        ...item, 
                        floors: item.floors!.filter(floor => floor.id !== floorId) 
                      } 
                    : item
            )
        );
    };
    const handleFloorNameChange = (floorId: number, newName: string) => {
        setDrowItems(prevItems => 
            prevItems.map(item => 
                item.id === selectedDrowItem2?.id
                    ? { 
                        ...item, 
                        floors: item.floors!.map(floor => 
                            floor.id === floorId ? { ...floor, name: newName } : floor
                        ) 
                      } 
                    : item
            )
        );
    };
    
    const handleFloorHeightChange = (floorId: number, newHeight: string) => {
        const heightValue = parseFloat(newHeight) * 10;
        setDrowItems(prevItems => 
            prevItems.map(item => 
                item.id === selectedDrowItem2?.id
                    ? { 
                        ...item, 
                        floors: item.floors!.map(floor => 
                            floor.id === floorId ? { ...floor, height: heightValue } : floor
                        ) 
                      } 
                    : item
            )
        );
    };
    useEffect(() => {
        if (selectedDrowItem || selectionBox) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
        }
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [selectedDrowItem, dragOffset, selectionBox]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Delete' && selectedDrowItem2) {
                setDrowItems(prevItems => prevItems.filter(item => item.id !== selectedDrowItem2.id));
            }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedDrowItem2]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === 'c') {
                // Copy the selected item
                if (selectedDrowItem2) {
                    setCopiedItem({ ...selectedDrowItem2, isSelected: false });
                }
            }

            if (event.ctrlKey && event.key === 'v') {
                // Paste the copied item
                console.log('Pasting copied item');
                if (copiedItem) {
                    const newItem = {
                        ...copiedItem,
                        id: drowItems.length + 1,
                        x: copiedItem.x + copiedItem.width, // Offset the pasted item slightly
                        y: copiedItem.y,
                        isSelected: false
                    };
                    setDrowItems([...drowItems.map(el => ({...el, isSelected: false})), newItem]);
                    setCopiedItem(newItem);
                    setSelectedDrowItem(newItem);
                }
            }

            if (selectedDrowItem2) {
                let newX = selectedDrowItem2.x;
                let newY = selectedDrowItem2.y;
                const baseMoveAmount = 0.1; // Base move amount
                const moveAmount = event.shiftKey ? baseMoveAmount * 10 : baseMoveAmount; // Increase move amount if Ctrl is pressed
    
                switch (event.key) {
                    case 'ArrowUp':
                        newY -= moveAmount;
                        break;
                    case 'ArrowDown':
                        newY += moveAmount;
                        break;
                    case 'ArrowLeft':
                        newX -= moveAmount;
                        break;
                    case 'ArrowRight':
                        newX += moveAmount;
                        break;
                    default:
                        return; // Exit if it's not an arrow key
                }
    
                const limitedPosition = limitPosition(newX, newY, selectedDrowItem2.width, selectedDrowItem2.length);
                newX = limitedPosition.x;
                newY = limitedPosition.y;
    
                console.log(`Moving item to ${newX}, ${newY}`);
                setDrowItems(prevItems => prevItems.map(item =>
                    item.id === selectedDrowItem2.id ? { ...item, x: newX, y: newY } : item
                ));
            }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedDrowItem2, drowItems, copiedItem]);

    useEffect(() => {
        const preventDefault = (e: WheelEvent) => {
            if (e.ctrlKey) {
                e.preventDefault();
            }
        };

        document.addEventListener('wheel', preventDefault, { passive: false });

        return () => {
            document.removeEventListener('wheel', preventDefault);
        };
    }, []);

    return (
        <Layout isHeaderVisible={false} isMenuVisible={false}>
            <div style={{display: "flex"}}>
                <div className={clsx(styles.menu, styles.scroll)}>
                    <div className={styles.pageName}>
                        Конструктор
                    </div>
                    <Button
                        className={styles.button}
                        text="Назад"
                        onClick={() => navigate(LinkPagesEnum.Home)}
                        inlineStyles={{ marginBottom: 10 }}
                        />
                    {(warehouseDrowItem.isSelected) && <div>
                        {warehouseScheme == null 
                        ? 
                            <Button isLoader={isAddLoading} className={styles.button} onClick={handleAddScheme} text="Создать схему" inlineStyles={{ marginBottom: '10px' }} />
                        :
                        <>
                            <Button isLoader={isUpdateLoading} className={styles.button} onClick={handleUpdateScheme} text="Обновить схему" inlineStyles={{ marginBottom: '10px' }} />
                            <Button className={styles.button} onClick={handleDeleteScheme} text="Удалить схему" inlineStyles={{ marginBottom: '10px' }} />
                        </>}
                        <div style={{marginBottom: 10}}>
                            Склад: 
                        </div>
                        <Input
                            type="number"
                            placeholder="Ширина м"
                            value={(warehouseDrowItem.width / 10).toString()}
                            onChange={handleWidthChange}
                            inlineStylesContainer={{marginBottom: 10}}
                        />
                        <Input
                            type="number"
                            placeholder="Длина м"
                            value={(warehouseDrowItem.length / 10).toString()}
                            inlineStylesContainer={{marginBottom: 10}}
                            onChange={handlelengthChange}
                        />
                        <Input
                            type="number"
                            placeholder="Высота м"
                            value={(warehouseDrowItem.height / 10).toString()}
                            inlineStylesContainer={{marginBottom: 10}}
                            onChange={handleHeightChange}
                        />
                        <div style={{ marginBottom: 10 }}>
                            Вместимость: {calculateCapacity(warehouseDrowItem).toFixed(5)} м³
                        </div>
                    </div>}
                    {selectedDrowItem2 && (
                        <div onMouseDownCapture={(e) => e.stopPropagation()}>
                            <div style={{ marginBottom: 10 }}>
                                {selectedDrowItem2.type == getDrowItemTypesEnum(DrowItemTypesEnum.Section) ? "Секция:"  : "Ячейка:"} 
                            </div>
                            <div style={{marginBottom: 10}}>
                                Id: {selectedDrowItem2.id}
                            </div>
                            <Input
                                placeholder="Имя"
                                value={selectedDrowItem2.name || ''}
                                onChange={handleNameChange}
                                inlineStylesContainer={{ marginBottom: 10 }}
                            />
                            <Input
                                type="number"
                                placeholder="Ширина м"
                                value={(selectedDrowItem2.width / 10).toString()}
                                onChange={handleDrowItemWidthChange}
                                inlineStylesContainer={{ marginBottom: 10 }}
                            />
                            <Input
                                type="number"
                                placeholder="Длина м"
                                value={(selectedDrowItem2.length / 10).toString()}
                                onChange={handleDrowItemlengthChange}
                                inlineStylesContainer={{ marginBottom: 10 }}
                            />
                            <Input
                                placeholder="X м"
                                value={(Math.round(selectedDrowItem2.x * 10) / 100).toString()}
                                onChange={handleXChange}
                                inlineStylesContainer={{ marginBottom: 10 }}
                            />
                            <Input
                                placeholder="Y м"
                                value={(Math.round(selectedDrowItem2.y * 10) / 100).toString()}
                                onChange={handleYChange}
                                inlineStylesContainer={{ marginBottom: 10 }}
                            />
                            <Button 
                                className={clsx(styles.button)}
                                text="Добавить ярус"
                                onClick={addFloorToDrowItem}
                                inlineStyles={{ marginBottom: 20 }}
                                />
                            {selectedDrowItem2.floors?.map(floor => (
                                <div style={{ marginBottom: 10 }}>
                                    <Input
                                        placeholder="Имя"
                                        value={floor.name || ''}
                                        onChange={(e) => handleFloorNameChange(floor.id, e.target.value)}
                                        inlineStylesContainer={{ marginBottom: 10 }}
                                        />
                                    <Input
                                        type="number"
                                        placeholder="Высота м"
                                        value={(floor.height / 10).toString()}
                                        onChange={(e) => handleFloorHeightChange(floor.id, e.target.value)}
                                        inlineStylesContainer={{ marginBottom: 10 }}
                                        />
                                    <Button
                                        className={clsx(styles.button, styles.button_red)}
                                        text="Удалить ярус"
                                        onClick={() => removeFloor(floor.id)}
                                        />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <Container inlineStyles={{ 
                        width: "83%",
                        height: "100%", 
                        minHeight: "calc(100vh - 40px)", 
                        overflow: "hidden", 
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <div
                        onMouseUp={() => {
                            if (selectionBox?.width == 0 && selectionBox?.length === 0) {
                                console.log("Deselecting item");
                                setDrowItems(prevItems => prevItems.map(item => ({...item, isSelected: false })))
                                setWarehouseDrowItem({...warehouseDrowItem, isSelected: true })
                            }
                        }}
                        onWheel={handleContainerZoom}
                        onMouseDown={handleContainerMouseDown}
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            overflow: "scroll",
                        }}
                    >
                        <div
                            style={{
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                                width: `${100 / containerZoom}%`,
                                height: `${100 / containerZoom}%`,
                                transform: `translate(-50%, -50%) scale(${containerZoom})`,
                                transformOrigin: "center",
                                transition: "transform 0.1s ease-out",
                                // display: "flex",
                                // justifyContent: "center",
                                // alignItems: "center",
                            }}
                        >
                            <div
                                onClick={(e) => handleSelectItem(e, warehouseDrowItem.id)}
                                style={{
                                    marginLeft: `calc(${containerZoom < 1 ? 50 / containerZoom : 50}vw - ${(warehouseDrowItem.width / 2)}px)`,
                                    marginTop: `calc(${containerZoom < 1 ? 50 / containerZoom : 50}vh - ${(warehouseDrowItem.length / 2)}px)`,
                                    marginRight: `calc(${containerZoom < 1 ? 50 / containerZoom : 50}vw - ${(warehouseDrowItem.width / 2)}px)`,
                                    marginBottom: `calc(${containerZoom < 1 ? 50 / containerZoom : 50}vh - ${(warehouseDrowItem.length / 2)}px)`,
                                    width: warehouseDrowItem.width,
                                    height: warehouseDrowItem.length,
                                    position: 'relative',
                                    border: '1px solid black',
                                }}
                                className={clsx(styles.warehouse, styles.drow__item, warehouseDrowItem.isSelected && styles.drow__item_active)}
                                >
                                {drowItems.map(item => (
                                    <div
                                        key={item.id}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleSelectItem(e, item.id);
                                        }}
                                        onMouseDown={(e) => handleMouseDown(e, item)}
                                        className={clsx(
                                            styles.drow__item,
                                            item.isSelected && styles.drow__item_active
                                        )}
                                        style={{
                                            zIndex: item.type == getDrowItemTypesEnum(DrowItemTypesEnum.Section) ? 2 : 3,
                                            left: item.x,
                                            top: item.y,
                                            position: 'absolute',
                                            width: item.width,
                                            height: item.length,
                                            backgroundColor: item.color,
                                            cursor: selectedActions === 1 ? 'move' : 'pointer',
                                            border: '1px solid #003366'
                                        }}>
                                            {containerZoom >= 2 && (
                                                <>
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: `${20 / containerZoom}px`, // Scale top position
                                                            left: `${-10 / containerZoom}px`, // Scale left position
                                                            transform: `translateY(-50%) scale(${1 / containerZoom}) rotate(-90deg)`,
                                                            transformOrigin: 'left center',
                                                            fontSize: '10px',
                                                            padding: '2px',
                                                        }}
                                                    >
                                                        {`${item.width / 10}м`}
                                                    </div>
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            left: `${15 / containerZoom}px`, // Scale left position
                                                            top: `${-15 / containerZoom}px`, // Scale top position
                                                            transform: `translateX(-50%) scale(${1 / containerZoom})`,
                                                            transformOrigin: 'top center',
                                                            fontSize: '10px',
                                                            padding: '2px',
                                                        }}
                                                    >
                                                        {`${item.length / 10}м`}
                                                    </div>
                                                    <div 
                                                        style={{ 
                                                            // position: 'absolute',
                                                            // left: 0,
                                                            // top: 0,
                                                            display: 'flex',
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            width: `100%`,
                                                            height: `100%`,
                                                        }}>
                                                            <div
                                                                style={{
                                                                    fontSize: `${10 * (item.length / 10)}px`,
                                                                    transform: `scale(${1 / containerZoom})`, // Scale the text
                                                                }}
                                                                >
                                                                {item.name}
                                                            </div>
                                                    </div>
                                                </>
                                            )}
                                        {/* Render corner circles if the item is selected */}
                                        {((selectedDrowItem && selectedDrowItem.id === item.id) || item.isSelected) && (
                                            <>
                                                <div
                                                    className={styles.cornerCircle}
                                                    style={{
                                                        left: -5,
                                                        top: -5,
                                                        cursor: 'se-resize',
                                                        transform: `scale(${1 / containerZoom})`,
                                                        transformOrigin: 'center'
                                                    }}
                                                    onMouseDown={(e) => handleResizeMouseDown(e, 'top-left')}
                                                />
                                                <div
                                                    className={styles.cornerCircle}
                                                    style={{
                                                        right: -5,
                                                        top: -5,
                                                        cursor: 'sw-resize',
                                                        transform: `scale(${1 / containerZoom})`,
                                                        transformOrigin: 'center'
                                                    }}
                                                    onMouseDown={(e) => handleResizeMouseDown(e, 'top-right')}
                                                />
                                                <div
                                                    className={styles.cornerCircle}
                                                    style={{
                                                        left: -5,
                                                        bottom: -5,
                                                        cursor: 'ne-resize',
                                                        transform: `scale(${1 / containerZoom})`,
                                                        transformOrigin: 'center'
                                                    }}
                                                    onMouseDown={(e) => handleResizeMouseDown(e, 'bottom-left')}
                                                />
                                                <div
                                                    className={styles.cornerCircle}
                                                    style={{
                                                        right: -5,
                                                        bottom: -5,
                                                        cursor: 'nw-resize',
                                                        transform: `scale(${1 / containerZoom})`,
                                                        transformOrigin: 'center'
                                                    }}
                                                    onMouseDown={(e) => handleResizeMouseDown(e, 'bottom-right')}
                                                />
                                            </>
                                        )}
                                        
                                    </div>
                                ))}
                                {selectionBox && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            border: "1px dashed #000",
                                            backgroundColor: "rgba(0, 0, 255, 0.1)",
                                            left: Math.min(selectionBox.x, selectionBox.x + selectionBox.width),
                                            top: Math.min(selectionBox.y, selectionBox.y + selectionBox.length),
                                            width: Math.abs(selectionBox.width),
                                            height: Math.abs(selectionBox.length),
                                            pointerEvents: "none"
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.zoomControls}>
                        <span style={{marginRight: 10}} >{zoomPercentage}%</span>
                        <MinusIcon inlineStyles={{marginRight: "10px"}} onClick={handleDecreaseZoom}/>
                        <PlusIcon strokeWidth="3" stroke="#003366"  inlineStyles={{marginRight: "10px"}} onClick={handleIncreaseZoom}/>
                        <Button text="Сбросить" onClick={handleResetZoom}/>
                    </div>
                </Container>
            </div>

            <Bar 
                selectedActions={selectedActions}
                setSelectedActions={setSelectedActions}
                setSelectedDrowItem={setSelectedDrowItem}
                setDrowItems={setDrowItems}
                selectedDropdownItem={selectedDropdownItem}
                setSelectedDropdownItem={setSelectedDropdownItem}
            />
        </Layout>
    )
}

export { WarehouseDesignerPage };