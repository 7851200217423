import React, { useMemo } from "react";
import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { Container } from "shared/ui/Container";
import { DrowItemType, useGetWarehouseByIdSchemeQuery } from "api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { WarehouseDrowItem } from "pages/WarehouseDesigner";

enum DrowItemTypesEnum {
    Shipment = "Отгрузка",
    Movement = "Перемещение",
    Storage = "Хранение",
    Packaging = "Упаковка",
    Inspection = "Приёмка",
    Section = "Секция",
}

const getDrowItemTypesEnum = (type: DrowItemTypesEnum) => {
    if (type === DrowItemTypesEnum.Shipment) {
        return 0
    }
    else if (type === DrowItemTypesEnum.Movement) {
        return 1
    }
    else if (type === DrowItemTypesEnum.Storage) {
        return 2
    }
    else if (type === DrowItemTypesEnum.Packaging) {
        return 3
    }
    else if (type === DrowItemTypesEnum.Inspection) {
        return 4
    }
    else if (type === DrowItemTypesEnum.Section) {
        return 5
    }

    return 0;
}

const getColorFortype = (option: DrowItemTypesEnum): string => {
    switch (option) {
        case DrowItemTypesEnum.Shipment:
            return "#FF5733"; // Example color for Shipment
        case DrowItemTypesEnum.Movement:
            return "#33FF57"; // Example color for Movement
        case DrowItemTypesEnum.Storage:
            return "#3357FF"; // Example color for Storage
        case DrowItemTypesEnum.Packaging:
            return "#FF33A1"; // Example color for Packaging
        case DrowItemTypesEnum.Inspection:
            return "#A133FF"; // Example color for Inspection
        case DrowItemTypesEnum.Section:
            return "rgba(51, 255, 245, 0.2)"; // Example color for Section
        default:
            return "#CEE7FF"; // Default color
    }
};

const WarehouseSchemePage = () => {
    const [params] = useSearchParams();
    const warehouseId = params.get("warehouseId");

    const { data: warehouseScheme } = useGetWarehouseByIdSchemeQuery({id: parseInt(warehouseId!)}, {skip: warehouseId == null});

    const [warehouseDrowItem, setWarehouseDrowItem] = useState<WarehouseDrowItem>({
        id: 0,
        name: "warehouse",
        width: 700,
        length: 700,
        height: 100,
        isSelected: true,
    });

    useEffect(() => {
        if (warehouseScheme != null) {
            setWarehouseDrowItem({
                width: warehouseScheme.width!,
                height: warehouseScheme.height!,
                length: warehouseScheme.length!,
                id: warehouseScheme.id!,
                name: warehouseScheme.name!,
                isSelected: false
            });

            const mappedDrowItems = warehouseScheme.drowItems!.map(item => ({
                ...item,
                id: item.id!,
                floors: item.floors!.map(floor => ({height: floor.height!, name: floor.name!, id: floor.id!  })),
            }));
            setDrowItems(mappedDrowItems);


        }
        else {
            setDrowItems([]);
        }
    }, [warehouseScheme]);
 
    const [drowItems, setDrowItems] = useState<DrowItemType[]>([]);

    const [selectionBox, setSelectionBox] = useState<{ x: number; y: number; width: number; length: number } | null>(null);

    const selectedDrowItem2 = useMemo(() => {
        const selectedItems = drowItems.filter(item => item.isSelected);
        if (selectedItems.length > 1 || selectedItems.length === 0) {
            return null;
        }
        return selectedItems[0];
    }, [drowItems]);


    const calculateCapacity = (item: WarehouseDrowItem) => {
        return (item.width / 10) * (item.length / 10) * (item.height / 10);
    };
    

    useEffect(() => {
        const preventDefault = (e: WheelEvent) => {
            if (e.ctrlKey) {
                e.preventDefault();
            }
        };

        document.addEventListener('wheel', preventDefault, { passive: false });

        return () => {
            document.removeEventListener('wheel', preventDefault);
        };
    }, []);

    return (
        <Layout>
            <Container inlineStyles={{ 
                    width: "100%",
                    height: "90vh", 
                    overflow: "hidden", 
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <TransformWrapper
                    wheel={{smoothStep: 0.02}}
                    >
                        <TransformComponent>
                        <div
                            style={{
                                width: warehouseDrowItem.width,
                                height: warehouseDrowItem.length,
                                position: 'relative',
                                border: '1px solid black',
                            }}
                            className={clsx(styles.warehouse, styles.drow__item, warehouseDrowItem.isSelected && styles.drow__item_active)}
                            >
                            {drowItems.map(item => (
                                <div
                                    onClick={() => alert(`Selected item: ${item.name}`)}
                                    key={item.id}
                                    className={clsx(
                                        styles.drow__item,
                                        item.isSelected && styles.drow__item_active
                                    )}
                                    style={{
                                        zIndex: item.type == getDrowItemTypesEnum(DrowItemTypesEnum.Section) ? 2 : 3,
                                        left: item.x,
                                        top: item.y,
                                        position: 'absolute',
                                        width: item.width,
                                        height: item.length,
                                        backgroundColor: item.color,
                                        border: '1px solid #003366'
                                    }}>
                                            <>
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        transformOrigin: 'left center',
                                                        fontSize: '10px',
                                                        padding: '2px',
                                                    }}
                                                >
                                                    {`${item.width / 10}м`}
                                                </div>
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        transformOrigin: 'top center',
                                                        fontSize: '10px',
                                                        padding: '2px',
                                                    }}
                                                >
                                                    {`${item.length / 10}м`}
                                                </div>
                                                <div 
                                                    style={{ 
                                                        // position: 'absolute',
                                                        // left: 0,
                                                        // top: 0,
                                                        display: 'flex',
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        width: `100%`,
                                                        height: `100%`,
                                                    }}>
                                                        <div
                                                            style={{
                                                                fontSize: `${10 * (item.length / 10)}px`,
                                                            }}
                                                            >
                                                            {item.name}
                                                        </div>
                                                </div>
                                            </>
                                    {/* Render corner circles if the item is selected */}
                                </div>
                            ))}
                            {selectionBox && (
                                <div
                                    style={{
                                        position: "absolute",
                                        border: "1px dashed #000",
                                        backgroundColor: "rgba(0, 0, 255, 0.1)",
                                        left: Math.min(selectionBox.x, selectionBox.x + selectionBox.width),
                                        top: Math.min(selectionBox.y, selectionBox.y + selectionBox.length),
                                        width: Math.abs(selectionBox.width),
                                        height: Math.abs(selectionBox.length),
                                        pointerEvents: "none"
                                    }}
                                />
                            )}
                        </div>
                            </TransformComponent>
                            </TransformWrapper>
            </Container>
        </Layout>
    )
}

export { WarehouseSchemePage };