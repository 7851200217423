import Layout from "widgets/Layout/Layout";
import React, { useLayoutEffect } from "react";
import styles from "./index.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "shared/ui/Button";
import {Container as SharedContainer} from "shared/ui/Container";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { useGetPaymentsBillQuery } from "api";
import { getColorByPaymentBillStatus, getPaymentBillStatus } from "functions/paymentBillStatus";
import Title from "shared/ui/Title/Title";
import { PaymentDetailType } from "enums/paymentType";
import { IS_PC } from "app/store/consts";
import { ButtonsContainer } from "shared/ui-mobile/ButtonsContainer";
import { List } from "widgets/mobile/List";
import { ListItem } from "widgets/mobile/List/components/Item";
import { ListItemText } from "widgets/mobile/List/components/ListItemText";

const PaymentBillPage = () => {
    const [searchParams] = useSearchParams();
    useLayoutEffect(() => {
        if (!searchParams.has('billId')) {
            navigate(LinkPagesEnum.PaymentBills);
        }
    }, [])
    
    const {data: bill } = useGetPaymentsBillQuery({billId: Number(searchParams.get('billId'))});

    const navigate = useNavigate();

    if (bill == null) {
        return null;
    }

    if (IS_PC) {
    return (
        <Layout>
                <div className={styles.header}>
                    <div style={{display: "flex"}}>
                        <Button inlineStyles={{marginRight: 10}} text="Назад" onClick={() => navigate(LinkPagesEnum.PaymentBills)}/>
                        <Button 
                            inlineStyles={{marginRight: 10}}
                            text="Оплатить счёт"
                            onClick={() => {}}
                            />
                        <Button text="Экспорт" onClick={() => {}}/>
                    </div>
                </div>
                <div className={styles.body}>
                    <Title inlineClasses={styles.title} inlineStyles={{fontSize: 20}}>
                        ID: {bill.id}
                    </Title>
                    <SharedContainer inlineStyles={{marginBottom: 10}} inlineClasses={styles.container}>
                        <>
                            {bill.type == 0 &&
                            <div style={{marginBottom: 10}} className={styles.textBlock}>
                                <div className={styles.nameText}>Юр лицо:</div>
                                <div className={styles.valueText}>{bill.organization?.name}</div>
                            </div>}
                            {bill.detailType == PaymentDetailType.ExpensesOnSalary &&
                            <div style={{marginBottom: 10}} className={styles.textBlock}>
                                <div className={styles.nameText}>ЗП сотруднику:</div>
                                <div className={styles.valueText}>{bill.salaryForEmployee?.user?.email}</div>
                            </div>}
                            {bill.detailType == PaymentDetailType.ExpensesOnConsumables &&
                            <div style={{marginBottom: 10}} className={styles.textBlock}>
                                <div className={styles.nameText}>Покупка расходников</div>
                            </div>}
                            {bill.detailType == PaymentDetailType.ExpensesOnIncidents &&
                            <div style={{marginBottom: 10}} className={styles.textBlock}>
                                <div className={styles.nameText}>Оплата инцидента</div>
                            </div>}
                            <div style={{marginBottom: 10}} className={styles.textBlock}>
                                <div className={styles.nameText}>Сумма:</div>
                                <div className={styles.valueText}>{bill!.paymentBillDetails?.reduce((s, p) => s + p.paymentAmount!, 0)}₽</div>
                            </div>
                            <div className={styles.textBlock}>
                                <div className={styles.nameText}>
                                    Статус оплаты:
                                </div>
                                <div className={styles.valueText} style={{color: getColorByPaymentBillStatus(bill.paymentStatus!)}}>
                                    {getPaymentBillStatus(bill.paymentStatus!)}
                                </div>
                            </div>
                        </>
                    </SharedContainer>
                    {bill.note != null && 
                        <SharedContainer inlineClasses={styles.container}>
                            <div className={styles.textBlock} style={{flexDirection: "column"}}>
                                <div className={styles.noteNameText}>Коментарий от склада:</div>
                                <div className={styles.noteValueText}>{bill.note}</div>
                            </div>
                        </SharedContainer>
                    }
                </div>
                <div style={{marginTop: 20}}>
                    <Title inlineStyles={{marginBottom: 20, fontSize: 20}}>
                        Подробнее:
                    </Title>
                    <div className={styles.details}>
                        {bill.paymentBillDetails?.map(paymentBill => 
                            <SharedContainer inlineClasses={styles.detail}>
                                <div style={{display: "flex", marginBottom: 10}}>
                                    <div className={styles.nameText}>Наименование:</div>
                                    <div className={styles.valueText}>{paymentBill.name}</div>
                                </div>
                                {/* <div className={styles.detail__name}>Количество:</div>
                                <div style={{marginBottom: 10}} className={styles.detail__value}>{paymentBill.quantity}</div> */}
                                <div style={{display: "flex"}}>
                                    <div className={styles.nameText}>Сумма:</div>
                                    <div className={styles.valueText}>{paymentBill.paymentAmount}₽</div>
                                </div>
                            </SharedContainer>
                        )}
                    </div>
                </div>
        </Layout>
    )}

    return (
        <Layout>
            <ButtonsContainer>
                <Button inlineStyles={{marginRight: 10}} text="Назад" onClick={() => navigate(LinkPagesEnum.PaymentBills)}/>
                <Button 
                    inlineStyles={{marginRight: 10}}
                    text="Оплатить счёт"
                    onClick={() => {}}
                    />
                <Button text="Экспорт" onClick={() => {}}/>
            </ButtonsContainer>
            <div className={styles.body}>
                    <Title inlineClasses={styles.title} inlineStyles={{fontSize: 20}}>
                        ID: {bill.id}
                    </Title>
                    <SharedContainer inlineStyles={{marginBottom: 10}} inlineClasses={styles.container}>
                        <>
                            {bill.type == 0 &&
                            <div style={{marginBottom: 10}} className={styles.textBlock}>
                                <div className={styles.nameText}>Юр лицо:</div>
                                <div className={styles.valueText}>{bill.organization?.name}</div>
                            </div>}
                            {bill.detailType == PaymentDetailType.ExpensesOnSalary &&
                            <div style={{marginBottom: 10}} className={styles.textBlock}>
                                <div className={styles.nameText}>ЗП сотруднику:</div>
                                <div className={styles.valueText}>{bill.salaryForEmployee?.user?.email}</div>
                            </div>}
                            {bill.detailType == PaymentDetailType.ExpensesOnConsumables &&
                            <div style={{marginBottom: 10}} className={styles.textBlock}>
                                <div className={styles.nameText}>Покупка расходников</div>
                            </div>}
                            {bill.detailType == PaymentDetailType.ExpensesOnIncidents &&
                            <div style={{marginBottom: 10}} className={styles.textBlock}>
                                <div className={styles.nameText}>Оплата инцидента</div>
                            </div>}
                            <div style={{marginBottom: 10}} className={styles.textBlock}>
                                <div className={styles.nameText}>Сумма:</div>
                                <div className={styles.valueText}>{bill!.paymentBillDetails?.reduce((s, p) => s + p.paymentAmount!, 0)}₽</div>
                            </div>
                            <div className={styles.textBlock}>
                                <div className={styles.nameText}>
                                    Статус оплаты:
                                </div>
                                <div className={styles.valueText} style={{color: getColorByPaymentBillStatus(bill.paymentStatus!)}}>
                                    {getPaymentBillStatus(bill.paymentStatus!)}
                                </div>
                            </div>
                        </>
                    </SharedContainer>
                    {bill.note != null && 
                        <SharedContainer inlineClasses={styles.container}>
                            <div className={styles.textBlock} style={{flexDirection: "column"}}>
                                <div className={styles.noteNameText}>Коментарий от склада:</div>
                                <div className={styles.noteValueText}>{bill.note}</div>
                            </div>
                        </SharedContainer>
                    }
                </div>
                <div style={{marginTop: 20}}>
                    <Title inlineStyles={{marginBottom: 20, fontSize: 20}}>
                        Подробнее:
                    </Title>
                    <List>
                        {bill.paymentBillDetails?.map(paymentBill => 
                            <ListItem
                                inlineStyles={{marginBottom: 10}}
                                >
                                <ListItemText
                                    name="Наименование: "
                                    value={paymentBill.name}
                                    inlineStyles={{marginBottom: 5}}
                                    />
                                <ListItemText
                                    name="Сумма: "
                                    value={paymentBill.paymentAmount + "₽"}
                                    />
                            </ListItem>
                        )}
                    </List>
                </div>
        </Layout>
    )
};

export {PaymentBillPage};