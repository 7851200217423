import { ProductRedactModel, useDeleteProductsImageMutation, useGetOrganizationsQuery, useGetProductsByIdQuery, usePostProductsImageMutation, usePostProductsRedactMutation } from "api"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { Input } from "shared/ui/Input"
import { MiniTitle } from "shared/ui/MiniTitle"
import { Select } from "shared/ui/Select"
import Layout from "widgets/Layout/Layout"
import styles from "./index.module.scss";
import stylesm from "./index.m.module.scss";
import { ImageSelector } from "features/ImageSelector"
import { NavigateButton } from "shared/ui/NavigateButton"
import { LinkPagesEnum } from "app/store/enums/linkPages"
import { Button } from "shared/ui/Button"
import { useNavigate, useSearchParams } from "react-router-dom"
import { getImageUrl } from "functions/image"
import { useAppSelector } from "app/store/hooks/redux"
import { IS_PC } from "app/store/consts"

const RedactProductPage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    useLayoutEffect(() => {
        if (!searchParams.has('productId')) {
            navigate(LinkPagesEnum.Products);
        }
    }, [])

    const {data: product} = useGetProductsByIdQuery({id: Number(searchParams.get('productId'))});
    
    const {isClient} = useAppSelector(state => state.userReducer);

    const {data: organizations} = useGetOrganizationsQuery();
    const [redactedProduct, setRedactedProduct] = useState<ProductRedactModel>({});

    const [redactProduct] = usePostProductsRedactMutation();

    const [createImage] = usePostProductsImageMutation();
    const [deleteImage] = useDeleteProductsImageMutation();

    const redactProductHandler = async () => {
        try {
            await redactProduct({
                productRedactModel: redactedProduct
            }).unwrap();

            if (currentImage != null || previewImage != null) {
                await createImage({body: {
                    ProductId: redactedProduct.id,
                    File: currentImage,
                }})
            }
            else {
                await deleteImage({deleteProductImageInput: {productId: redactedProduct.id}})
            }

        } catch (err) {
            // Handle error
        }
    };

    useEffect(() => {
        if (product != null) {
            setRedactedProduct({
                organizationId: product.organizationId,
                length: product.length?? 0,
                height: product.height?? 0,
                width: product.width?? 0,
                weight: product.weight?? 0,
                id: product.id,
                serviceProductPrice: product.serviceProductPrice ?? undefined,
                name: product.name ?? undefined,
                factoryBarcode: product.factoryBarcode ?? undefined,
                article: product.article ?? undefined,
                color: product.color ?? undefined,
                barcode: product.barcode ?? undefined,
            });

            setPreviewImage(getImageUrl(product.imageLinks))
        }
    }, [product])

    const [currentImage, setCurrentImage] = useState<File>();
    const [previewImage, setPreviewImage] = useState("");
    
    if (IS_PC) {
    return (
        <Layout>
            <div>
                <div style={{display: "flex", marginBottom: 20}}>
                    <NavigateButton
                        to={LinkPagesEnum.Products}
                        text="Назад"
                        inlineStyles={{marginRight: 10}}
                        />
                    {isClient && <Button
                        text="Сохранить"
                        onClick={redactProductHandler}
                        />}
                </div>
                <div>
                    <div className={styles.fieldsContainer}>
                        <div className={styles.fields}>
                            <MiniTitle inlineStyles={{marginBottom: 20}}>
                                Информация о товаре
                            </MiniTitle>
                            <Input placeholder="Название" value={redactedProduct.name} onChange={e => setRedactedProduct({...redactedProduct, name: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            <Input placeholder="Артикул" value={redactedProduct.article} onChange={e => setRedactedProduct({...redactedProduct, article: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            <Input placeholder="Цвет" value={redactedProduct.color} onChange={e => setRedactedProduct({...redactedProduct, color: e.target.value })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            <Input placeholder="Цена обслуживания" value={redactedProduct.serviceProductPrice?.toString()} onChange={e => setRedactedProduct({...redactedProduct, serviceProductPrice: Number(e.target.value)})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            <Select placeholder="Организация" options={organizations?.map(el => ({value: el.name!, key: el.id!})) ?? []} selectedOptionKey={redactedProduct.organizationId} onChange={val => setRedactedProduct({...redactedProduct, organizationId: val.key})} classNames={styles.modal__field}/>
                        </div>
                        <div className={styles.fields}>
                            <MiniTitle inlineStyles={{marginBottom: 20}}>
                                Габариты и вес
                            </MiniTitle>
                            <Input type="number" placeholder="Высота упаковки, см" value={redactedProduct.height?.toString()} onChange={e => setRedactedProduct({...redactedProduct, height: Number(e.target.value)})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            <Input type="number" placeholder="Длина упаковки, см" value={redactedProduct.length?.toString()} onChange={e => setRedactedProduct({...redactedProduct, length: Number(e.target.value)})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            <Input type="number" placeholder="Ширина упаковки, см" value={redactedProduct.width?.toString()} onChange={e => setRedactedProduct({...redactedProduct, width: Number(e.target.value) })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            <Input type="number" placeholder="Объём см3" value={(redactedProduct.height! * redactedProduct.length! * redactedProduct.width!).toString()} onChange={e => {}} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            <Input type="number" placeholder="Вес товара кг" value={redactedProduct.weight?.toString()} onChange={e => setRedactedProduct({...redactedProduct, weight: Number(e.target.value)})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                        </div>
                    </div>
                    <div className={styles.fieldsContainer} style={{marginTop: 20}}>
                        <div className={styles.fields}>
                            <MiniTitle inlineStyles={{marginBottom: 20}}>
                                Штрих - коды
                            </MiniTitle>
                            <Input placeholder="Внешний баркод" value={redactedProduct.factoryBarcode} onChange={e => setRedactedProduct({...redactedProduct,factoryBarcode: e.target.value })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            <Input placeholder="Заводской баркод" value={redactedProduct.barcode} onChange={e => setRedactedProduct({...redactedProduct, barcode: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                            {/* <div>
                                <Input
                                    placeholder=""
                                    value={}
                                    onChange={() => }
                                    />
                            </div> */}
                        </div>
                        <div className={styles.fields}>
                            <MiniTitle inlineStyles={{marginBottom: 20}}>
                                Изображение
                            </MiniTitle>
                            <ImageSelector
                                inlineStyles={{width: 400, height: 250}}
                                previewImage={previewImage}
                                setCurrentImage={setCurrentImage}
                                setPreviewImage={setPreviewImage}
                                />                                    
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
    }
    return (
        <Layout>
            <>
                <div style={{display: "flex", marginBottom: 20}}>
                    <NavigateButton
                        to={LinkPagesEnum.Products}
                        text="Назад"
                        inlineStyles={{marginRight: 10}}
                        />
                    {isClient && <Button
                        text="Сохранить"
                        onClick={redactProductHandler}
                        />}
                </div>
                <MiniTitle inlineStyles={{marginBottom: 10}}>
                    Изображение
                </MiniTitle>
                <ImageSelector
                    inlineStyles={{width: "100%", height: 150, marginBottom: 10}}
                    previewImage={previewImage}
                    setCurrentImage={setCurrentImage}
                    setPreviewImage={setPreviewImage}
                    />   
                <MiniTitle inlineStyles={{marginBottom: 10}}>
                    Информация о товаре
                </MiniTitle>
                <Input placeholder="Название" value={redactedProduct.name} onChange={e => setRedactedProduct({...redactedProduct, name: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.field}/>
                <Input placeholder="Артикул" value={redactedProduct.article} onChange={e => setRedactedProduct({...redactedProduct, article: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.field}/>
                <Input placeholder="Цвет" value={redactedProduct.color} onChange={e => setRedactedProduct({...redactedProduct, color: e.target.value })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.field}/>
                <Input placeholder="Цена обслуживания" value={redactedProduct.serviceProductPrice?.toString()} onChange={e => setRedactedProduct({...redactedProduct, serviceProductPrice: Number(e.target.value)})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.field}/>
                <Select placeholder="Организация" options={organizations?.map(el => ({value: el.name!, key: el.id!})) ?? []} selectedOptionKey={redactedProduct.organizationId} onChange={val => setRedactedProduct({...redactedProduct, organizationId: val.key})} inlineStyles={{marginBottom: 10}} classNames={styles.field}/>
                <MiniTitle inlineStyles={{marginBottom: 10}}>
                    Габариты и вес
                </MiniTitle>
                <Input type="number" placeholder="Высота упаковки, см" value={redactedProduct.height?.toString()} onChange={e => setRedactedProduct({...redactedProduct, height: Number(e.target.value)})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.field}/>
                <Input type="number" placeholder="Длина упаковки, см" value={redactedProduct.length?.toString()} onChange={e => setRedactedProduct({...redactedProduct, length: Number(e.target.value)})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.field}/>
                <Input type="number" placeholder="Ширина упаковки, см" value={redactedProduct.width?.toString()} onChange={e => setRedactedProduct({...redactedProduct, width: Number(e.target.value) })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.field}/>
                <Input type="number" placeholder="Объём см3" value={(redactedProduct.height! * redactedProduct.length! * redactedProduct.width!).toString()} onChange={e => {}} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.field}/>
                <Input type="number" placeholder="Вес товара кг" value={redactedProduct.weight?.toString()} onChange={e => setRedactedProduct({...redactedProduct, weight: Number(e.target.value)})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.field}/>
                <MiniTitle inlineStyles={{marginBottom: 10}}>
                    Штрих - коды
                </MiniTitle>
                <Input placeholder="Внешний баркод" value={redactedProduct.factoryBarcode} onChange={e => setRedactedProduct({...redactedProduct,factoryBarcode: e.target.value })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                <Input placeholder="Заводской баркод" value={redactedProduct.barcode} onChange={e => setRedactedProduct({...redactedProduct, barcode: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
            </>
        </Layout>
    )
}

export {
    RedactProductPage
}