import { ProductOutputModel } from "api";
import { ReactNode } from "react";
import { BaseSharedComponentType } from "types";
import { ListItem } from "widgets/mobile/List/components/Item";
import { ListItemText } from "widgets/mobile/List/components/ListItemText";
import styles from "./index.module.scss";
import { getImageUrlByStrings } from "functions/image";

type Props = {
    children?: ReactNode;
    warehouseName?: string | undefined | null;
    onClick?: (el: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
} & BaseSharedComponentType & ProductOutputModel;

const Product = ({
    onClick,
    objectCreateDate,
    serviceProductPrice,
    organizationName,
    id,
    images,
    quantity,
    packageQuantity,
    capacity,
    name,
    article,
    color,
    factoryBarcode,
    barcode,
    childs,
    consumables,
    warehouseName,
    children,
    inlineClasses,
    inlineStyles}: Props) => {
    return (
        <ListItem onClick={onClick} inlineClasses={inlineClasses} inlineStyles={inlineStyles}>
            <img className={styles.img} src={getImageUrlByStrings(images)}/>
            {id && (
                <ListItemText
                    name="Id:"
                    value={id}
                    inlineStyles={{ marginBottom: 5 }}
                />
            )}
            {name && (
                <ListItemText
                    name="Наименование:"
                    value={name}
                    inlineStyles={{ marginBottom: 5 }}
                />
            )}
            {barcode && (
                <ListItemText
                    name="Штрихкод:"
                    value={barcode}
                    inlineStyles={{ marginBottom: 5 }}
                />
            )}
            {organizationName && (
                <ListItemText
                    name="Юр лицо:"
                    value={organizationName}
                    inlineStyles={{ marginBottom: 5 }}
                />
            )}
            {color && (
                <ListItemText
                    name="Цвет:"
                    value={color}
                    inlineStyles={{ marginBottom: 5 }}
                />
            )}
            {capacity && (
                <ListItemText
                    name="Объём:"
                    value={capacity}
                    inlineStyles={{ marginBottom: 5 }}
                />
            )}
            {serviceProductPrice && (
                <ListItemText
                    name="Цена услуги:"
                    value={serviceProductPrice}
                    inlineStyles={{ marginBottom: 5 }}
                />
            )}
            {quantity && (
                <ListItemText
                    name="Количество:"
                    value={quantity}
                    inlineStyles={{ marginBottom: 5 }}
                />
            )}
            {packageQuantity && (
                <ListItemText
                    name="Количество в упаковке:"
                    value={packageQuantity}
                    inlineStyles={{ marginBottom: 5 }}
                />
            )}
            {article && (
                <ListItemText
                    name="Артикул:"
                    value={article}
                    inlineStyles={{ marginBottom: 5 }}
                />
            )}
            {factoryBarcode && (
                <ListItemText
                    name="Фабричный штрихкод:"
                    value={factoryBarcode}
                    inlineStyles={{ marginBottom: 5 }}
                />
            )}
            {objectCreateDate && (
                <ListItemText
                    name="Дата создания:"
                    value={objectCreateDate}
                    inlineStyles={{ marginBottom: 5 }}
                />
            )}
            {warehouseName && (
                <ListItemText
                    name="Склад:"
                    value={warehouseName}
                    inlineStyles={{ marginBottom: 5 }}
                />
            )}
            {children}
        </ListItem>
    );
};

export {
    Product
}