import Layout from "widgets/Layout/Layout";
import { News } from "./components/news";
import styles from "./index.module.scss";
import clsx from "clsx";
import { HomeEmployee } from "./employee";
import { useAppSelector } from "app/store/hooks/redux";
import { HomeClient } from "./client";

const HomePage = () => {

    const {isClient} = useAppSelector(state => state.userReducer);

    return (
        <Layout>
            <div className={clsx(styles.container)}>
                {isClient 
                    ? <HomeClient/> 
                    : <HomeEmployee/>}
                <News/>
            </div>
        </Layout>
    )
};

export { HomePage };