import { createBrowserRouter, redirect, RouteObject, RouterProvider } from 'react-router-dom';
import { RegistrationPage } from './Registration/ui';
import { AuthorizationPage } from './Authorization/ui';
import { HomePage } from './Home/ui';
import { DocumentsPage } from './Documents/ui';
import { AcceptanceBillsPage } from './AcceptanceBills/ui';
import { AddingAcceptanceBillPage } from './AddingAcceptanceBill/ui';
import { ProductsPage } from './Products/ui';
import { InventoryPage } from './Inventory/ui';
import { CellsPage } from './Cells/ui';
import { TerminalPage } from './Terminal/ui/Terminal';
import { InspectionPage } from './Inspection/ui';
import { ShipmentBillsPage } from './ShipmentBills';
import { SettingsPage } from './Settings/ui/Settings';
import { useAppSelector } from '../app/store/hooks/redux';
import { UserProfilePage } from './Profile';
import { AddingShipmentBillPage } from './AddingShipmentBill';
import { PackagePage } from './Package';
import { ShipPage } from './Ship';
import { EmployeesPage } from './Employees';
import { UsersPage } from './Users';
import { SoonPage } from './Soon';
import { InformationPage } from './Information';
import { ProcessesPage } from './Processes/ui';
import { InspectionProcessPage } from './Inspection/pages/Process';
import { ShipProcessPage } from './Ship/pages/Process';
import { LinkPagesEnum } from 'app/store/enums/linkPages';
import { RedactAcceptanceBillPage } from './RedactAcceptanceBill/ui';
import { RedactShipmentBillPage } from './RedactShipmentBill/ui';
import { PaymentBillsPage } from './PaymentBills';
import { PaymentBillPage } from './PaymentBill';
import { AddingKitPage } from './AddingKit';
import { AddingPackageBillPage } from './AddingPackageBill';
import { PackageBillsPage } from './PackageBills';
import { AddingPlacementBillPage } from './AddingPlacementBill';
import { PlacementBillsPage } from './PlacementBills';
import { PackageProcessPage } from './Package/pages/Process';
import { PlacementPage } from './Placement';
import { PlacementProcessPage } from './Placement/pages/Process';
import { CooperationsPage } from './Cooperations';
import { OrganizationsPage } from './Organizations';
import { ConsumablesPage } from './Consumables';
import { IntegrationsPage } from './Integrations';
import { ChartsPage } from './Charts';
import { CalendarPage } from './Calendar';
import { RedactPackageBillPage } from './RedactPackageBill';
import { RedactPlacementBillPage } from './RedactPlacementBill';
import { AddingProductPage } from './AddingProduct';
import { RedactProductPage } from './RedactProduct';
import { InformationBasePage } from './InformationBase';
import { AddingWarehousePage } from './AddingWarehouse';
import { SupportPage } from './Support';
import { WarehouseDesignerPage } from './WarehouseDesigner';
import { WarehouseSchemePage } from './WarehouseScheme';

type RouteType = {
  pageName: string;
  routeObject: RouteObject
};

export const routes: RouteType[] = [
  {
    pageName: "Склад",
    routeObject: {
      path: LinkPagesEnum.WarehouseScheme,
      element: <WarehouseSchemePage/>,
    }
  },
  {
    pageName: "Конструктор",
    routeObject: {
      path: LinkPagesEnum.WarehouseDesigner,
      element: <WarehouseDesignerPage/>,
    }
  },
  {
    pageName: "Поддержка",
    routeObject: {
      path: LinkPagesEnum.Support,
      element: <SupportPage/>,
    }
  },
  {
    pageName: "Создание склада",
    routeObject: {
      path: LinkPagesEnum.AddingWarehouse,
      element: <AddingWarehousePage/>,
    }
  },
  {
    pageName: "База информации",
    routeObject: {
      path: LinkPagesEnum.InformationBase,
      element: <InformationBasePage/>,
    }
  },
  {
    pageName: "Редактирование товара",
    routeObject: {
      path: LinkPagesEnum.RedactProduct,
      element: <RedactProductPage/>,
    }
  },
  {
    pageName: "Создание товара",
    routeObject: {
      path: LinkPagesEnum.AddingProduct,
      element: <AddingProductPage/>,
    }
  },
  {
    pageName: "Накладная на перемещение",
    routeObject: {
      path: LinkPagesEnum.RedactPlacementBill,
      element: <RedactPlacementBillPage/>,
    }
  },
  {
    pageName: "Накладная на упаковку",
    routeObject: {
      path: LinkPagesEnum.RedactPackageBill,
      element: <RedactPackageBillPage />,
    }
  },
  {
    pageName: "Календарь",
    routeObject: {
      path: LinkPagesEnum.Calender,
      element: <CalendarPage />,
    }
  },
  {
    pageName: "Аналитика",
    routeObject: {
      path: LinkPagesEnum.Analytics,
      element: <ChartsPage />,
    }
  },
  {
    pageName: "Интеграции",
    routeObject: {
      path: LinkPagesEnum.Integrations,
      element: <IntegrationsPage />,
    }
  },
  {
    pageName: "Расходники",
    routeObject: {
      path: LinkPagesEnum.Consumables,
      element: <ConsumablesPage />,
    }
  },
  {
    pageName: "Организации",
    routeObject: {
      path: LinkPagesEnum.Organizations,
      element: <OrganizationsPage />,
    }
  },
  {
    pageName: "Сотрудничество",
    routeObject: {
      path: LinkPagesEnum.Cooperations,
      element: <CooperationsPage />,
    }
  },
  {
    pageName: "Транзитные накладные",
    routeObject: {
      path: LinkPagesEnum.PlacementBills,
      element: <PlacementBillsPage />,
    }
  },
  {
    pageName: "Создание накладной перемещения",
    routeObject: {
      path: LinkPagesEnum.AddingPlacementBill,
      element: <AddingPlacementBillPage />,
    }
  },
  {
    pageName: "Упаковочные накладные",
    routeObject: {
      path: LinkPagesEnum.PackageBills,
      element: <PackageBillsPage />,
    }
  },
  {
    pageName: "Создание накладной упаковки",
    routeObject: {
      path: LinkPagesEnum.AddingPackageBill,
      element: <AddingPackageBillPage />,
    }
  },
  {
    pageName: "Создание комплекта",
    routeObject: {
      path: LinkPagesEnum.AddingKit,
      element: <AddingKitPage />,
    }
  },
  {
    pageName: "Счет",
    routeObject: {
      path: LinkPagesEnum.PaymentBill,
      element: <PaymentBillPage />,
    }
  },
  {
    pageName: "Счета",
    routeObject: {
      path: LinkPagesEnum.PaymentBills,
      element: <PaymentBillsPage />,
    }
  },
  {
    pageName: "Процесс отгрузки",
    routeObject: {
      path: LinkPagesEnum.ShipProcess,
      element: <ShipProcessPage />,
    }
  },
  {
    pageName: "Процесс приёмки",
    routeObject: {
      path: LinkPagesEnum.InspectionProcess,
      element: <InspectionProcessPage />,
    }
  },
  {
      pageName: "Регистрация",
      routeObject: {
        path: LinkPagesEnum.Registration,
        element: <RegistrationPage />,
      }
    },
    {
      pageName: "Информация",
      routeObject: {
        path: LinkPagesEnum.Information,
        element: <InformationPage />,
      }
    },
    {
      pageName: "Авторизация",
      routeObject: {
        path: LinkPagesEnum.Authorization,
        element: <AuthorizationPage />,
      }
    },
    {
      pageName: "Профиль",
      routeObject: {
        path: LinkPagesEnum.UserProfile,
        element:<UserProfilePage/>
      }
    },
    {
      pageName: "Главная",
      routeObject: {
        path: LinkPagesEnum.Home,
        element: <HomePage />,
      }
    },
    {
      pageName: "Документы",
      routeObject: {
        path: LinkPagesEnum.Documents,
        element: <DocumentsPage />,
      }
    },
    {
      pageName: "Отгрузка",
      routeObject: {
        path: LinkPagesEnum.Ship,
        element: <ShipPage />,
      }
    },
    {
      pageName: "Накладные на приход",
      routeObject: {
        path: LinkPagesEnum.AcceptanceBills,
        element: <AcceptanceBillsPage />,
      }
    },
    {
      pageName: "Накладные на отгрузку",
      routeObject: {
        path: LinkPagesEnum.ShipmentBills,
        element: <ShipmentBillsPage />,
      }
    },
    {
      pageName: "Накладная на отгрузку",
      routeObject: {
        path: LinkPagesEnum.RedactShipmentBill,
        element: <RedactShipmentBillPage />,
      }
    },
    {
      pageName: "Накладная на приход",
      routeObject: {
        path: LinkPagesEnum.RedactAcceptanceBill,
        element: <RedactAcceptanceBillPage />,
      }
    },
    {
      pageName: "Накладная на приход",
      routeObject: {
        path: LinkPagesEnum.AddingAcceptanceBill,
        element: <AddingAcceptanceBillPage />,
      }
    },
    {
      pageName: "Накладная на отгрузку",
      routeObject: {
        path: LinkPagesEnum.AddingShipmentBill,
        element: <AddingShipmentBillPage />,
      }
    },
    {
      pageName: "Товары",
      routeObject: {
        path: LinkPagesEnum.Products,
        element: <ProductsPage />
      }
    },
    {
      pageName: "Инветаризация",
      routeObject: {
        path: LinkPagesEnum.Inventory,
        element: <InventoryPage />,
      }
    },
    {
      pageName: "Ячейки",
      routeObject: {
        path: LinkPagesEnum.Cells,
        element: <CellsPage />,
      }
    },
    {
      pageName: "Дашбоард",
      routeObject: {
        path: LinkPagesEnum.Terminal,
        element: <TerminalPage />,
      }
    },
    {
      pageName: "Приёмка",
      routeObject: {
        path: LinkPagesEnum.Inspection,
        element: <InspectionPage />,
      }
    },
    {
      pageName: "Настройки",
      routeObject: {
        path: LinkPagesEnum.Settings,
        element: <SettingsPage />,
      }
    },
    {
      pageName: "Перемещение",
      routeObject: {
        path: LinkPagesEnum.PlacementProcess,
        element: <PlacementProcessPage />,
      }
    },
    {
      pageName: "Перемещение",
      routeObject: {
        path: LinkPagesEnum.Placement,
        element: <PlacementPage />,
      }
    },
    {
      pageName: "Упаковка",
      routeObject: {
        path: LinkPagesEnum.Package,
        element: <PackagePage />,
      }
    },
    {
      pageName: "Упаковка",
      routeObject: {
        path: LinkPagesEnum.PackageProcess,
        element: <PackageProcessPage />,
      }
    },
    {
      pageName: "Сотрудники",
      routeObject: {
        path: LinkPagesEnum.Employees,
        element: <EmployeesPage />,
      }
    },
    {
      pageName: "Пользователи",
      routeObject: {
        path: LinkPagesEnum.Users,
        element: <UsersPage/>,
      }
    },
    {
      pageName: "Скоро",
      routeObject: {
        path: LinkPagesEnum.Soon,
        element: <SoonPage/>,
      }
    },
    {
      pageName: "Процессы",
      routeObject: {
        path: LinkPagesEnum.Processes,
        element: <ProcessesPage/>,
      }
    }
];

const Pages = () => {
  const user = useAppSelector(state => state.userReducer)

  const browserRouterUnAuthLoader = (to:string) => {
    const userAuthorized = user.token;
      if(!userAuthorized){
        return redirect("/information")
      }
      return {}
  }

  const browserRouterAuthLoader = () =>{
    const userAuthorized = user.token;
    console.log(user)
    if(userAuthorized){
      return redirect("/")
    }
    return {}
  }

  const router = createBrowserRouter(routes.map( el => 
    {
      if(el.routeObject.path != "/authorization" && el.routeObject.path != "/registration" && el.routeObject.path != "/information"){
        el.routeObject.loader = () => browserRouterUnAuthLoader(el.routeObject.path!)
      }
      else{
        el.routeObject.loader = () => browserRouterAuthLoader()
      }
  
      return el.routeObject
    }));

  return <RouterProvider router={router} />;
};

export default Pages; 