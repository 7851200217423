import { ReactNode } from "react"
import styles from "./index.module.scss";
import { BaseSharedComponentType } from "types";
import clsx from "clsx";

type PropsType = {
    hover?: boolean;
    children: ReactNode;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
} & BaseSharedComponentType;

const Container = ({children, inlineStyles, inlineClasses, onClick, hover}: PropsType) => {
    return (
        <div  className={clsx(styles.container, hover && styles.hover, inlineClasses)} style={inlineStyles} onClick={onClick}>
            {children}
        </div>
    )
}

export {
    Container
};