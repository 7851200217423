export enum PaymentDetailType {
    ProfitAcceptanceBill = 0,
    ProfitShipmentBill = 1,
    ProfitPackageBill = 2,
    ProfitStorage = 3,
    ProfitOther = 4,
    ExpensesOnConsumables = 5,
    ExpensesOnSalary = 6,
    ExpensesOnIncidents = 7,
    ExpensesOnDIF = 8,
    ExpensesOther = 9,
}