export const NotMore = (number: number | undefined | null, notMoreNumber: number | undefined | null) => {
	if (number == null) {
		return 0;
	}
	else if (notMoreNumber == null) {
		return 0;
	}
	else if (number > notMoreNumber) { 
		return notMoreNumber;
	}

	return number;
};

export type SumType = number | null | undefined;

export const Sum = (array: SumType[] | null | undefined) => {
	if (array == null) {
		return 0;
	}

	let sum = 0;

    for (let i = 0; i < array.length; i++) {
        if (array[i]!= null) {
            sum += array[i]!;
        }
    }

    return sum;
}