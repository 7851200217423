import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import { Container } from "shared/ui/Container";
import Title from "shared/ui/Title/Title";
import React, { useEffect, useState } from "react";
import { ImageSelector } from "features/ImageSelector";
import { Input } from "shared/ui/Input";
import { UpdateUserProfileModel, useGetUsersProfileQuery, usePostAuthRegistrationMutation, usePutUsersMeMutation, usePutUsersProfileImageMutation } from "api";
import { OptionType, Select } from "shared/ui/Select";
import { Button } from "shared/ui/Button";
import { BASE_URL_FILES, IS_PC } from "app/store/consts";
import { useAppDispatch, useAppSelector } from "app/store/hooks/redux";
import { useNavigate } from "react-router-dom";
import { userSlice } from "app/store/reducers/UserSlice";

const genders:OptionType[] = [
    {value: "Мужчина", key: 1},
    {value: "Женщина", key: 2},
];

const UserProfilePage = () => {
    const {employeeId, warehouseOwnerId} = useAppSelector(state => state.userReducer);
    const {data: immutableProfileData} = useGetUsersProfileQuery();
    const [profileData, setProfileData] = useState<UpdateUserProfileModel>({});
    
    useEffect(()=> {
        setProfileData({
            name: immutableProfileData?.name,
            secondName: immutableProfileData?.secondName,
            gender: immutableProfileData?.gender,
            birtdayDate: immutableProfileData?.birtdayDate,
            telephone: immutableProfileData?.telephone,
            email: immutableProfileData?.email,
            password: immutableProfileData?.password,
        });
        if (immutableProfileData?.image != null) {
            fetch(BASE_URL_FILES + immutableProfileData?.image?.name)
                .then(response => response.blob())
                .then(blob => setCurrentImage(new File([blob], immutableProfileData?.image == null ? "" : immutableProfileData!.image!.name!)));
        }

        setPreviewImage(immutableProfileData?.image != null ? BASE_URL_FILES + immutableProfileData?.image?.name : "");
    }, [immutableProfileData])

    const [updateMe, {isLoading: isUpdateFetching}] = usePutUsersMeMutation();
    const [updateProfileImage, {isLoading: isUpdateImageFetching}] = usePutUsersProfileImageMutation();

    const [currentImage, setCurrentImage] = useState<File>();
    const [previewImage, setPreviewImage] = useState("");
    const [addUser, {isLoading: isAddFetching}] = usePostAuthRegistrationMutation();

    const updateMeHandler = async () => {
        var user = await updateMe({updateUserProfileModel: profileData}).unwrap();
        await addUser({authCredentials: {email: user.email, password: user.password}}).unwrap()
            .then(res => {
                localStorage.setItem("authToken", res.authToken!);
            })
            .catch(error => {
            })

        await updateProfileImage({body: {
            image: currentImage
        }});
    };

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {setToken} = userSlice.actions
    
    const deleteToken = () => {
        localStorage.removeItem("authToken");
        dispatch(setToken(null))
        navigate('/authorization');
    };

    if (IS_PC)
    {
    return (
        <Layout>
            <Container inlineClasses={styles.container}>
                    <ImageSelector
                        inlineStyles={{width: 195, height: 150, margin: "0 auto"}}
                        previewImage={previewImage} 
                        setCurrentImage={setCurrentImage} 
                        setPreviewImage={setPreviewImage}
                        />
                    <div className={styles.fieldsContainer}>
                        <Input
                            classNamesContainer={styles.field}
                            placeholder="Имя"
                            value={profileData?.name}
                            onChange={e => setProfileData({...profileData, name: e.target.value})}
                            />
                        <Input
                            classNamesContainer={styles.field}
                            placeholder="Фамилия"
                            value={profileData?.secondName}
                            onChange={e => setProfileData({...profileData, secondName: e.target.value})}
                            />
                        <Select
                            classNames={styles.field}
                            options={genders}
                            selectedOptionKey={profileData?.gender != null ? genders[profileData?.gender].key : null}
                            onChange={e => setProfileData({...profileData, gender: e == genders[0] ? 0 : 1})}
                            placeholder="Пол"
                            />
                        <Input
                            classNamesContainer={styles.field}
                            placeholder="Дата рождения"
                            type="date"
                            value={profileData?.birtdayDate?.split('T')[0]}
                            onChange={e => setProfileData({...profileData, birtdayDate: (new Date(e.target.value).toISOString())})}
                            />
                        <Input
                            classNamesContainer={styles.field}
                            placeholder="Номер телефона"
                            value={profileData?.telephone}
                            onChange={e => setProfileData({...profileData, telephone: e.target.value})}
                            />
                        <Input
                            classNamesContainer={styles.field}
                            placeholder="Электронная почта"
                            value={profileData?.email}
                            onChange={e => setProfileData({...profileData, email: e.target.value})}
                            />
                        <Input
                            classNamesContainer={styles.field}
                            placeholder="Пароль"
                            value={profileData?.password}
                            onChange={e => setProfileData({...profileData, password: e.target.value})}
                            />
                        <Input
                            classNamesContainer={styles.field}
                            placeholder="Тип аккаунта"
                            value={warehouseOwnerId != null ? "Владелец склада" :
                                employeeId!= null? "Сотрудник" :
                                    "Клиент"
                            }
                            onChange={e => {}}
                            />
                    </div>
                    <Button
                        isLoader={isAddFetching || isUpdateFetching || isUpdateImageFetching}
                        className={styles.button}
                        text="Сохранить"
                        onClick={updateMeHandler}
                        />
                    <Button
                        className={styles.button}
                        text="Выйти из аккаунта"
                        onClick={deleteToken}
                        />
            </Container>
        </Layout>
    )}

    return(
        <Layout>
            <ImageSelector
                inlineStyles={{width: 195, height: 150, margin: "10px auto"}}
                previewImage={previewImage} 
                setCurrentImage={setCurrentImage} 
                setPreviewImage={setPreviewImage}
                />
            <Input
                classNamesContainer={styles.field}
                placeholder="Имя"
                value={profileData?.name}
                onChange={e => setProfileData({...profileData, name: e.target.value})}
                />
            <Input
                classNamesContainer={styles.field}
                placeholder="Фамилия"
                value={profileData?.secondName}
                onChange={e => setProfileData({...profileData, secondName: e.target.value})}
                />
            <Select
                classNames={styles.field}
                options={genders}
                selectedOptionKey={profileData?.gender != null ? genders[profileData?.gender].key : null}
                onChange={e => setProfileData({...profileData, gender: e == genders[0] ? 0 : 1})}
                placeholder="Пол"
                />
            <Input
                classNamesContainer={styles.field}
                placeholder="Дата рождения"
                type="date"
                value={profileData?.birtdayDate?.split('T')[0]}
                onChange={e => setProfileData({...profileData, birtdayDate: (new Date(e.target.value).toISOString())})}
                />
            <Input
                classNamesContainer={styles.field}
                placeholder="Номер телефона"
                value={profileData?.telephone}
                onChange={e => setProfileData({...profileData, telephone: e.target.value})}
                />
            <Input
                classNamesContainer={styles.field}
                placeholder="Электронная почта"
                value={profileData?.email}
                onChange={e => setProfileData({...profileData, email: e.target.value})}
                />
            <Input
                classNamesContainer={styles.field}
                placeholder="Пароль"
                value={profileData?.password}
                onChange={e => setProfileData({...profileData, password: e.target.value})}
                />
            <Input
                classNamesContainer={styles.field}
                placeholder="Тип аккаунта"
                value={warehouseOwnerId != null ? "Владелец склада" :
                    employeeId!= null? "Сотрудник" :
                        "Клиент"
                }
                onChange={e => {}}
                />
            <Button
                isLoader={isAddFetching || isUpdateFetching || isUpdateImageFetching}
                className={styles.button}
                text="Сохранить"
                onClick={updateMeHandler}
                />
            <Button
                className={styles.button}
                text="Выйти из аккаунта"
                onClick={deleteToken}
                />
        </Layout>
    )
};

export { UserProfilePage };