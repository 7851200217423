import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import { Button } from "shared/ui/Button";
import { useState } from "react";
import { ColumnHeaderType } from "widgets/Table";

// const columns: Array<ColumnHeaderType> = [
// 	{}
// ]

const OrganizationsPage = () => {
	const [isOrganizationModalVisible, setIsOrganizationModalVisible] = useState(false);
	const [isWarehouseModalVisible, setIsWarehouseModalVisible] = useState(false);
	
	return (
		<Layout>
			1
			{/* <Container>
				<div>
					<div className={styles.header}>
						<Button
							text="Создать организацию"
							onClick={() => setIsOrganizationModalVisible(true)}
							inlineStyles={{marginRight: 10}}
							/>
						<Button
							text="Создать склад"
							onClick={() => setIsOrganizationModalVisible(true)}
							/>
					</div>
					<Table columns={tableHeadName}>
                            {sortedData?.length != 0 ?
                                sortedData?.map(el => (
                                    <TableItem hover inlineStyles={{marginBottom: 10}} onClick={() => {setSelectedProductForRedact(el); setIsRedactModalOpen(true)}}>
                                        <>
                                            <TableItemContainer width={tableHeadName[0].width}><img className={styles.image} src={el.images != null && el.images!.length != 0 ? el.images![0] : boxImg} alt={el.name!} /> </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[1].width}><div className={styles.name}> {el.barcode} </div> </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[2].width}>{el.name} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[3].width}>{el.color} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[4].width}>0 </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[5].width}>{el.serviceProductPrice ? el.serviceProductPrice : 0} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[6].width}>{el.quantity ? el.quantity : 0} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[7].width}>{el.organizationName} </TableItemContainer> 
                                        </>
                                    </TableItem>
                            ))
                            :  <></>
                        }
                        </Table>
				</div>
			</Container> */}
		</Layout>
	);
};

export {
	OrganizationsPage
}