export const IsArrayIsNotEmpty = (array: any | undefined | null, moreThen?: number) => {
	if (array == null) return false;
	
	if (Array.isArray(array)) { 
		if (array.length != 0) {
			// if (moreThen && array.length > moreThen) {
			// 	return true;
			// }
			// else if (moreThen && array.length < moreThen) {
			// 	return false;
			// }
			return true;
		}
        return false;
	}

	return false;
}