import React from "react";
import styles from "./index.module.scss";
import Layout from "widgets/Layout/Layout";
import { useNavigate } from "react-router-dom";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { useGetBillsQuery, usePostProcessesPlacementAddProcessMutation } from "api";
import { useAppSelector } from "app/store/hooks/redux";
import Title from "shared/ui/Title/Title";
import { NavigateButton } from "shared/ui/NavigateButton";
import { BillType } from "enums/billType";
import { BillStatusEnum } from "enums/billStatus";
import TableSelect from "entities/TableSelect";

const PlacementPage = () => {
    const navigate = useNavigate();

    const {warehouseId} = useAppSelector(state => state.userReducer);
    const {data: placementBills} = useGetBillsQuery({billStatus: BillStatusEnum.New, billType: BillType.Placement, warehouseId: warehouseId!}, {skip: warehouseId == null});

    const [createProcess] = usePostProcessesPlacementAddProcessMutation();
    const createProcesshandler = async (selectedBillId: number) => {
        try {
            const processId = await createProcess({
                billId: selectedBillId!,
            }).unwrap();

            navigate({pathname: LinkPagesEnum.PlacementProcess, search: "?processId=" + processId});
        }
        catch (error) {
        }
    };

    if (warehouseId == null)
    {
        return <div>
            <Title inlineStyles={{marginBottom: 10}}>Выберите складскую организацию и склад в настройках</Title>
            <NavigateButton
                to={LinkPagesEnum.Settings}
                text="Перейти в настройки"
                />
        </div>;
    }

    return (
        <Layout>
            <TableSelect 
                options={placementBills?.map(el => { return { bill: el, onClick: () => createProcesshandler(el.id!)}})}
                titles={["Выберите накладную"]}
                />
        </Layout>
    )
};

export {PlacementPage};