import React, {ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks/redux";
import { userSlice } from "../store/reducers/UserSlice";
import { useGetUsersMeQuery } from "api";
import { LinkPagesEnum } from "app/store/enums/linkPages";

type Props = {
  children: ReactNode
}

const UserProvider = ({children}: Props) =>  {
    const [loader, setLoader] = useState<boolean>(true)
    const dispatch = useAppDispatch();
    const {setMe} = userSlice.actions
    const {token} = useAppSelector(el => el.userReducer);
    const {data = null, isSuccess, isError} = useGetUsersMeQuery(undefined, {skip: token == null});

    useLayoutEffect(()=> {
      if (data != null){
        dispatch(setMe(data));
        setLoader(false);
      }
    },[data])

    useEffect(() =>{
      setLoader(!(isSuccess || isError));
      if (isError) {
        localStorage.removeItem("authToken");
        window.location.replace(LinkPagesEnum.Authorization);
      }
    },[isSuccess, isError])

  return (
    <>
    {loader && token != null ? null : children}
    </>
  )
}

export default UserProvider;