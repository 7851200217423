import Layout from "widgets/Layout/Layout"

const SupportPage = () => {
    return (
        <Layout>
            <div>
                SupportPage page content
            </div>
        </Layout>
    )
}

export { SupportPage };