import { BillType } from "api"

export const getBillType = (type?: BillType | undefined) => {
    if (type == 0) {
        return "Приходная"
    }
    else if (type == 1) {
        return "Отходная";
    }
    else if (type == 2) {
        return "Перемещение"
    }
    else if (type == 3) {
        return "Упаковка"
    }
    else {
        return "Другое"
    }
}