import { ReactNode } from "react";
import styles from "./index.module.scss";
import { BaseSharedComponentType } from "types";
import clsx from "clsx";

type Props = {
    children: ReactNode;
} & BaseSharedComponentType;

const List = ({children, inlineStyles, inlineClasses}: Props) => {
    return (
        <div className={clsx(styles.container, inlineClasses)} style={inlineStyles}>
            {children}
        </div>
    )
}

export {
    List
}