import Layout from "widgets/Layout/Layout"
import styles from "./index.module.scss";
import { getLocalDate, getMonthDays } from "functions/time";
import { ArrowIcon, TriangleIcon } from "shared/ui/icons/icons";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useGetBillsDateQuery, useGetBillsQuery } from "api";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { useNavigate } from "react-router-dom";
import { BillType } from "enums/billType";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { getBillType } from "functions/billType";
import { getBillStatus } from "functions/billStatus";
import { IS_PC } from "app/store/consts";
import { List } from "widgets/mobile/List";
import { ListItem } from "widgets/mobile/List/components/Item";
import { ListItemText } from "widgets/mobile/List/components/ListItemText";

const getColorByNumberOfBills = (numberOfBills?: number | null | undefined) => {
    if (numberOfBills == null) return '#fff';

    if (numberOfBills == 0) return '#fff';
    if (numberOfBills <= 5) return '#03A500';
    if (numberOfBills <= 10) return '#E38500';
    if (numberOfBills <= 20) return '#EE3F3F';
    return '#EE3F3F';
}

const compareDates = (date: Date, date1: Date) => {
    const year1 = date.getFullYear();
    const year2 = date1.getFullYear();

    if (year1 != year2) return false;

    const month1 = date.getMonth();
    const month2 = date1.getMonth();

    if (month1!= month2) return false;

    const day1 = date.getDate();
    const day2 = date1.getDate();

    if (day1 != day2) return false;

    return true;
}

const columns: ColumnHeaderType[] = [
    {name: "ID", width: "10%"},
    {name: "Тип", width: "10%"},
    {name: "Статус", width: "10%"},
    {name: "ЮР Лицо", width: "20%"},
    {name: "Склад", width: "20%"},
    {name: "Комментарий", width: "20%"},
    {name: "Создано", width: "10%"},
]

const getDateForRequest = (date?: Date | undefined | null) => {
    if (date == null) return undefined;

    return (new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)).toISOString();
}

const CalendarPage = () => {
    const [monthDate, setMonthDate] = useState<Date>(new Date());
    const [date, setDate] = useState<Date | null>(new Date());

    const {data: bills, isFetching: isBillsFetching} = useGetBillsQuery({date: getDateForRequest(date)}, {skip: date == null})

    const {data: billDate} = useGetBillsDateQuery();
    const navigate = useNavigate();

    const dayRefs = useRef<(HTMLDivElement | null)[]>([]);
    useEffect(() => {
        if (date) {
            const dayIndex = getMonthDays(monthDate, true).findIndex(day => day.number === date.getDate());
            if (dayIndex !== -1 && dayRefs.current[dayIndex]) {
                dayRefs.current[dayIndex]?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            }
        }
    }, [date, monthDate]);
    
    if (IS_PC){
        return (
            <Layout>
                <div>
                    <div>
                        <div className={styles.month__container}>
                            {date == null && <TriangleIcon inlineClass={styles.triangle}/>}
                            <ArrowIcon onClick={() => 
                                {
                                    setDate(null);
                                    setMonthDate(new Date(monthDate.getFullYear(), monthDate.getMonth() - 1))
                            }} fill="#000" inlineStyles={{transform: "rotate(90deg)", marginRight: "3px", width: "10px", cursor: "pointer"}}/>
                            <div onClick={() => setDate(null)} className={styles.month} style={{textTransform: "capitalize"}}>{monthDate.toLocaleString('default', { month: 'long' })}</div>
                            <ArrowIcon onClick={() => {
                                    setDate(null);
                                    setMonthDate(new Date(monthDate.getFullYear(), monthDate.getMonth() + 1))}} fill="#000" inlineStyles={{transform: "rotate(-90deg)", marginLeft: "3px", width: "10px", cursor: "pointer"}}/>
                        </div>
                        <div className={styles.days}>
                            {getMonthDays(monthDate, true).map(day => {
                                var bc = getColorByNumberOfBills(billDate?.find(y => compareDates(new Date(y.date!), day.date!))?.count!);
                                return (
                                    <div
                                        className={clsx(
                                            styles.day,
                                        )}
                                        style={{backgroundColor: bc, color: bc == "#fff" ? "#000" : "#fff"}}
                                        onClick={() => {
                                            setDate(day.date)
                                        }}
                                    >
                                        {date?.getDate() === day.number && <TriangleIcon inlineClass={styles.triangle}/>}
                                        <div style={{color: bc == "#fff" ? "#CACACA" : "#fff"}}>{day.weekDayName}</div>
                                        <div>{day.number}</div>
                                    </div>
                                )
                            }
                            )}
                        </div>
                    </div>
                    <Table columns={columns} isFetching={isBillsFetching}>
                        {date != null && bills?.map(bill => 
                            <TableItem inlineStyles={{marginBottom: 10}} hover onClick={() => {
                                if (bill.type == BillType.Acceptance)
                                {
                                    navigate(LinkPagesEnum.RedactAcceptanceBill + "?billId=" + bill.id);
                                }
                                else if (bill.type == BillType.Shipment) {
                                    navigate(LinkPagesEnum.RedactShipmentBill + "?billId=" + bill.id);
                                }
                                else if (bill.type == BillType.Placement) {
                                    navigate(LinkPagesEnum.RedactPlacementBill + "?billId=" + bill.id);
                                }
                                else if (bill.type == BillType.Package) {
                                    navigate(LinkPagesEnum.RedactPackageBill + "?billId=" + bill.id);
                                }
                            }}>
                                <TableItemContainer width={columns[0].width}>{bill.id}</TableItemContainer>
                                <TableItemContainer width={columns[1].width}>{getBillType(bill.type)}</TableItemContainer>
                                <TableItemContainer width={columns[2].width}>{getBillStatus(bill.status)}</TableItemContainer>
                                <TableItemContainer width={columns[3].width}>{bill.organizationName}</TableItemContainer>
                                <TableItemContainer width={columns[4].width}>{bill.warehouseName}</TableItemContainer>
                                <TableItemContainer width={columns[5].width}>{bill.note}</TableItemContainer>
                                <TableItemContainer width={columns[6].width}>{getLocalDate(bill.dateCreated)}</TableItemContainer>
                            </TableItem>
                        )}
                    </Table>
                </div>
            </Layout>
    )}

    return (
        <Layout>
            <div>
                <div>
                    <div className={styles.month__container}>
                        {date == null && <TriangleIcon inlineClass={styles.triangle}/>}
                        <ArrowIcon onClick={() => 
                            {
                                setDate(null);
                                setMonthDate(new Date(monthDate.getFullYear(), monthDate.getMonth() - 1))
                        }} fill="#000" inlineStyles={{transform: "rotate(90deg)", marginRight: "3px", width: "10px", cursor: "pointer"}}/>
                        <div onClick={() => setDate(null)} className={styles.month} style={{textTransform: "capitalize"}}>{monthDate.toLocaleString('default', { month: 'long' })}</div>
                        <ArrowIcon onClick={() => {
                                setDate(null);
                                setMonthDate(new Date(monthDate.getFullYear(), monthDate.getMonth() + 1))}} fill="#000" inlineStyles={{transform: "rotate(-90deg)", marginLeft: "3px", width: "10px", cursor: "pointer"}}/>
                    </div>
                    <div className={styles.days}>
                        {getMonthDays(monthDate, true).map((day, index) => {
                            var bc = getColorByNumberOfBills(billDate?.find(y => compareDates(new Date(y.date!), day.date!))?.count!);
                            return (
                                <div
                                    ref={el => dayRefs.current[index] = el}
                                    className={clsx(
                                        styles.day,
                                    )}
                                    style={{backgroundColor: bc, color: bc == "#fff" ? "#000" : "#fff"}}
                                    onClick={() => {
                                        setDate(day.date)
                                    }}
                                >
                                    {date?.getDate() === day.number && <TriangleIcon inlineClass={styles.triangle}/>}
                                    <div style={{color: bc == "#fff" ? "#CACACA" : "#fff"}}>{day.weekDayName}</div>
                                    <div>{day.number}</div>
                                </div>
                            )
                        }
                        )}
                    </div>
                </div>
                <List>
                    {date != null && bills?.map(bill => 
                        <ListItem inlineStyles={{marginBottom: 10}} onClick={() => {
                            if (bill.type == BillType.Acceptance)
                            {
                                navigate(LinkPagesEnum.RedactAcceptanceBill + "?billId=" + bill.id);
                            }
                            else if (bill.type == BillType.Shipment) {
                                navigate(LinkPagesEnum.RedactShipmentBill + "?billId=" + bill.id);
                            }
                            else if (bill.type == BillType.Placement) {
                                navigate(LinkPagesEnum.RedactPlacementBill + "?billId=" + bill.id);
                            }
                            else if (bill.type == BillType.Package) {
                                navigate(LinkPagesEnum.RedactPackageBill + "?billId=" + bill.id);
                            }
                        }}>
                            <ListItemText inlineStyles={{marginBottom: 5}} value={bill.id} name="ID: " />
                            <ListItemText inlineStyles={{marginBottom: 5}} value={getBillType(bill.type)} name="Тип: " />
                            <ListItemText inlineStyles={{marginBottom: 5}} value={getBillStatus(bill.status)} name="Статус: " />
                            <ListItemText inlineStyles={{marginBottom: 5}} value={bill.organizationName} name="ЮР Лицо: " />
                            <ListItemText inlineStyles={{marginBottom: 5}} value={bill.warehouseName} name="Склад: " />
                            <ListItemText inlineStyles={{marginBottom: 5}} value={bill.note} name="Комментарий: " />
                            <ListItemText value={getLocalDate(bill.dateCreated)} name="Создано: " />
                        </ListItem>
                    )}
                </List>
            </div>
        </Layout>
    )
}


export {
    CalendarPage
};