import { CellTypeEnum } from "api";

export const getNameByCellType = (cellType: CellTypeEnum | null | undefined) => {
    if (cellType == null)
    {
        return "Не указано";
    }
    
	if (cellType == 0) {
		return "Отгрузка";
	}
	else if (cellType == 1) {
        return "Приход";
    }
	else if (cellType == 2) {
        return "Упаковка";
    }
	else if (cellType == 3) {
        return "Хранение";
    }
	else {
        return "Другое";
    }
}