import React, { useState } from "react";
import styles from "./index.module.scss";
import Layout from "widgets/Layout/Layout";
import { useGetDocumentsQuery } from "api";
import {Container} from "shared/ui/Container";
import { OptionType, Select } from "shared/ui/Select";
import { BASE_URL_FILES, IS_PC } from "app/store/consts";
import { getDocumentTypeName } from "functions/documentType";
import { List } from "widgets/mobile/List";
import { ListItem } from "widgets/mobile/List/components/Item";

type PropsType = {

}

const options: OptionType[] = [
    {value: "Все", key: -1},
    {value: "Накладные приходные", key: 0}, 
    {value: "Накладные отходные", key: 1},
    {value: "Накладные упаковочные", key: 2},
    {value: "Накладные транзитные", key: 3},
    {value: "Товары", key: 4},
    {value: "Счета", key: 5}

];

const DocumentsPage = ({}: PropsType) => {
    const {data: documents } = useGetDocumentsQuery();

    const [selectedOptionKey, setSelectedOption] = useState(-1);
    const filteredDocuments = documents?.filter(document => selectedOptionKey != -1 ? document.documentType == selectedOptionKey : true);

    if (IS_PC) {
    return (
        <Layout>
                <>
                    <div className={styles.header}>
                        <Select
                            placeholder="Тип"
                            options={options}
                            selectedOptionKey={selectedOptionKey}
                            onChange={val => setSelectedOption(val.key)}
                            classNames={styles.select}
                            />
                    </div>
                    <div className={styles.container}>
                        {filteredDocuments?.map( item => 
                            <Container inlineClasses={styles.document}>
                                <>
                                    <div>
                                        <div className={styles.document__name}>
                                            {getDocumentTypeName(item.documentType!) + " №" + item.id}
                                        </div>
                                        <div className={styles.document__date}>
                                            {(new Date(item.objectCreateDate!)).toLocaleString()}
                                        </div>
                                    </div>
                                    <div onClick={()=> {
                                        if (item.file && item.file.fileName) {
                                            window.open(BASE_URL_FILES + item.file.fileName)!.focus();
                                        }
                                    }}>
                                        {/* <img src="assets/svg/pdf_icon.svg"/> */}
                                        {item.documentExtension == 0
                                            ? <img src="assets/svg/docx_icon.svg"/>
                                            : <img className={styles.document__file} src="assets/svg/xlsx_icon.svg"/>
                                            }
                                    </div>
                                </>
                            </Container>
                            )} 
                    </div>
                </>
        </Layout>
    )}

    return (
        <Layout>
            <List>
                {
                    filteredDocuments?.map(document =>  
                        <ListItem onClick={()=> {
                            if (document.file && document.file.fileName) {
                                window.open(BASE_URL_FILES + document.file.fileName)!.focus();
                            }
                        }}>
                            <div className={styles.document__name}>
                                {getDocumentTypeName(document.documentType!) + " №" + document.id}
                            </div>
                            <div className={styles.document__date}>
                                {(new Date(document.objectCreateDate!)).toLocaleString()}
                            </div>
                            <div>
                                {/* <img src="assets/svg/pdf_icon.svg"/> */}
                                {/* {document.documentExtension == 0
                                   ? <img src="assets/svg/docx_icon.svg"/>
                                    : <img className={styles.document__file} src="assets/svg/xlsx_icon.svg"/>
                                    } */}
                            </div>
                        </ListItem>
                    )
                }
            </List>
        </Layout>
    )
};

export { DocumentsPage };