import { CSSProperties } from "react";
import styles from "./index.module.scss";
import clsx from "clsx";
import { Loader } from "../Loader";

type Props = {
    text: string,
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean,
    className?: string,
    inlineStyles?: CSSProperties;
    isLoader?: boolean;
    loaderText?: string;
};

const Button = ({text, onClick, disabled, className, inlineStyles, isLoader, loaderText="Обновление..."}: Props) => {
    return (
        // TODO: Remove div container
        <div style={{position:"relative"}}>
            <button disabled={disabled} onClick={e => {
                if (!isLoader) {
                    e.stopPropagation();
                    onClick(e);
                }
            }} style={inlineStyles} className={clsx(styles.button, !isLoader ? styles.button_hover : styles.button_loading,  className)}>
                {isLoader && <Loader inlineContainerClasses={styles.loader} inlineStyles={{width: 20, height: 20, borderWidth: 1}} />}
                {isLoader ? loaderText : text}
            </button>
        </div>
    );
};

export {Button};