import { FC } from "react";
import styles from "./index.module.scss"
import { TerminalCounter } from "entities/TerminalCounter";

type ShipProductProps = {
  name : string,
  imgSrc: string,
  article: string,
  factoryBarcode: string,
  barcode: string,
  awaitQuantity : number,
  onIncrement : () => void,
  onDecrement : () => void,
  onAdd?: () => void,
  onSet?: () => void,
  quantity: number
}


const ShipProductItem: FC<ShipProductProps> = ({name,article,factoryBarcode, barcode, awaitQuantity, onIncrement,onDecrement, onSet, onAdd, imgSrc, quantity}) => {
  return <div className={styles.card}>
      <div className={styles.header}>
          <img src={imgSrc} className={styles.image}/>
          <div className={styles.info}>
              <div className={styles.nameAndText}>Название: <span className={styles.text}>{name}</span></div>
              <div className={styles.nameAndText}>Артикул: <span className={styles.text}>{article}</span></div>
              <div className={styles.nameAndText}>Штрихкод заводской: <span className={styles.text}>{factoryBarcode}</span></div>
              <div className={styles.nameAndText}>Штрихкод:  <span className={styles.text}>{barcode}</span></div>
          </div>
      </div>
      <TerminalCounter
            onAdd={onAdd}
            onSet={onSet}
            onDecrement={onDecrement}
            onIncrement={onIncrement}
            awaitQuantity={awaitQuantity}
            quantity={quantity}
            />
  </div>
}

export {ShipProductItem};