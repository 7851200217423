import { ArrowIcon, NotificationsIcon } from "shared/ui/icons/icons";
import styles from "./Header.module.scss";
import UserProfile from "./components/UserProfile/UserProfile";
import { useAppSelector } from "app/store/hooks/redux";
import { useGetNotificationsQuery } from "api";
import { Notifications } from "features/Notifications";
import { useState } from "react";
import { TechSupportModal } from "./components/TechSupportModal";

type Props = {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
};

export type DifNotificationType = {
    id: number;
    userid: number;
    text: string;
    isSeen: boolean;
    objectCreateDate: Date;
}

const Header = ({isOpen, setIsOpen}: Props) => {
    const pageName = useAppSelector(state => state.pageNameReducer);
    const {data: notifications, isSuccess} = useGetNotificationsQuery();
    const [isOpenNotifications, setIsOpenNotifications] = useState(false);

    const [techSupport, setTechSupport] = useState(false);

    return (
        <header className={styles.header}>
            {/* <TitleCard className={styles.titleCard} text={pageName.pageName}/>  */}
            <div className={styles.pageName}>{pageName.pageName}</div>
            <div style={{display: "flex", alignItems: 'center'}}>
                {/* <div style={{display: "flex", alignItems: 'center'}}>
                    <div className={styles.techsupport}>
                        Техподдержка
                    </div>
                    <div className={styles.stick}/>
                    <a href="tel:+79196165333" className={styles.number}>
                        8 (919) 616-53-33
                    </a>
                </div> */}
                <div 
                    style={{display: "flex", alignItems: 'center', cursor: 'pointer', position: "relative"}}
                    onClick={() => {
                        setTechSupport(prevState => !prevState)
                    }}
                    >
                    <div className={styles.techsupport}>
                        Поддержка
                    </div>
                    <ArrowIcon
                        fill="#000"
                        width="10px"
                        height="6px"
                        inlineStyles={{marginLeft: "5px", rotate: techSupport ? "180deg" : "0deg"}}
                        />
                    <TechSupportModal 
                        isOpen={techSupport} 
                        onClose={() => {setTechSupport(false)}}
                        />
                </div>
                <div className={styles.notificationsContainer} style={{margin: "0 20px"}}>
                    {notifications?.length != 0 && <div onClick={() => setIsOpenNotifications(prevState => !prevState)} className={styles.notificationCount}>{notifications?.length}</div>}
                    <NotificationsIcon inlineStyles={{cursor: "pointer"}} onClick={() => setIsOpenNotifications(prevState => !prevState)}/>
                    {isSuccess && isOpenNotifications ? <Notifications close={() => setIsOpenNotifications(prevState => !prevState)} inlineClasses={styles.notifications} notifications={notifications!}/> : null}
                </div>
                <UserProfile />
            </div>
        </header>
    )
};

export default Header;