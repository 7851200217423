import { useRef } from "react";
import styles from "./index.module.scss";
import { ImageAddIcon, ImageEditIcon, ImageRemoveIcon } from "shared/ui/icons/icons";
import { BaseSharedComponentType } from "types";
import clsx from "clsx";

type Props = {
	setCurrentImage: React.Dispatch<React.SetStateAction<File | undefined>>;
	previewImage: string;
	setPreviewImage: React.Dispatch<React.SetStateAction<string>>;
} & BaseSharedComponentType;

const ImageSelector = ({previewImage, setCurrentImage, setPreviewImage,inlineClasses, inlineStyles}: Props) => {
    const selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files as FileList;
        setCurrentImage(selectedFiles?.[0]);
        setPreviewImage(URL.createObjectURL(selectedFiles?.[0]));
    };
	
    const ref = useRef<HTMLInputElement>(null);

	if (previewImage == "") 
	{
		return (
			<div className={clsx(styles.selectImg, inlineClasses)} style={inlineStyles} onClick={e => ref.current?.click()}>
				<ImageAddIcon/>
				<input ref={ref} className={styles.fileUploadImg} type="file" accept="image/*" onChange={selectImage} />
			</div>
		)
	}

	return (
		<div className={clsx(styles.container, inlineClasses)} style={inlineStyles}>
			<img src={previewImage} alt=""  className={styles.img}/>
			<div className={styles.iconsContainer}>
				<ImageRemoveIcon onClick={async () => {
						setCurrentImage(undefined);
						setPreviewImage("");
					}}  inlineStyles={{margin: "10px 0"}}/>
				<ImageEditIcon onClick={e => ref.current?.click()}/>
			</div>
			<input ref={ref} className={styles.fileUploadImg} type="file" accept="image/*" onChange={selectImage} />
		</div>
	);
};

export {
	ImageSelector,
}