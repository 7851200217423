import { ArrowIcon } from "shared/ui/icons/icons";
import { Container } from "shared/ui/Container";
import React from "react";
import styles from "./index.module.scss";
import { NavigateButton } from "shared/ui/NavigateButton";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { Button } from "shared/ui/Button";
import { useGetEventsQuery } from "api";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { getLocalDate } from "functions/time";
import { useNavigate } from "react-router-dom";
import { IS_PC } from "app/store/consts";
import Layout from "widgets/Layout/Layout";
import { MiniTitle } from "shared/ui/MiniTitle";
import Title from "shared/ui/Title/Title";
import { List } from "widgets/mobile/List";
import { ListItem } from "widgets/mobile/List/components/Item";
import { ListItemText } from "widgets/mobile/List/components/ListItemText";

const columns: ColumnHeaderType[] = [
    {width: "15%", name: "Создано"},
    {width: "15%", name: "ID"},
    {width: "20%", name: "Название"},
    {width: "35%", name: "Описание"},
]

var date = new Date().toISOString();

const HomeClient = () => {
    const {data: events, isFetching: isEventsFetching} = useGetEventsQuery();
    const navigate = useNavigate();

    if (IS_PC) {
    return (
    <div className={styles.left}>
        <div className={styles.container}>
            <Container inlineStyles={{marginRight: 20, width: "40%"}}>
                <>
                    <MiniTitle>
                        Создавайте новые накладные
                        опережающего развития
                    </MiniTitle>
                    <Button
                        text="Создать накладную"
                        onClick={() => {}}
                        />
                </>
            </Container>
            <Container inlineStyles={{width: "60%"}}>
                <>
                    <div className={styles.title}>
                        Узнайте, как работает система
                    </div>
                    <div className={styles.description} style={{marginBottom: 50, marginTop: 3}}>
                        Это займёт всего пару минут
                    </div>
                    <div className={styles.goToInfo} onClick={() => navigate(LinkPagesEnum.InformationBase)}>
                        Подробнее 
                        <ArrowIcon  fill="#000" inlineStyles={{rotate: "-90deg", width: "9px"}}/>
                    </div>
                </>
            </Container>
        </div>
        <Container inlineClasses={styles.fastButtons}>
            <>
                <NavigateButton
                    text="Создать товар"
                    to={LinkPagesEnum.Products}
                    inlineStyles={{marginRight: 10}}
                    />
                <Button
                    text="Экспорт товаров"
                    onClick={() => {}}
                    inlineStyles={{marginRight: 10}}
                    />
            </>
        </Container>
        <div>
            <div className={styles.title} style={{marginBottom: 20}}>
                События
            </div>
            <Table columns={columns} isFetching={isEventsFetching}>
                {events?.map(b => 
                    <TableItem hover inlineStyles={{marginBottom: 10}} onClick={() => {
                    }}>
                        <TableItemContainer width={columns[0].width}>{getLocalDate(b.objectCreateDate)}</TableItemContainer>
                        <TableItemContainer width={columns[1].width}>{b.id}</TableItemContainer>
                        <TableItemContainer width={columns[2].width}>{b.title}</TableItemContainer>
                        <TableItemContainer width={columns[3].width}>{b.description}</TableItemContainer>
                    </TableItem>
                )}
            </Table>
        </div>
    </div>
    )}

    return (
        <>
        <Container inlineStyles={{marginBottom: 10}}>
            <MiniTitle inlineStyles={{marginBottom: 20}}>
                Создавайте новые накладные
                опережающего развития
            </MiniTitle>
            <Button
                text="Создать накладную"
                onClick={() => {}}
                />
        </Container>
        <Container inlineStyles={{marginBottom: 10}}>
            <MiniTitle inlineStyles={{marginBottom: 3}}>
                Узнайте, как работает система
            </MiniTitle>
            <div style={{marginBottom: 20, fontSize: 11}}>
                Это займёт всего пару минут
            </div>
            <div className={styles.goToInfo} onClick={() => navigate(LinkPagesEnum.InformationBase)}>
                Подробнее 
                <ArrowIcon  fill="#000" inlineStyles={{rotate: "-90deg", width: "9px"}}/>
            </div>
        </Container>
        <NavigateButton
            text="Создать товар"
            to={LinkPagesEnum.Products}
            inlineStyles={{marginRight: 10, marginBottom: 20}}
            />
        <Title inlineStyles={{marginBottom: 10}}>
            События
        </Title>
        <List inlineStyles={{overflowY: "scroll", maxHeight: 300, marginBottom: 30}}>
        {events?.map(b => 
            <ListItem inlineStyles={{margin: "5px 0"}} onClick={() => {
            }}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                        <div style={{fontSize: 14, marginBottom: 5}}>{getLocalDate(b.objectCreateDate)}</div>
                        <ListItemText value={b.id} name="Id: " inlineStyles={{marginBottom: 5}}/>
                    </div>
                    <div style={{fontSize: 14, color: "#000", fontWeight: 500}}>{b.title}</div>
                </div>
                <div style={{color: "#000", marginBottom: 10}}>{b.description}</div>
            </ListItem>
        )}
        </List>
        </>
    )
};

export {
    HomeClient,
}