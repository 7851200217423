import Layout from "widgets/Layout/Layout"
import styles from "./index.module.scss";

const InformationBasePage = () => {
    return (
        <Layout>
            <div>
                                
                <div className={styles.subTitle}>
                    Первый шаг
                </div>
                <div className={styles.text}>
                    <div>
                    После регистрации
                    </div>
                    1) Владельцу склада: необходимо создать складскую организацю на странице ячейки. 
                    <br/>
                    2) Далее создаём склад и ячейки.
                    <br/>
                    3) После чего мы можем начать создавать сотрудничество с клиентами или специалистами по их id.
                    <br />
                    4) После этого мы автоматически увидим, если клиенты создадут накладную для нашего склада.
                </div>
            </div>
        </Layout>
    )
}

export {
    InformationBasePage,
}