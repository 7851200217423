import styles from "./Settings.module.scss";
import Layout from "widgets/Layout/Layout";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/store/hooks/redux";
import { OptionType, Select } from "shared/ui/Select";
import { SettingsModel, useGetOrganizationsQuery, useGetUsersMeQuery, useGetUsersProfileQuery, useGetUsersSettingsQuery, useGetWarehouseOrganizationsByIdWarehousesQuery, useGetWarehouseOrganizationsQuery, usePostAuthRegistrationMutation, usePutUsersSettingsMutation } from "api";
import { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "shared/ui/Button";
import { userSlice } from "app/store/reducers/UserSlice";


const isTableStickOptions: OptionType[] = [
    {value: "True", key: 1},
    {value: "False", key: 2},
];
const SettingsPage = () => {
    const {employeeId, warehouseOwnerId, isClient, isTableStick} = useAppSelector(state => state.userReducer);
    
    const dispatch = useAppDispatch();

    const {data: organizations} = useGetOrganizationsQuery();
    const {data: warehouseOrganizations} = useGetWarehouseOrganizationsQuery();
    const {data: immutableProfileData} = useGetUsersProfileQuery();

    const [updateSettings, {isLoading: isUpdateSettingsFetching}] = usePutUsersSettingsMutation();
    const {data: immutableSettings } = useGetUsersSettingsQuery();
    const [settings, setSettings] = useState<SettingsModel>({});

    useEffect(() => {
        setSettings({
            isTableStick: immutableSettings?.isTableStick ?? false,
            organizationId: immutableSettings?.organizationId,
            warehouseId: immutableSettings?.warehouseId,
            warehouseOrganizationId: immutableSettings?.warehouse?.organizationId
        })
    }, [immutableSettings]);

    const {data: warehouses} = useGetWarehouseOrganizationsByIdWarehousesQuery({id: settings.warehouseOrganizationId!}, {skip: settings.warehouseOrganizationId == null});

    const [addUser, {isLoading: isAddFetching}] = usePostAuthRegistrationMutation();
    
    const { setMe } = userSlice.actions
    const {data: me, refetch: refetchMe} = useGetUsersMeQuery();

    useLayoutEffect(()=> {
        if (me != null)
        {
            dispatch(setMe(me));
        }
    }, [me]);

    const updateMeHandler = async () => {
        try {
            await updateSettings({
                settingsModel: settings
            })

            await addUser({authCredentials: {email: immutableProfileData!.email, password: immutableProfileData!.password}}).unwrap()
                .then(res => {
                    localStorage.setItem("authToken", res.authToken!);
                });

            refetchMe();
        }
        catch {
            
        }
    };

    return (
        <Layout>
            <div className={styles.settings}>
                <Select
                    classNames={styles.field}
                    placeholder="Складская организация"
                    options={warehouseOrganizations?.map(el => ({value: el.name!, key: el.id!}))}
                    selectedOptionKey={settings.warehouseOrganizationId}
                    onChange={e => setSettings({...settings, warehouseOrganizationId: e.key})}
                    onDelete={() => setSettings({...settings, warehouseOrganizationId: null, warehouseId: null})}
                    />
                <Select
                    classNames={styles.field}
                    placeholder="Склад"
                    options={warehouses?.map(el => ({value: el.name!, key: el.id!}))}
                    selectedOptionKey={settings?.warehouseId}
                    onChange={e => setSettings({...settings, warehouseId: e.key})}
                    onDelete={() => setSettings({...settings, warehouseId: null})}
                    />
                <Select
                    classNames={styles.field}
                    placeholder="Разделитель в таблицах"
                    options={isTableStickOptions}
                    selectedOptionKey={settings.isTableStick ? 1 : 2}
                    onChange={e => setSettings({...settings, isTableStick: e.value == isTableStickOptions[0].value})}
                    />
                { isClient && 
                <Select
                    classNames={styles.field}
                    placeholder="Организация"
                    options={organizations?.map(el => ({value: el.name!, key: el.id!}))}
                    selectedOptionKey={settings?.organizationId}
                    onChange={e => setSettings({...settings, organizationId: e.key})}
                    />}
                <Button
                    isLoader={isAddFetching || isUpdateSettingsFetching}
                    text="Сохранить"
                    onClick={updateMeHandler}
                    />
                </div>
        </Layout>
    )
};

export {SettingsPage};