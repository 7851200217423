import { ColumnHeaderType, Table } from "widgets/Table";
import styles from "./index.module.scss";
import { useGetEmployeesCooperationRequestsQuery, useGetEmployeesCooperationsQuery, usePostEmployeesCreateCooperationRequestMutation } from "api";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { OptionType, Select } from "shared/ui/Select";
import React, { useState } from "react";
import { getLocalDate } from "functions/time";
import { Button } from "shared/ui/Button";
import {Modal} from "shared/ui/Modal";
import { Input } from "shared/ui/Input";
import { useAppSelector } from "app/store/hooks/redux";

export const tableHeadName: Array<ColumnHeaderType> = [
    {name: 'Id', width: '10%'},
    {name: 'ЮР Лицо', width: '15%'},
    {name: 'Почта', width: '15%'},
    {name: 'Имя', width: '15%'},
    {name: 'Телефон', width: '15%'},
    {name: 'Начало сотрудничества', width: '15%'},
];

export const tableHeadNameRequests: Array<ColumnHeaderType> = [
    {name: 'Id', width: '10%'},
    {name: 'ЮР Лицо', width: '15%'},
    {name: 'Тип запроса', width: '15%'},
];

// const options = ["Текущие", "Запросы"]
const options: OptionType[] = [{value: "Текущие", key:1}]

const CooperationsPageEmployee = () => {
    const {employeeId} = useAppSelector(el => el.userReducer);
	const [isWarehouseModalVisible, setIsWarehouseModalVisible] = useState(false);
	const [selectedOption, setSelectedOption] = useState(1);
	const {data: cooperations, isFetching: isCooperationsFetching} = useGetEmployeesCooperationsQuery();
	const {data: cooperationRequests} = useGetEmployeesCooperationRequestsQuery();
	const [idForRequest, setIdForRequest] = useState("");
	const [sendRequest, {isLoading}] = usePostEmployeesCreateCooperationRequestMutation();

	const sendRequestHandler = async () => {
		try {
			await sendRequest({organizationId: Number(idForRequest)}).unwrap();
			setIsWarehouseModalVisible(false)
		}
		catch (error) {
        }
	}

	return (
		<>
		<div className={styles.header}>
			<div style={{display: "flex"}}>
				<Select 
					placeholder="Тип" 
					options={options} 
					inlineStyles={{width: 150, marginRight: 10}} 
					selectedOptionKey={selectedOption} onChange={val => setSelectedOption(val.key)}
					/>
				<Button
					inlineStyles={{marginRight: 10}} 
					text="Отправить запрос сотрудничества складу"
					onClick={() => setIsWarehouseModalVisible(true)}
					/>
			</div>
			<div className={styles.MyId}>
				Мой ID: {employeeId}
			</div>
		</div>
			{selectedOption == options[0].key
				&& <Table columns={tableHeadName} isFetching={isCooperationsFetching}>
					{
						cooperations?.map(el => (
							<TableItem hover inlineStyles={{marginBottom: 10}} onClick={() => {}}>
								<>
									<TableItemContainer width={tableHeadName[0].width}><div className={styles.name}> {el.organization?.id} </div> </TableItemContainer> 
									<TableItemContainer width={tableHeadName[1].width}><div className={styles.name}> {el.organization?.name} </div> </TableItemContainer> 
									<TableItemContainer width={tableHeadName[1].width}><div className={styles.name}> {el.organization?.owner?.user?.email} </div> </TableItemContainer> 
									<TableItemContainer width={tableHeadName[1].width}><div className={styles.name}> {el.organization?.owner?.user?.name} </div> </TableItemContainer> 
									<TableItemContainer width={tableHeadName[1].width}><div className={styles.name}> {el.organization?.owner?.user?.telephone} </div> </TableItemContainer> 
									<TableItemContainer width={tableHeadName[1].width}><div className={styles.name}> {getLocalDate(el.objectCreateDate!)} </div> </TableItemContainer> 
								</>
							</TableItem>
						))
					}
				</Table>}
				{/* {selectedOption == options[1].key
				&& <Table columns={tableHeadNameRequests}>
					{
						cooperationRequests?.map(el => (
							<TableItem hover inlineStyles={{marginBottom: 10}} onClick={() => {}}>
								<>
									<TableItemContainer width={tableHeadNameRequests[0].width}><div className={styles.name}> {el.organization?.id} </div> </TableItemContainer> 
									<TableItemContainer width={tableHeadNameRequests[1].width}><div className={styles.name}> {el.organization?.name} </div> </TableItemContainer> 
									<TableItemContainer width={tableHeadNameRequests[2].width}><div className={styles.name}> {el.direction == 0 ? "Исходящий" : "Приходящий"} </div> </TableItemContainer> 
								</>
							</TableItem>
						))
					}
				</Table>} */}
				<Modal 
					isLoader={isLoading}
					isCloseCloseIcon
					isOpen={isWarehouseModalVisible}
					close={() => setIsWarehouseModalVisible(false)}
				>
				<div>
					<Input
						value={idForRequest}
						type="number"
						placeholder="Id Склада"
						onChange={e => setIdForRequest(e.target.value)}
						inlineStylesContainer={{marginBottom: 20, width: 300}}
						/>
					<Button text="Отправить" onClick={sendRequestHandler} inlineStyles={{margin: "0 auto"}}/>
				</div>
			</Modal>
		</>
	);
};

export {
	CooperationsPageEmployee
}