export type SortType = {
    value1: string | undefined | null | number;
    value2: string | undefined | null | number;
    key: number;
}

export const sortDataEl = (options: SortType[], key: number, isDesc: boolean) => {
    for (let index = 0; index < options.length; index++) {
        if (options[index].key == key) {
            if (options[index].value1 != null && options[index].value2 != null) {
                let res = 0;
                if (typeof options[index].value1! == "string") {
                    res = options[index].value1!.toString().localeCompare(options[index].value2!.toString()) ? -1  : 1;
                }
                else {
                    res = options[index].value1! < options[index].value2! ? -1 : 1;
                }

                if (isDesc) 
                {
                    if (res > 0) {
                        return -1;
                    }
                    else if (res < 0) 
                    {
                        return 1;
                    }
                    return 0;
                }

                return res;
            }
        }
    }

    return 0;
}
