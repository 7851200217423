import React, { useState } from "react";
import styles from "./index.module.scss";
// import stylesm from "./index.m.module.scss";
import {Button} from "shared/ui/Button";
// import { Search } from "shared/ui/Search";
// import { BASE_URL } from "app/store/consts";
// import clsx from "clsx";
// import { ArModel, useGetProcessesQuery } from "../api/processesAPI";
import { tableHeadName } from "../consts";
import Layout from "widgets/Layout/Layout";
import { useNavigate } from "react-router-dom";
import { getPercentColor } from "functions/color";
import { useGetProcessesQuery } from "api";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { SearchInput } from "shared/ui/SearchInput";
import { MultipleSelect } from "shared/ui/MultipleSelect";
import { Sort } from "shared/ui/Sort";
import { Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import Checkbox from "shared/ui/Checkbox/Checkbox";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { OptionType } from "shared/ui/Select";
import { IS_PC } from "app/store/consts";
import { List } from "widgets/mobile/List";
import { ListItem } from "widgets/mobile/List/components/Item";
import { ListItemText } from "widgets/mobile/List/components/ListItemText";

const options: OptionType[] = [
    {value: "Наименование", key: 1},
    {value: "Сотрудники", key: 2},
    {value: "ID", key: 3},
    {value: "Процент выполнения", key: 4},
    {value: "Начало", key: 5},
    {value: "Конец", key: 6},

];

const sortOptions: OptionType[] = [
    {value: "Наименование", key: 1},
    {value: "ID", key: 2},
    {value: "Процент выполнения", key: 3},
    {value: "Начало", key: 4},
    {value: "Конец", key: 5},
];

const ProcessesPage = () => {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");

    const {data: processes, refetch, isFetching: isProcessesFetching} = useGetProcessesQuery();

    const [selectedOptions, setSelectedOptions] = useState<number[] | null>([1]);

    const filteredData = processes?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el.name, key: 1},
        {value: el.emps!.map(x => x?.name != null ? x.name : x.email!), key: 2},
        {value: el.id, key: 3},
        {value: el.percent, key: 4},
        {value: el.dateStart, key: 5},
        {value: el.dateEnd, key: 6},
    ]));
    
    const [selectedOption, setSelectedOption] = useState(2);

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2.name, value1: el1.name, key: 1},
        {value2: el2.id, value1: el1.id, key: 2},
        {value2: el2.percent, value1: el1.percent, key: 3},
        {value2: el2.dateStart, value1: el1.dateStart, key: 4},
        {value2: el2.dateEnd, value1: el1.dateEnd, key: 5},
    ], selectedOption, isDesc));

    const [checkedCurrentProcesses, setCheckedCurrentProcesses] = useState(false);

    const filteredByCheckedCurrentProcesses = sortedData?.filter(el => checkedCurrentProcesses ? el.dateEnd == null : true);

    if (IS_PC) {
    return (
        <Layout>
                <>
                    <div className={styles.header}>
                        <div style={{display: "flex"}}>
                            <Button inlineStyles={{marginRight: 10}} text="Обновить" onClick={refetch} />
                        </div>
                        <div style={{display: 'flex'}}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <Checkbox 
                                    checked={checkedCurrentProcesses}
                                    handleChange={() => setCheckedCurrentProcesses(!checkedCurrentProcesses)}
                                    />
                                <div className={styles.checkboxText}>
                                    Текущие
                                </div>
                            </div>
                            <SearchInput 
                                value={searchText} 
                                onChange={setSearchText}
                                inlineStyles={{marginRight: 10}}
                                classNames={styles.searchInput}
                                />
                            <MultipleSelect 
                                text="Параметры"
                                options={options} 
                                selectedOptionKeys={selectedOptions} 
                                setSelectedOptions={setSelectedOptions}
                                inlineStyles={{marginRight: 10}}
                                inlineClasses={styles.select}
                                />
                            <Sort 
                                options={sortOptions}
                                selectedOptionKey={selectedOption}
                                setSelectedOption={setSelectedOption}
                                setIsDesc={setIsDesc}
                                isDesc={isDesc}
                                selectClassNames={styles.select}
                                />
                        </div>
                    </div>
                    <Table columns={tableHeadName} isFetching={isProcessesFetching}>
                        {
                            filteredByCheckedCurrentProcesses?.map(el => (
                                <TableItem hover inlineStyles={{marginBottom: 10}} onClick={() => 
                                            {
                                                if (el.dateEnd == null) {
                                                    if ("Приёмка" == el.name) {
                                                        navigate({pathname: LinkPagesEnum.InspectionProcess, search: "processId=" + el.id});
                                                    }
                                                    else if ("Отгрузка" == el.name) {
                                                        navigate({pathname: LinkPagesEnum.ShipProcess, search: "processId=" + el.id});
                                                    }
                                                    else if ("Упаковка" == el.name) {
                                                        navigate({pathname: LinkPagesEnum.PackageProcess, search: "processId=" + el.id});
                                                    }
                                                    else if ("Перемещение" == el.name) {
                                                        navigate({pathname: LinkPagesEnum.PlacementProcess, search: "processId=" + el.id});
                                                    }
                                                }
                                            }
                                        }
                                    >
                                        <TableItemContainer width={tableHeadName[0].width}>{el.id}</TableItemContainer>
                                        <TableItemContainer width={tableHeadName[1].width}>{el.name}</TableItemContainer>
                                        <TableItemContainer width={tableHeadName[2].width}>
                                            <div>
                                                {el.emps?.map(x => <div className={styles.emp}>{x.email}</div>)}
                                            </div>
                                        </TableItemContainer>
                                        <TableItemContainer width={tableHeadName[3].width}><span className={styles.percentContainer} style={{backgroundColor: getPercentColor(el.percent!) }}>{el.percent}%</span></TableItemContainer>
                                        <TableItemContainer width={tableHeadName[4].width}>{new Date(el.dateStart!).toISOString().split('T')[0]}</TableItemContainer>
                                        <TableItemContainer width={tableHeadName[5].width}>{el.dateEnd && new Date(el.dateEnd).toISOString().split('T')[0]}</TableItemContainer>
                                </TableItem>
                            ))
                        }
                    </Table>
                </>
        </Layout>
    )
    }

    return (
        <Layout>
            <List>
                {filteredByCheckedCurrentProcesses?.map((el) => (
                    <ListItem  onClick={() => 
                        {
                            if (el.dateEnd == null) {
                                if ("Приёмка" == el.name) {
                                    navigate({pathname: LinkPagesEnum.InspectionProcess, search: "processId=" + el.id});
                                }
                                else if ("Отгрузка" == el.name) {
                                    navigate({pathname: LinkPagesEnum.ShipProcess, search: "processId=" + el.id});
                                }
                                else if ("Упаковка" == el.name) {
                                    navigate({pathname: LinkPagesEnum.PackageProcess, search: "processId=" + el.id});
                                }
                                else if ("Перемещение" == el.name) {
                                    navigate({pathname: LinkPagesEnum.PlacementProcess, search: "processId=" + el.id});
                                }
                            }
                        }
                        }>
                        <ListItemText inlineStyles={{marginBottom: 5}} name="Имя: " value={el.name} />
                        <ListItemText inlineStyles={{marginBottom: 5}} name="Id: " value={el.id} />
                        <ListItemText inlineStyles={{marginBottom: 5}} name="Специалисты: " value={el.emps?.map(x => x.email)?.join(', ')} />
                        <ListItemText inlineStyles={{marginBottom: 5}} name="Выполнено на: " valueInlineStyles={{color: getPercentColor(el.percent!)}} value={el.percent + "%"}/>
                        <ListItemText inlineStyles={{marginBottom: 5}} name="Дата старта: " value={new Date(el.dateStart!).toISOString().split('T')[0]} />
                        <ListItemText name="Дата окончания: " value={el.dateEnd && new Date(el.dateEnd).toISOString().split('T')[0]} />
                    </ListItem>
                ))}
            </List>
        </Layout>
    )
};

export {
    ProcessesPage
};