import { Container } from "shared/ui/Container";
import styles from "./index.module.scss";
import React from "react";
import { useGetNewsQuery } from "api";
import { IS_PC } from "app/store/consts";
import Title from "shared/ui/Title/Title";

const News = () => {
    const {data: news} = useGetNewsQuery();

    if (IS_PC) {
    return (
        <Container 
            inlineClasses={styles.container}
            >
                <div>
                    <div className={styles.newsName}>
                        Новости
                    </div>
                    <div className={styles.stick} />
                    <div>
                        {news?.map(x => <>
                            <div className={styles.date}>{(new Date(x.objectCreateDate!)).toLocaleDateString()}</div>
                            <div className={styles.title}>{x.title}</div>
                            <div className={styles.description}>{x.description}</div>
                        </>)}
                    </div>
                </div>
        </Container>
    );
    }
    
    return (
        <>
        <Title>
            Новости
        </Title>
        <div className={styles.stick} />
        <div>
            {news?.map(x => <>
                <div className={styles.date}>{(new Date(x.objectCreateDate!)).toLocaleDateString()}</div>
                <div className={styles.title}>{x.title}</div>
                <div className={styles.description}>{x.description}</div>
            </>)}
        </div>
        </>
    )
};

export {
    News
}