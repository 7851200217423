import Layout from "widgets/Layout/Layout";
import React, { useState } from "react";
import styles from "./index.module.scss";
import { Button } from "shared/ui/Button";
import { SearchInput } from "shared/ui/SearchInput";
import { MultipleSelect } from "shared/ui/MultipleSelect";
import { Sort } from "shared/ui/Sort";
import { CreateConsumablesModel, useGetConsumablesQuery, useGetWarehouseOrganizationsByIdWarehousesQuery, useGetWarehouseOrganizationsQuery, usePostConsumablesMutation, usePutConsumablesByIdQuantityMutation } from "api";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { OptionType, Select } from "shared/ui/Select";
import {Modal} from "shared/ui/Modal";
import { Input } from "shared/ui/Input";
import { IS_PC } from "app/store/consts";
import { List } from "widgets/mobile/List";
import { ListItem } from "widgets/mobile/List/components/Item";
import { ListItemText } from "widgets/mobile/List/components/ListItemText";
import { ButtonsContainer } from "shared/ui-mobile/ButtonsContainer";

// const tableTypes: OptionType[] = [
//     {value: "Расходники", key: 1},
//     {value: "История", key: 2},
// ];

const consumableOptions: OptionType[] = [
    {value: "Организация", key: 1},
    {value: "Склад", key: 2},
    {value: "Наименование", key: 3},
    {value: "Кол-во", key: 4},
    {value: "Потраченное кол-во", key: 5},
]

const tableHeadNameConsumables: ColumnHeaderType[] = [
    {name: "Организация", width: "15%"},
    {name: "Склад", width: "15%"},
    {name: "Наименование", width: "15%"},
    {name: "В наличии", width: "15%"},
    {name: "Забронированно", width: "15%"},
    {name: "Потраченно", width: "15%"},
];

// const consumableHistoryOptions: OptionType[] = [
//     {value: "Организация", key: 1},
//     {value: "Склад", key: 2},
//     {value: "Наименование", key: 3},
//     {value: "Кол-во", key: 4},
//     {value: "Потраченное кол-во", key: 5},
//     {value: "ID/Сотрудник", key: 6},
//     {value: "Время траты", key: 7},
//     {value: "ID Товара / Наименование товара", key: 8},
//     {value: "Кол-во товара", key: 9},
// ]

// const tableHeadNameconsumablesHistory: ColumnHeaderType[] = [
//     {name: "Организация", width: "150px"},
//     {name: "Склад", width: "150px"},
//     {name: "Наименование", width: "150px"},
//     {name: "Кол-во", width: "100px"},
//     {name: "Потраченное кол-во", width: "100px"},
//     {name: "ID/Сотрудник", width: "150px"},
//     {name: "Время траты", width: "150px"},
//     {name: "ID Товара / Наименование товара", width: "150px"},
//     {name: "Кол-во товара", width: "150px"},
// ];

const ConsumablesPage = () => {
	const {data: warehouseOrganizations, isFetching: isWarehouseOrganizationsFetching} = useGetWarehouseOrganizationsQuery();
	const [warehouseOrganizationId, setWarehouseOrganizationId] = useState<number | null>(null);
	const [warehouseIdForAddConsumableModal, setWarehouseIdForAddConsumableModal] = useState<number | null>(null);

	const {data: warehouses, isFetching: isWarehousesFatching} = useGetWarehouseOrganizationsByIdWarehousesQuery({id: warehouseOrganizationId!}, {skip: warehouseOrganizationId == null});

	const {data: consumables, refetch, isFetching: isConsumablesFetching } = useGetConsumablesQuery();
	
	const [createConsumable] = usePostConsumablesMutation();
	const [createdConsumable, setCreatedConsumable] = useState<CreateConsumablesModel>({});
	
	const createConsumableHandler = async () => {
		try {
			await createConsumable({createConsumablesModel: createdConsumable});
			
			setIsConsumablesCreate(false);
			setCreatedConsumable({});
		}
		catch (error) {
        }
	}
	
	const [addConsumable] = usePutConsumablesByIdQuantityMutation();
	const [quantity, setQuantity] = useState(0);
	const [consumableId, setConsumableId] = useState<number | null>(null);
	
	const addConsumableHandler = async () => {
		if (consumableId == null) {
			alert("Не выбран расходник");
            return;
		}
		try {
			await addConsumable({quantity: quantity, id: consumableId});
			
			setIsConsumablesAdd(false);
		}
		catch (error) {
        }
	}

	const [isConsumablesCreate, setIsConsumablesCreate] = useState(false);
	const [isConsumablesAdd, setIsConsumablesAdd] = useState(false);

    const [searchText, setSearchText] = useState("");
    const [selectedOptions, setSelectedOptions] = useState<number[] | null>([1]);

    const filteredData = consumables?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el.warehouse?.organization?.name, key: 1},
        {value: el.warehouse?.name, key: 2},
        {value: el?.name, key: 3},
        {value: el.quantity, key: 4},
        {value: el.spentQuantity, key: 5},
    ]));
    
    const [selectedOption, setSelectedOption] = useState(1);

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2.warehouse?.organization?.name, value1: el1.warehouse?.organization?.name, key: 1},
        {value2: el2.warehouse?.name,  value1: el1.warehouse?.name, key: 2},
        {value2: el2?.name, value1: el1?.name, key: 3},
        {value2: el2.quantity, value1: el1.quantity, key: 4},
        {value2: el2.spentQuantity, value1: el1.spentQuantity, key: 5},
    ], selectedOption, isDesc));

	if (IS_PC) {
	return (
		<Layout>
			<div className={styles.header}>
                    <div style={{display: "flex"}}>
                        <Button inlineStyles={{marginRight: 10}} text="Обновить" onClick={refetch} />
                        <Button inlineStyles={{marginRight: 10}} text="Создать" onClick={() => setIsConsumablesCreate(true)}/>
                        <Button text="Добавить" onClick={() => setIsConsumablesAdd(true)}/>
                    </div>
                    <div style={{display: 'flex'}}>
                        <SearchInput 
                            value={searchText} 
                            onChange={setSearchText}
                            inlineStyles={{marginRight: 10}}
                            classNames={styles.searchInput}
                            />
                        <MultipleSelect 
                            text="Параметры"
                            options={consumableOptions} 
                            selectedOptionKeys={selectedOptions} 
                            setSelectedOptions={setSelectedOptions}
                            inlineStyles={{marginRight: 10}}
                            inlineClasses={styles.select}
                            />
                        <Sort 
                            options={consumableOptions}
                            selectedOptionKey={selectedOption}
                            setSelectedOption={setSelectedOption}
                            setIsDesc={setIsDesc}
                            isDesc={isDesc}
                            selectClassNames={styles.select}
                            />
                    </div>
                </div>
				<Table columns={tableHeadNameConsumables} isFetching={isConsumablesFetching}>
					{
						sortedData?.map(el => (
							<TableItem hover inlineStyles={{marginBottom: 10}} onClick={() => {}}>
								<>
									<TableItemContainer width={tableHeadNameConsumables[0].width}><div className={styles.name}> {el.warehouse?.organization?.name} </div> </TableItemContainer> 
									<TableItemContainer width={tableHeadNameConsumables[1].width}><div className={styles.name}> {el.warehouse?.name} </div> </TableItemContainer> 
									<TableItemContainer width={tableHeadNameConsumables[2].width}><div className={styles.name}> {el.name} </div> </TableItemContainer> 
									<TableItemContainer width={tableHeadNameConsumables[3].width}><div className={styles.name}> {el.quantity} </div> </TableItemContainer> 
									<TableItemContainer width={tableHeadNameConsumables[4].width}><div className={styles.name}> {el.plannedQuantity} </div> </TableItemContainer> 
									<TableItemContainer width={tableHeadNameConsumables[5].width}><div className={styles.name}> {el.spentQuantity} </div> </TableItemContainer> 
								</>
							</TableItem>
						))
					}
				</Table>
			<Modal 
				isCloseCloseIcon
				isOpen={isConsumablesCreate}
				close={() => setIsConsumablesCreate(false)}
				>
				<>
					<Select 
						isFetching={isWarehouseOrganizationsFetching}
						classNames={styles.field}
                        placeholder="Складская организация"
                        options={warehouseOrganizations?.map(el =>({value: el.name!, key: el.id!}))}
                        onChange={val => setWarehouseOrganizationId(val.key)}
                        selectedOptionKey={warehouseOrganizationId}
                        />
                    <Select 
						classNames={styles.field}
                        nullOptionsText={warehouseOrganizationId ? "Нет записей" : "Выберите складскую организацию"}
                        isFetching={isWarehousesFatching}
                        placeholder="Склад"
                        options={warehouses?.map(el => ({value: el.name!, key: el.id!}))}
                        onChange={val => setCreatedConsumable({...createdConsumable, warehouseId: val.key})}
                        selectedOptionKey={createdConsumable.warehouseId}
                        />
					<Input
						classNamesContainer={styles.field}
					    placeholder="Наименование"
                        value={createdConsumable.name}
						onChange={e => setCreatedConsumable({...createdConsumable, name: e.target.value})}
						/>
					<Input
						classNamesContainer={styles.field}
					    placeholder="Единица измерения"
                        value={createdConsumable.measurementUnit}
						onChange={e => setCreatedConsumable({...createdConsumable, measurementUnit: e.target.value})}
						/>
					<Input
						classNamesContainer={styles.field}
					    placeholder="Количество"
                        value={createdConsumable.quantity?.toString()}
						onChange={e => setCreatedConsumable({...createdConsumable, quantity: Number(e.target.value)})}
						/>
					<Input
						classNamesContainer={styles.field}
					    placeholder="Цена"
                        value={createdConsumable.price?.toString()}
						onChange={e => setCreatedConsumable({...createdConsumable, price: Number(e.target.value)})}
						/>
					<Button text="Создать" onClick={createConsumableHandler} inlineStyles={{margin: "0 auto"}}/>
				</>
			</Modal>
			<Modal 
				isCloseCloseIcon
				isOpen={isConsumablesAdd}
				close={() => setIsConsumablesAdd(false)}
				>
				<>
					<Select
						isFetching={isWarehouseOrganizationsFetching}
						classNames={styles.field}
                        placeholder="Складская организация"
                        options={warehouseOrganizations?.map(el =>({value: el.name!, key: el.id!}))}
                        onChange={val => setWarehouseOrganizationId(val.key)}
                        selectedOptionKey={warehouseOrganizationId}
                        />
                    <Select 
						classNames={styles.field}
                        nullOptionsText={warehouseOrganizationId ? "Нет записей" : "Выберите складскую организацию"}
                        isFetching={isWarehousesFatching}
                        placeholder="Склад"
                        options={warehouses?.map(el => ({value: el.name!, key: el.id!}))}
                        onChange={val => setWarehouseIdForAddConsumableModal(val.key)}
                        selectedOptionKey={warehouseIdForAddConsumableModal}
                        />
					<Select
						classNames={styles.field}
                        nullOptionsText={warehouseIdForAddConsumableModal ? "Нет записей" : "Выберите склад"}
						placeholder="Расходник"
						options={consumables?.filter(x => x.warehouseId == warehouseIdForAddConsumableModal).map(el => ({value: el.name!, key: el.id!}))}
                        onChange={val => setConsumableId(val.key)}
                        selectedOptionKey={consumableId}
						/>
					<Input
						classNamesContainer={styles.field}
					    placeholder="Количество"
                        value={quantity.toString()}
						onChange={e => setQuantity(Number(e.target.value))}
						/>
					<Button text="Добавить" onClick={addConsumableHandler} inlineStyles={{margin: "0 auto"}}/>
				</>
			</Modal>
		</Layout>
	)}

	return (
		<Layout>
			<ButtonsContainer>
				<Button inlineStyles={{marginRight: 10}} text="Обновить" onClick={refetch} />
                <Button inlineStyles={{marginRight: 10}} text="Создать" onClick={() => setIsConsumablesCreate(true)}/>
                <Button text="Добавить" onClick={() => setIsConsumablesAdd(true)}/>
			</ButtonsContainer>
			<List>
				{sortedData?.map(el => 
					<ListItem key={el.id}>
						<ListItemText inlineStyles={{marginBottom: 5}} value={el.warehouse?.organization?.name} name="Складская организация" />
						<ListItemText inlineStyles={{marginBottom: 5}} value={el.warehouse?.name} name="Склад" />
						<ListItemText inlineStyles={{marginBottom: 5}} value={el.name} name="Наименование" />
						<ListItemText inlineStyles={{marginBottom: 5}} value={el.quantity} name="Количество" />
						<ListItemText inlineStyles={{marginBottom: 5}} value={el.plannedQuantity} name="Планируемое количество" />
						<ListItemText value={el.spentQuantity} name="Использованное количество" />
					</ListItem>
					)
				}
			</List>
			<Modal 
				isCloseCloseIcon
				isOpen={isConsumablesCreate}
				close={() => setIsConsumablesCreate(false)}
				inlineStyles={{maxWidth: "90vw"}}
				>
				<>
					<Select 
						inlineStyles={{marginBottom: 10}}
						isFetching={isWarehouseOrganizationsFetching}
                        placeholder="Складская организация"
                        options={warehouseOrganizations?.map(el =>({value: el.name!, key: el.id!}))}
                        onChange={val => setWarehouseOrganizationId(val.key)}
                        selectedOptionKey={warehouseOrganizationId}
                        />
                    <Select 
						inlineStyles={{marginBottom: 10}}
                        nullOptionsText={warehouseOrganizationId ? "Нет записей" : "Выберите складскую организацию"}
                        isFetching={isWarehousesFatching}
                        placeholder="Склад"
                        options={warehouses?.map(el => ({value: el.name!, key: el.id!}))}
                        onChange={val => setCreatedConsumable({...createdConsumable, warehouseId: val.key})}
                        selectedOptionKey={createdConsumable.warehouseId}
                        />
					<Input
						inlineStyles={{marginBottom: 10}}
					    placeholder="Наименование"
                        value={createdConsumable.name}
						onChange={e => setCreatedConsumable({...createdConsumable, name: e.target.value})}
						/>
					<Input
						inlineStyles={{marginBottom: 10}}
					    placeholder="Единица измерения"
                        value={createdConsumable.measurementUnit}
						onChange={e => setCreatedConsumable({...createdConsumable, measurementUnit: e.target.value})}
						/>
					<Input
						inlineStyles={{marginBottom: 10}}
					    placeholder="Количество"
                        value={createdConsumable.quantity?.toString()}
						onChange={e => setCreatedConsumable({...createdConsumable, quantity: Number(e.target.value)})}
						/>
					<Input
						inlineStyles={{marginBottom: 10}}
					    placeholder="Цена"
                        value={createdConsumable.price?.toString()}
						onChange={e => setCreatedConsumable({...createdConsumable, price: Number(e.target.value)})}
						/>
					<Button text="Создать" onClick={createConsumableHandler} inlineStyles={{margin: "0 auto"}}/>
				</>
			</Modal>
			<Modal 
				isCloseCloseIcon
				isOpen={isConsumablesAdd}
				close={() => setIsConsumablesAdd(false)}
				inlineStyles={{maxWidth: "90vw"}}
				>
				<>
					<Select
						isFetching={isWarehouseOrganizationsFetching}
						inlineStyles={{marginBottom: 10}}
                        placeholder="Складская организация"
                        options={warehouseOrganizations?.map(el =>({value: el.name!, key: el.id!}))}
                        onChange={val => setWarehouseOrganizationId(val.key)}
                        selectedOptionKey={warehouseOrganizationId}
                        />
                    <Select 
						inlineStyles={{marginBottom: 10}}
                        nullOptionsText={warehouseOrganizationId ? "Нет записей" : "Выберите складскую организацию"}
                        isFetching={isWarehousesFatching}
                        placeholder="Склад"
                        options={warehouses?.map(el => ({value: el.name!, key: el.id!}))}
                        onChange={val => setWarehouseIdForAddConsumableModal(val.key)}
                        selectedOptionKey={warehouseIdForAddConsumableModal}
                        />
					<Select
						inlineStyles={{marginBottom: 10}}
                        nullOptionsText={warehouseIdForAddConsumableModal ? "Нет записей" : "Выберите склад"}
						placeholder="Расходник"
						options={consumables?.filter(x => x.warehouseId == warehouseIdForAddConsumableModal).map(el => ({value: el.name!, key: el.id!}))}
                        onChange={val => setConsumableId(val.key)}
                        selectedOptionKey={consumableId}
						/>
					<Input
						inlineStyles={{marginBottom: 10}}
					    placeholder="Количество"
                        value={quantity.toString()}
						onChange={e => setQuantity(Number(e.target.value))}
						/>
					<Button text="Добавить" onClick={addConsumableHandler} inlineStyles={{margin: "0 auto"}}/>
				</>
			</Modal>
		</Layout>
    );
}

export {
	ConsumablesPage
}