import React, { useState } from "react";
import styles from "./Menu.module.scss";
import stylesm from "./index.m.module.scss";
import MenuItem from "./components/MenuItem/MenuItem";
import { menuItems } from "./consts";
import { useAppDispatch, useAppSelector } from "app/store/hooks/redux";
import UserProfile from "../Header/components/UserProfile/UserProfile";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { UserState } from "app/store/reducers/UserSlice";
import { BurgerIcon, Close2Icon, Logo, Search2Icon } from "shared/ui/icons/icons";
import { headerSlice } from "processes/header";
import { LinkPagesEnum } from "app/store/enums/linkPages";

const Menu = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const {setIsHeaderSearch} = headerSlice.actions;
    const dispatch = useAppDispatch();
    const {isHeaderSearh} = useAppSelector(state => state.headerReducer);
    const user: UserState = useAppSelector(state => state.userReducer);
    const pageName = useAppSelector(state => state.pageNameReducer);
    const location = useLocation();
    const navigate = useNavigate();

    if (window.outerWidth > 750) {
        return (
            <div className={styles.menuActive} style={ isMobileMenuOpen ? {marginLeft: "auto", width: "calc(100% - 370px)"} : {}}>
                <div className={styles.logo}>
                    <Logo/>
                </div>
                <div className={clsx(styles.menu__wrapper, isMobileMenuOpen ? styles.open : styles.close)}>
                    {/* <UserProfile className={styles.userProfile}/> */}
                    <div className={styles.menuActive__wrapper} onClick={e => e.stopPropagation()}>
                        {menuItems.map(item => 
                            (item.allowedFor?.some(r => user.roles?.includes(r)) && item.notAllowedFor?.some(r => !user.roles?.includes(r))) ?? true ?
                                <MenuItem menuItem={item} defaultOpen={item.path == location.pathname && location.pathname != "/"} key={item.name} {...item}/>
                                : null
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={clsx(stylesm.menu__wrapper, isMobileMenuOpen ? stylesm.open : stylesm.close)}>
                <img onClick={() => setIsMobileMenuOpen(false)} src="assets/svg/delete-white.svg" className={stylesm.deleteIcon}/>
                <img onClick={() => navigate(LinkPagesEnum.UserProfile)} className={stylesm.profile} src="assets/images/default-profile.png" />
                <div className={stylesm.menuActive__wrapper} onClick={e => e.stopPropagation()}>
                    {menuItems.map(item => 
                    (item.allowedFor?.some(r => user.roles?.includes(r)) && item.notAllowedFor?.some(r => !user.roles?.includes(r))) ?? true ?
                            <MenuItem menuItem={item} defaultOpen={item.path == location.pathname && location.pathname != "/"} key={item.name} {...item}/>
                            : null
                    )}
                </div>
            </div>
            <div className={stylesm.menuActive} style={ isMobileMenuOpen ? {marginLeft: "auto", width: "calc(100% - 370px)"} : {}}>
                {!isMobileMenuOpen && <BurgerIcon onClick={() => setIsMobileMenuOpen(true)} inlineClass={stylesm.burger}/> }
                <div className={stylesm.pageName}>
                    {/* TODO: сделать адаптивный обрезатель */}
                    {pageName.pageName.substring(0, 18) + (pageName.pageName.length > 18 ? "..." : "")}
                </div>
                {isHeaderSearh 
                ?
                <Close2Icon
                    onClick={() => dispatch(setIsHeaderSearch(false))}
                    width="20"
                    height="20"
                    />
                :
                <Search2Icon
                    onClick={() => dispatch(setIsHeaderSearch(true))}
                    width="20"
                    height="20"
                    />}
            </div>
        </>
    )

};

export default Menu;