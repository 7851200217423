import { ColumnHeaderType } from "widgets/Table";

export const tableHeadName: Array<ColumnHeaderType> = [
    {name: 'Изображение', width: '15%'},
    {name: 'Наименование', width: '15%'},
    {name: 'Штрихкод', width: '15%'},
    {name: 'Юр лицо', width: '10%'},
    {name: 'Количество', width: '10%'},
    {name: "Цвет", width: "10%" },
    {name: "Объём", width: "5%" },
    {name: "Цена услуги", width: "15%" },
];