import { ProductDetail, ProductDetailCell, ProductOutputModel } from "api";
import styles from "./index.module.scss";
import clsx from "clsx";
import {Modal} from "shared/ui/Modal";
import React from "react";
import { Container } from "shared/ui/Container";
import { getImageUrlByStrings } from "functions/image";
import { CheckMarkIcon } from "shared/ui/icons/icons";
import { ListItem } from "widgets/mobile/List/components/Item";
import { ListItemText } from "widgets/mobile/List/components/ListItemText";

type Props = {
    isLoader?: boolean;
    isOpen: boolean;
    close: () => void;
    detailsBack?: () => void;
    cellsBack?: () => void;
    products?: ProductOutputModel[];
    selectedProducts?: ProductOutputModel[];
    onProductClick?: (el: ProductOutputModel) => void;
    details?: ProductDetail[] | null;
    selectedDetails?: ProductDetail[];
    onDetailClick?: (el: ProductDetail) => void;
    cells?: ProductDetailCell[] | null;
    selectedCells?: ProductDetailCell[];
    onCellClick?: (el: ProductDetailCell) => void;
}

const ProductModal = ({isLoader, isOpen, close, detailsBack, cellsBack, products, selectedProducts, onProductClick = () => {}, details, selectedDetails, onDetailClick = () => {}, cells, selectedCells, onCellClick = () => {}}: Props) => {

    return (
        <Modal 
            isLoader={isLoader} 
            isOpen={isOpen} 
            close={close}
            back={
                (cells == null && details != null)
                    ? detailsBack != null 
                        ? detailsBack
                        : undefined
                    : cells != null ?
                        cellsBack != null
                            ? cellsBack
                            : undefined
                        : undefined
            }
            >
            {(cells == null && details == null) && 
                products?.map(product => 
                <ListItem 
                    onClick={() => {
                        onProductClick(product);
                    }}   
                    inlineClasses={clsx(styles.item, selectedProducts?.map(x => x.id)?.includes(product.id) ? styles.itemSelected : "")} 
                    inlineStyles={{marginBottom: 10, position: "relative"}}
                    >
                    {selectedProducts?.map(x => x.id)?.includes(product.id) 
                        && <CheckMarkIcon
                            inlineClass={styles.checkMark}
                            />}
                    <img src={getImageUrlByStrings(product.images)} className={styles.modalImg}/>
                    <ListItemText inlineStyles={{marginBottom: 5}} value={product.name} name="Название: "/>
                    <ListItemText inlineStyles={{marginBottom: 5}} value={product.article} name="Артикул: "/>
                    <ListItemText inlineStyles={{marginBottom: 5}} value={product.barcode} name="Штрих-код: "/>
                    <ListItemText inlineStyles={{marginBottom: 5}} value={product.factoryBarcode} name="Внешний штрих-код: "/>
                    <ListItemText value={product.quantity} name="Остаток на складе: "/>
                </ListItem>)
            }
            {(cells == null && details != null) && 
                <>
                <div>
                    {details?.map(x => 
                        <Container
                            onClick={() => onDetailClick(x)} 
                            hover
                            inlineStyles={{marginTop: 10, position: "relative"}}
                            inlineClasses={clsx(styles.item, selectedDetails?.map(x => x.key).includes(x.key) ? styles.itemSelected : "")}
                            >
                            <div>
                                {selectedDetails?.map(x => x.key)?.includes(x.key) 
                                    && <CheckMarkIcon inlineClass={styles.checkMark}/>
                                }
                                <div className={styles.textContainer}>
                                    <div className={styles.name}>Организация:</div> {x.organizationName}
                                </div>
                                <div className={styles.textContainer}>
                                    <div className={styles.name}>Склад: </div> {x.warehouseName}
                                </div>
                                {x.consumables != null &&
                                    <>
                                        <div className={styles.textContainer} style={{marginBottom: 3}}>
                                            <div className={styles.name}>Расходники:</div>
                                        </div>
                                        {x.consumables?.map(c =>
                                            <div>{c.name}/{c.quantity} ед.</div>
                                        )}
                                    </>
                                }
                                <div className={styles.textContainer} style={{marginTop: 5, marginBottom: 0}}>
                                    <div className={styles.name}>Количество:</div> {x.quantity}
                                </div>
                            </div>
                        </Container>
                    )}
                </div>
                </>
            }
            {cells != null && 
            <>
                {cells?.map(cell => 
                <Container 
                    inlineClasses={clsx(styles.item, selectedCells?.map(x => x.productCellId).includes(cell!.productCellId) ? styles.itemSelected : "")} 
                    inlineStyles={{marginTop: 10, position: "relative"}} 
                    hover 
                    onClick={() => onCellClick(cell)}
                    >
                    <div>
                        {selectedCells?.map(x => x.productCellId)?.includes(cell.productCellId) && <CheckMarkIcon
                            inlineClass={styles.checkMark}
                            />}
                        <div className={styles.textContainer}>
                            <div className={styles.name}>Ячейка:</div> {cell.cellPlace}
                        </div>
                        <div className={styles.textContainer} style={{marginBottom: 0}}>
                            <div className={styles.name}>Количество:</div> {cell.quantity}
                        </div>
                    </div>
                </Container>
                )}
            </>
            }
        </Modal>
    )
}

export {
    ProductModal
}