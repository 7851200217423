import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./index.module.scss";

type ChildAction = {
    func: () => void; 
    name: string;
}

export type Action = {
    func: () => void; 
    name: string;
    rightName?: string; 
    childs?: ChildAction[];
    isLine?: boolean;
};

type Props = {
    actions: Action[];
    isOpen?: boolean;
    close: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | null;
}

const getTop = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, ref: HTMLDivElement): number => {
    if ((window.innerHeight - e.clientY) > ref.clientHeight) {
        return e.clientY;
    }
    
    return e.clientY - ref.clientHeight;
}

const getLeft = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, ref: HTMLDivElement): number => {
    if ((window.innerWidth - e.clientX) > (ref.clientWidth + 10)) {
        return e.clientX;
    }

    return e.clientX - ref.clientWidth;
}

const ActionsSelector = ({actions, close, event, isOpen=event != null}: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const [pos, setPos] = useState<{top: number, left: number} | null>(null);

    useLayoutEffect(() => {
        if (event != null && ref.current != null) {
            setPos({top: getTop(event, ref.current), left: getLeft(event, ref.current)});
        }
    }, [ref, event])

    return (
        isOpen ?
        <div className={styles.container}>
            <div className={styles.back} onClick={(e) => {e.stopPropagation(); close(e); }}/>
            <div 
                ref={ref} 
                className={styles.front} 
                style={{top: pos?.top, left: pos?.left}}
                >
                {actions.map((action, index) => (
                    <div className={styles.item} key={index} onClick={(e) => {action.func(); close(e)}}>
                        <div>
                            {action.name}
                        </div>
                        <div className={styles.right}>
                            {action.rightName}
                        </div>
                    </div>
                ))}
            </div>
        </div>
        : null
    )
}

export {
    ActionsSelector
};