import React from 'react';
import { TeminalCounterButton } from "entities/TerminalCounterButton";
import styles from "./index.module.scss";

type Props = {
    awaitQuantity?: number | null,
    quantity?: number | undefined | null,
    onIncrement : () => void,
    onDecrement : () => void,
    onAdd?: () => void,
    onSet?: () => void,
}

const TerminalCounter = ({awaitQuantity, quantity, onAdd, onSet, onDecrement, onIncrement}: Props) => {
    return (
        <>
        <div className={styles.tableContainer}>
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <td>Ожид. кол-во</td>
                        <td>Скан. кол-во</td>
                        <td>Ост. кол-во</td>
                    </tr>
                    <tr>
                        <td>{awaitQuantity}</td>
                        <td>{quantity}</td>
                        <td>{awaitQuantity! - quantity!}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className={styles.footer}>
            <div className={styles.buttons}>
                <TeminalCounterButton placeHolder="-" onClick={onDecrement} />
                <div className={styles.container}>
                    <div className={styles.button} onClick={onAdd}>Добавить</div>
                    <div className={styles.button} onClick={onSet}>Установить</div>
                </div>
                <TeminalCounterButton placeHolder="+" onClick={onIncrement} />
            </div>
        </div>
        </>
    );
}

export { TerminalCounter };