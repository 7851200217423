import { baseApi as api } from "./base";
export const addTagTypes = [
  "Actions",
  "Admin",
  "AdminWarehouseEmployee",
  "Analytics",
  "Auth",
  "Bills",
  "Cell",
  "Clients",
  "Consumables",
  "Dic",
  "Documents",
  "Employees",
  "Events",
  "File",
  "Inventorization",
  "MoySklad",
  "News",
  "Notifications",
  "Organizations",
  "Package",
  "PackageProcesses",
  "Payments",
  "Placement",
  "PlacementProcesses",
  "Processes",
  "ProductCells",
  "Products",
  "Users",
  "Warehouse",
  "WarehouseEmployee",
  "WarehouseOrganizations",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getProductsReceivedActions: build.query<
        GetProductsReceivedActionsApiResponse,
        GetProductsReceivedActionsApiArg
      >({
        query: (queryArg) => ({
          url: `/products/received/actions`,
          params: { Skip: queryArg.skip, Take: queryArg.take },
        }),
        providesTags: ["Actions"],
      }),
      postProductsReceivedActionsAction: build.mutation<
        PostProductsReceivedActionsActionApiResponse,
        PostProductsReceivedActionsActionApiArg
      >({
        query: (queryArg) => ({
          url: `/products/received/actions/action`,
          method: "POST",
          params: {
            receivedProductCellId: queryArg.receivedProductCellId,
            actionTypeId: queryArg.actionTypeId,
          },
        }),
        invalidatesTags: ["Actions"],
      }),
      postAdminEmpoloyee: build.mutation<
        PostAdminEmpoloyeeApiResponse,
        PostAdminEmpoloyeeApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/empoloyee`,
          method: "POST",
          body: queryArg.createEmployeeModel,
        }),
        invalidatesTags: ["Admin"],
      }),
      getAdminEmployees: build.query<
        GetAdminEmployeesApiResponse,
        GetAdminEmployeesApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/employees`,
          body: queryArg.collectionFilter,
        }),
        providesTags: ["AdminWarehouseEmployee"],
      }),
      getAdminEmployeesPayments: build.query<
        GetAdminEmployeesPaymentsApiResponse,
        GetAdminEmployeesPaymentsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/employees/payments`,
          body: queryArg.collectionFilter,
        }),
        providesTags: ["AdminWarehouseEmployee"],
      }),
      getAnalyticsRemoveData: build.query<
        GetAnalyticsRemoveDataApiResponse,
        GetAnalyticsRemoveDataApiArg
      >({
        query: () => ({ url: `/analytics/removeData` }),
        providesTags: ["Analytics"],
      }),
      getAnalyticsGenProcessesData: build.query<
        GetAnalyticsGenProcessesDataApiResponse,
        GetAnalyticsGenProcessesDataApiArg
      >({
        query: (queryArg) => ({
          url: `/analytics/genProcessesData`,
          params: { length: queryArg.length },
        }),
        providesTags: ["Analytics"],
      }),
      getAnalyticsGenPRoductsData: build.query<
        GetAnalyticsGenPRoductsDataApiResponse,
        GetAnalyticsGenPRoductsDataApiArg
      >({
        query: (queryArg) => ({
          url: `/analytics/genPRoductsData`,
          params: { length: queryArg.length },
        }),
        providesTags: ["Analytics"],
      }),
      getAnalyticsGenData: build.query<
        GetAnalyticsGenDataApiResponse,
        GetAnalyticsGenDataApiArg
      >({
        query: () => ({ url: `/analytics/genData` }),
        providesTags: ["Analytics"],
      }),
      getAnalyticsMoneyDate: build.query<
        GetAnalyticsMoneyDateApiResponse,
        GetAnalyticsMoneyDateApiArg
      >({
        query: () => ({ url: `/analytics/moneyDate` }),
        providesTags: ["Analytics"],
      }),
      getAnalyticsMoney: build.query<
        GetAnalyticsMoneyApiResponse,
        GetAnalyticsMoneyApiArg
      >({
        query: () => ({ url: `/analytics/money` }),
        providesTags: ["Analytics"],
      }),
      getAnalyticsProducts: build.query<
        GetAnalyticsProductsApiResponse,
        GetAnalyticsProductsApiArg
      >({
        query: () => ({ url: `/analytics/products` }),
        providesTags: ["Analytics"],
      }),
      postAnalyticsAddVisit: build.mutation<
        PostAnalyticsAddVisitApiResponse,
        PostAnalyticsAddVisitApiArg
      >({
        query: (queryArg) => ({
          url: `/analytics/addVisit`,
          method: "POST",
          body: queryArg.visitInputModel,
        }),
        invalidatesTags: ["Analytics"],
      }),
      getAnalyticsAddRecallRequest: build.query<
        GetAnalyticsAddRecallRequestApiResponse,
        GetAnalyticsAddRecallRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/analytics/addRecallRequest`,
          params: {
            userId: queryArg.userId,
            sessionId: queryArg.sessionId,
            name: queryArg.name,
            number: queryArg["number"],
            email: queryArg.email,
            telegram: queryArg.telegram,
          },
        }),
        providesTags: ["Analytics"],
      }),
      postAuthLogin: build.mutation<
        PostAuthLoginApiResponse,
        PostAuthLoginApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/login`,
          method: "POST",
          body: queryArg.authCredentials,
        }),
        invalidatesTags: ["Auth"],
      }),
      postAuthRegistration: build.mutation<
        PostAuthRegistrationApiResponse,
        PostAuthRegistrationApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/registration`,
          method: "POST",
          body: queryArg.authCredentials,
        }),
        invalidatesTags: ["Auth"],
      }),
      postBillsExcelHonestSigns: build.mutation<
        PostBillsExcelHonestSignsApiResponse,
        PostBillsExcelHonestSignsApiArg
      >({
        query: (queryArg) => ({
          url: `/bills/excel/honestSigns`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Bills"],
      }),
      getBillsDate: build.query<GetBillsDateApiResponse, GetBillsDateApiArg>({
        query: () => ({ url: `/bills/date` }),
        providesTags: ["Bills"],
      }),
      getBills: build.query<GetBillsApiResponse, GetBillsApiArg>({
        query: (queryArg) => ({
          url: `/bills`,
          params: {
            date: queryArg.date,
            warehouseId: queryArg.warehouseId,
            billType: queryArg.billType,
            billStatus: queryArg.billStatus,
          },
        }),
        providesTags: ["Bills"],
      }),
      deleteBills: build.mutation<DeleteBillsApiResponse, DeleteBillsApiArg>({
        query: (queryArg) => ({
          url: `/bills`,
          method: "DELETE",
          params: { id: queryArg.id },
        }),
        invalidatesTags: ["Bills"],
      }),
      getBillsGetAcceptanceBillsByProductBarcode: build.query<
        GetBillsGetAcceptanceBillsByProductBarcodeApiResponse,
        GetBillsGetAcceptanceBillsByProductBarcodeApiArg
      >({
        query: (queryArg) => ({
          url: `/bills/getAcceptanceBillsByProductBarcode`,
          params: { barcode: queryArg.barcode },
        }),
        providesTags: ["Bills"],
      }),
      getBillsGetShipmentBillsByProductBarcode: build.query<
        GetBillsGetShipmentBillsByProductBarcodeApiResponse,
        GetBillsGetShipmentBillsByProductBarcodeApiArg
      >({
        query: (queryArg) => ({
          url: `/bills/getShipmentBillsByProductBarcode`,
          params: { barcode: queryArg.barcode },
        }),
        providesTags: ["Bills"],
      }),
      getBillsById: build.query<GetBillsByIdApiResponse, GetBillsByIdApiArg>({
        query: (queryArg) => ({ url: `/bills/${queryArg.id}` }),
        providesTags: ["Bills"],
      }),
      getBillsPackageBill: build.query<
        GetBillsPackageBillApiResponse,
        GetBillsPackageBillApiArg
      >({
        query: (queryArg) => ({
          url: `/bills/packageBill`,
          params: { billId: queryArg.billId },
        }),
        providesTags: ["Bills"],
      }),
      postBillsPackageBill: build.mutation<
        PostBillsPackageBillApiResponse,
        PostBillsPackageBillApiArg
      >({
        query: (queryArg) => ({
          url: `/bills/packageBill`,
          method: "POST",
          body: queryArg.redactPackageBillModel,
        }),
        invalidatesTags: ["Bills"],
      }),
      getBillsPlacementBill: build.query<
        GetBillsPlacementBillApiResponse,
        GetBillsPlacementBillApiArg
      >({
        query: (queryArg) => ({
          url: `/bills/placementBill`,
          params: { billId: queryArg.billId },
        }),
        providesTags: ["Bills"],
      }),
      postBillsPlacementBill: build.mutation<
        PostBillsPlacementBillApiResponse,
        PostBillsPlacementBillApiArg
      >({
        query: (queryArg) => ({
          url: `/bills/placementBill`,
          method: "POST",
          body: queryArg.redactPlacementBillModel,
        }),
        invalidatesTags: ["Bills"],
      }),
      postBillsRedactPlacementBill: build.mutation<
        PostBillsRedactPlacementBillApiResponse,
        PostBillsRedactPlacementBillApiArg
      >({
        query: (queryArg) => ({
          url: `/bills/redactPlacementBill`,
          method: "POST",
          body: queryArg.redactPlacementBillModel,
        }),
        invalidatesTags: ["Bills"],
      }),
      postBillsRedactPackageBill: build.mutation<
        PostBillsRedactPackageBillApiResponse,
        PostBillsRedactPackageBillApiArg
      >({
        query: (queryArg) => ({
          url: `/bills/redactPackageBill`,
          method: "POST",
          body: queryArg.redactPackageBillModel,
        }),
        invalidatesTags: ["Bills"],
      }),
      postBillsBill: build.mutation<
        PostBillsBillApiResponse,
        PostBillsBillApiArg
      >({
        query: (queryArg) => ({
          url: `/bills/bill`,
          method: "POST",
          body: queryArg.redactBillModel,
        }),
        invalidatesTags: ["Bills"],
      }),
      getBillsByIdDetails: build.query<
        GetBillsByIdDetailsApiResponse,
        GetBillsByIdDetailsApiArg
      >({
        query: (queryArg) => ({ url: `/bills/${queryArg.id}/details` }),
        providesTags: ["Bills"],
      }),
      postBillsRedactBill: build.mutation<
        PostBillsRedactBillApiResponse,
        PostBillsRedactBillApiArg
      >({
        query: (queryArg) => ({
          url: `/bills/redactBill`,
          method: "POST",
          body: queryArg.redactBillModel,
        }),
        invalidatesTags: ["Bills"],
      }),
      postBillsFileExcel: build.mutation<
        PostBillsFileExcelApiResponse,
        PostBillsFileExcelApiArg
      >({
        query: (queryArg) => ({
          url: `/bills/file/excel`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Bills"],
      }),
      postBillsEmployeeFileExcel: build.mutation<
        PostBillsEmployeeFileExcelApiResponse,
        PostBillsEmployeeFileExcelApiArg
      >({
        query: (queryArg) => ({
          url: `/bills/employee/file/excel`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Bills"],
      }),
      putBillsStatus: build.mutation<
        PutBillsStatusApiResponse,
        PutBillsStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/bills/status`,
          method: "PUT",
          params: { billId: queryArg.billId, status: queryArg.status },
        }),
        invalidatesTags: ["Bills"],
      }),
      getCells: build.query<GetCellsApiResponse, GetCellsApiArg>({
        query: (queryArg) => ({
          url: `/cells`,
          params: {
            cellType: queryArg.cellType,
            warehouseId: queryArg.warehouseId,
          },
        }),
        providesTags: ["Cell"],
      }),
      postCells: build.mutation<PostCellsApiResponse, PostCellsApiArg>({
        query: (queryArg) => ({
          url: `/cells`,
          method: "POST",
          body: queryArg.createCellRequest,
        }),
        invalidatesTags: ["Cell"],
      }),
      getCellsById: build.query<GetCellsByIdApiResponse, GetCellsByIdApiArg>({
        query: (queryArg) => ({ url: `/cells/${queryArg.id}` }),
        providesTags: ["Cell"],
      }),
      getClientsCooperationRequests: build.query<
        GetClientsCooperationRequestsApiResponse,
        GetClientsCooperationRequestsApiArg
      >({
        query: () => ({ url: `/clients/cooperationRequests` }),
        providesTags: ["Clients"],
      }),
      getClientsCooperations: build.query<
        GetClientsCooperationsApiResponse,
        GetClientsCooperationsApiArg
      >({
        query: () => ({ url: `/clients/cooperations` }),
        providesTags: ["Clients"],
      }),
      postClientsCreateCooperation: build.mutation<
        PostClientsCreateCooperationApiResponse,
        PostClientsCreateCooperationApiArg
      >({
        query: (queryArg) => ({
          url: `/clients/createCooperation`,
          method: "POST",
          params: { cooperationRequestId: queryArg.cooperationRequestId },
        }),
        invalidatesTags: ["Clients"],
      }),
      deleteClientsDeleteCooperation: build.mutation<
        DeleteClientsDeleteCooperationApiResponse,
        DeleteClientsDeleteCooperationApiArg
      >({
        query: (queryArg) => ({
          url: `/clients/deleteCooperation`,
          method: "DELETE",
          params: { cooperationId: queryArg.cooperationId },
        }),
        invalidatesTags: ["Clients"],
      }),
      postClientsCreateCooperationRequest: build.mutation<
        PostClientsCreateCooperationRequestApiResponse,
        PostClientsCreateCooperationRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/clients/createCooperationRequest`,
          method: "POST",
          params: {
            warehouseOrganizationId: queryArg.warehouseOrganizationId,
            organizationId: queryArg.organizationId,
          },
        }),
        invalidatesTags: ["Clients"],
      }),
      deleteClientsDeleteCooperationRequest: build.mutation<
        DeleteClientsDeleteCooperationRequestApiResponse,
        DeleteClientsDeleteCooperationRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/clients/deleteCooperationRequest`,
          method: "DELETE",
          params: { cooperationRequestId: queryArg.cooperationRequestId },
        }),
        invalidatesTags: ["Clients"],
      }),
      getConsumables: build.query<
        GetConsumablesApiResponse,
        GetConsumablesApiArg
      >({
        query: () => ({ url: `/consumables` }),
        providesTags: ["Consumables"],
      }),
      postConsumables: build.mutation<
        PostConsumablesApiResponse,
        PostConsumablesApiArg
      >({
        query: (queryArg) => ({
          url: `/consumables`,
          method: "POST",
          body: queryArg.createConsumablesModel,
        }),
        invalidatesTags: ["Consumables"],
      }),
      getConsumablesSpending: build.query<
        GetConsumablesSpendingApiResponse,
        GetConsumablesSpendingApiArg
      >({
        query: (queryArg) => ({
          url: `/consumables/spending`,
          body: queryArg.consumablesFilter,
        }),
        providesTags: ["Consumables"],
      }),
      getConsumablesSpendingActual: build.query<
        GetConsumablesSpendingActualApiResponse,
        GetConsumablesSpendingActualApiArg
      >({
        query: (queryArg) => ({
          url: `/consumables/spending/actual`,
          body: queryArg.body,
        }),
        providesTags: ["Consumables"],
      }),
      putConsumablesByIdSpentQuantity: build.mutation<
        PutConsumablesByIdSpentQuantityApiResponse,
        PutConsumablesByIdSpentQuantityApiArg
      >({
        query: (queryArg) => ({
          url: `/consumables/${queryArg.id}/spentQuantity`,
          method: "PUT",
          params: { spentQuantity: queryArg.spentQuantity },
        }),
        invalidatesTags: ["Consumables"],
      }),
      putConsumablesByIdQuantity: build.mutation<
        PutConsumablesByIdQuantityApiResponse,
        PutConsumablesByIdQuantityApiArg
      >({
        query: (queryArg) => ({
          url: `/consumables/${queryArg.id}/quantity`,
          method: "PUT",
          params: { quantity: queryArg.quantity },
        }),
        invalidatesTags: ["Consumables"],
      }),
      postDicAddVisit: build.mutation<
        PostDicAddVisitApiResponse,
        PostDicAddVisitApiArg
      >({
        query: (queryArg) => ({
          url: `/dic/addVisit`,
          method: "POST",
          body: queryArg.dicVisitInputModel,
        }),
        invalidatesTags: ["Dic"],
      }),
      getDicAddRecallRequest: build.query<
        GetDicAddRecallRequestApiResponse,
        GetDicAddRecallRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/dic/addRecallRequest`,
          params: {
            productId: queryArg.productId,
            userId: queryArg.userId,
            sessionId: queryArg.sessionId,
            name: queryArg.name,
            number: queryArg["number"],
            email: queryArg.email,
            telegram: queryArg.telegram,
          },
        }),
        providesTags: ["Dic"],
      }),
      getDocuments: build.query<GetDocumentsApiResponse, GetDocumentsApiArg>({
        query: () => ({ url: `/documents` }),
        providesTags: ["Documents"],
      }),
      getDocumentsDocxBills: build.query<
        GetDocumentsDocxBillsApiResponse,
        GetDocumentsDocxBillsApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/docx/bills`,
          params: { billId: queryArg.billId, userId: queryArg.userId },
        }),
        providesTags: ["Documents"],
      }),
      postDocumentsExcelBills: build.mutation<
        PostDocumentsExcelBillsApiResponse,
        PostDocumentsExcelBillsApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/excel/bills`,
          method: "POST",
          body: queryArg.billsFilter,
        }),
        invalidatesTags: ["Documents"],
      }),
      postDocumentsTemplate: build.mutation<
        PostDocumentsTemplateApiResponse,
        PostDocumentsTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/template`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Documents"],
      }),
      getEmployeesCooperationRequests: build.query<
        GetEmployeesCooperationRequestsApiResponse,
        GetEmployeesCooperationRequestsApiArg
      >({
        query: () => ({ url: `/employees/cooperationRequests` }),
        providesTags: ["Employees"],
      }),
      getEmployeesCooperations: build.query<
        GetEmployeesCooperationsApiResponse,
        GetEmployeesCooperationsApiArg
      >({
        query: () => ({ url: `/employees/cooperations` }),
        providesTags: ["Employees"],
      }),
      postEmployeesCreateCooperation: build.mutation<
        PostEmployeesCreateCooperationApiResponse,
        PostEmployeesCreateCooperationApiArg
      >({
        query: (queryArg) => ({
          url: `/employees/createCooperation`,
          method: "POST",
          params: { cooperationRequestId: queryArg.cooperationRequestId },
        }),
        invalidatesTags: ["Employees"],
      }),
      deleteEmployeesDeleteCooperation: build.mutation<
        DeleteEmployeesDeleteCooperationApiResponse,
        DeleteEmployeesDeleteCooperationApiArg
      >({
        query: (queryArg) => ({
          url: `/employees/deleteCooperation`,
          method: "DELETE",
          params: { cooperationId: queryArg.cooperationId },
        }),
        invalidatesTags: ["Employees"],
      }),
      postEmployeesCreateCooperationRequest: build.mutation<
        PostEmployeesCreateCooperationRequestApiResponse,
        PostEmployeesCreateCooperationRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/employees/createCooperationRequest`,
          method: "POST",
          params: { organizationId: queryArg.organizationId },
        }),
        invalidatesTags: ["Employees"],
      }),
      deleteEmployeesDeleteCooperationRequest: build.mutation<
        DeleteEmployeesDeleteCooperationRequestApiResponse,
        DeleteEmployeesDeleteCooperationRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/employees/deleteCooperationRequest`,
          method: "DELETE",
          params: { cooperationRequestId: queryArg.cooperationRequestId },
        }),
        invalidatesTags: ["Employees"],
      }),
      getEvents: build.query<GetEventsApiResponse, GetEventsApiArg>({
        query: () => ({ url: `/events` }),
        providesTags: ["Events"],
      }),
      postEvents: build.mutation<PostEventsApiResponse, PostEventsApiArg>({
        query: (queryArg) => ({
          url: `/events`,
          method: "POST",
          params: {
            title: queryArg.title,
            description: queryArg.description,
            userId: queryArg.userId,
          },
        }),
        invalidatesTags: ["Events"],
      }),
      postFilesUpload: build.mutation<
        PostFilesUploadApiResponse,
        PostFilesUploadApiArg
      >({
        query: (queryArg) => ({
          url: `/files/upload`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["File"],
      }),
      getProductsInventorizeClients: build.query<
        GetProductsInventorizeClientsApiResponse,
        GetProductsInventorizeClientsApiArg
      >({
        query: () => ({ url: `/products/inventorize/clients` }),
        providesTags: ["Inventorization"],
      }),
      postIntegrationMoySkladProduct: build.mutation<
        PostIntegrationMoySkladProductApiResponse,
        PostIntegrationMoySkladProductApiArg
      >({
        query: (queryArg) => ({
          url: `/integration/moy-sklad/product`,
          method: "POST",
          params: { productId: queryArg.productId },
        }),
        invalidatesTags: ["MoySklad"],
      }),
      postIntegrationMoySkladData: build.mutation<
        PostIntegrationMoySkladDataApiResponse,
        PostIntegrationMoySkladDataApiArg
      >({
        query: (queryArg) => ({
          url: `/integration/moy-sklad/data`,
          method: "POST",
          body: queryArg.integrationDataModel,
        }),
        invalidatesTags: ["MoySklad"],
      }),
      getNews: build.query<GetNewsApiResponse, GetNewsApiArg>({
        query: () => ({ url: `/news` }),
        providesTags: ["News"],
      }),
      postNews: build.mutation<PostNewsApiResponse, PostNewsApiArg>({
        query: (queryArg) => ({
          url: `/news`,
          method: "POST",
          params: { title: queryArg.title, description: queryArg.description },
        }),
        invalidatesTags: ["News"],
      }),
      postNotificationsSeen: build.mutation<
        PostNotificationsSeenApiResponse,
        PostNotificationsSeenApiArg
      >({
        query: (queryArg) => ({
          url: `/notifications/seen`,
          method: "POST",
          body: queryArg.notificationSeenModelInput,
        }),
        invalidatesTags: ["Notifications"],
      }),
      getNotifications: build.query<
        GetNotificationsApiResponse,
        GetNotificationsApiArg
      >({
        query: () => ({ url: `/notifications` }),
        providesTags: ["Notifications"],
      }),
      postNotificationsNew: build.mutation<
        PostNotificationsNewApiResponse,
        PostNotificationsNewApiArg
      >({
        query: (queryArg) => ({
          url: `/notifications/new`,
          method: "POST",
          params: { userId: queryArg.userId, text: queryArg.text },
        }),
        invalidatesTags: ["Notifications"],
      }),
      getOrganizations: build.query<
        GetOrganizationsApiResponse,
        GetOrganizationsApiArg
      >({
        query: () => ({ url: `/organizations` }),
        providesTags: ["Organizations"],
      }),
      postPackageComplete: build.mutation<
        PostPackageCompleteApiResponse,
        PostPackageCompleteApiArg
      >({
        query: (queryArg) => ({
          url: `/package/complete`,
          method: "POST",
          body: queryArg.completePackageInputModel,
        }),
        invalidatesTags: ["Package"],
      }),
      getProcessesPackageSetEmployeeInProcessAndGetEmployeesInProcess:
        build.query<
          GetProcessesPackageSetEmployeeInProcessAndGetEmployeesInProcessApiResponse,
          GetProcessesPackageSetEmployeeInProcessAndGetEmployeesInProcessApiArg
        >({
          query: (queryArg) => ({
            url: `/processes/package/setEmployeeInProcessAndGetEmployeesInProcess`,
            params: { processId: queryArg.processId },
          }),
          providesTags: ["PackageProcesses"],
        }),
      postProcessesPackageRedactProcessEmployee: build.mutation<
        PostProcessesPackageRedactProcessEmployeeApiResponse,
        PostProcessesPackageRedactProcessEmployeeApiArg
      >({
        query: (queryArg) => ({
          url: `/processes/package/redactProcessEmployee`,
          method: "POST",
          body: queryArg.redactProcessEmployeeModel,
        }),
        invalidatesTags: ["PackageProcesses"],
      }),
      postProcessesPackageAddProcess: build.mutation<
        PostProcessesPackageAddProcessApiResponse,
        PostProcessesPackageAddProcessApiArg
      >({
        query: (queryArg) => ({
          url: `/processes/package/addProcess`,
          method: "POST",
          params: { billId: queryArg.billId, cellId: queryArg.cellId },
        }),
        invalidatesTags: ["PackageProcesses"],
      }),
      getProcessesPackageProcessModel: build.query<
        GetProcessesPackageProcessModelApiResponse,
        GetProcessesPackageProcessModelApiArg
      >({
        query: (queryArg) => ({
          url: `/processes/package/ProcessModel`,
          params: { processId: queryArg.processId },
        }),
        providesTags: ["PackageProcesses"],
      }),
      postProcessesPackageComplete: build.mutation<
        PostProcessesPackageCompleteApiResponse,
        PostProcessesPackageCompleteApiArg
      >({
        query: (queryArg) => ({
          url: `/processes/package/complete`,
          method: "POST",
          body: queryArg.inspectionCompleteModel,
        }),
        invalidatesTags: ["PackageProcesses"],
      }),
      postPaymentsGen: build.mutation<
        PostPaymentsGenApiResponse,
        PostPaymentsGenApiArg
      >({
        query: () => ({ url: `/payments/gen`, method: "POST" }),
        invalidatesTags: ["Payments"],
      }),
      getPaymentsBill: build.query<
        GetPaymentsBillApiResponse,
        GetPaymentsBillApiArg
      >({
        query: (queryArg) => ({
          url: `/payments/bill`,
          params: { billId: queryArg.billId },
        }),
        providesTags: ["Payments"],
      }),
      getPaymentsBills: build.query<
        GetPaymentsBillsApiResponse,
        GetPaymentsBillsApiArg
      >({
        query: () => ({ url: `/payments/bills` }),
        providesTags: ["Payments"],
      }),
      postPayments: build.mutation<PostPaymentsApiResponse, PostPaymentsApiArg>(
        {
          query: (queryArg) => ({
            url: `/payments`,
            method: "POST",
            body: queryArg.createPaymentBillModel,
          }),
          invalidatesTags: ["Payments"],
        }
      ),
      postPlacementComplete: build.mutation<
        PostPlacementCompleteApiResponse,
        PostPlacementCompleteApiArg
      >({
        query: (queryArg) => ({
          url: `/placement/complete`,
          method: "POST",
          body: queryArg.placementBillInputModel,
        }),
        invalidatesTags: ["Placement"],
      }),
      getProcessesPlacementSetEmployeeInProcessAndGetEmployeesInProcess:
        build.query<
          GetProcessesPlacementSetEmployeeInProcessAndGetEmployeesInProcessApiResponse,
          GetProcessesPlacementSetEmployeeInProcessAndGetEmployeesInProcessApiArg
        >({
          query: (queryArg) => ({
            url: `/processes/placement/setEmployeeInProcessAndGetEmployeesInProcess`,
            params: { processId: queryArg.processId },
          }),
          providesTags: ["PlacementProcesses"],
        }),
      postProcessesPlacementRedactProcessEmployee: build.mutation<
        PostProcessesPlacementRedactProcessEmployeeApiResponse,
        PostProcessesPlacementRedactProcessEmployeeApiArg
      >({
        query: (queryArg) => ({
          url: `/processes/placement/redactProcessEmployee`,
          method: "POST",
          body: queryArg.redactProcessEmployeeModel,
        }),
        invalidatesTags: ["PlacementProcesses"],
      }),
      postProcessesPlacementAddProcess: build.mutation<
        PostProcessesPlacementAddProcessApiResponse,
        PostProcessesPlacementAddProcessApiArg
      >({
        query: (queryArg) => ({
          url: `/processes/placement/addProcess`,
          method: "POST",
          params: { billId: queryArg.billId },
        }),
        invalidatesTags: ["PlacementProcesses"],
      }),
      getProcessesPlacementProcessModel: build.query<
        GetProcessesPlacementProcessModelApiResponse,
        GetProcessesPlacementProcessModelApiArg
      >({
        query: (queryArg) => ({
          url: `/processes/placement/ProcessModel`,
          params: { processId: queryArg.processId },
        }),
        providesTags: ["PlacementProcesses"],
      }),
      postProcessesPlacementComplete: build.mutation<
        PostProcessesPlacementCompleteApiResponse,
        PostProcessesPlacementCompleteApiArg
      >({
        query: (queryArg) => ({
          url: `/processes/placement/complete`,
          method: "POST",
          body: queryArg.inspectionCompleteModel,
        }),
        invalidatesTags: ["PlacementProcesses"],
      }),
      getProcessesSetEmployeeInProcessAndGetEmployeesInProcess: build.query<
        GetProcessesSetEmployeeInProcessAndGetEmployeesInProcessApiResponse,
        GetProcessesSetEmployeeInProcessAndGetEmployeesInProcessApiArg
      >({
        query: (queryArg) => ({
          url: `/processes/setEmployeeInProcessAndGetEmployeesInProcess`,
          params: { processId: queryArg.processId },
        }),
        providesTags: ["Processes"],
      }),
      postProcessesRedactProcessEmployee: build.mutation<
        PostProcessesRedactProcessEmployeeApiResponse,
        PostProcessesRedactProcessEmployeeApiArg
      >({
        query: (queryArg) => ({
          url: `/processes/redactProcessEmployee`,
          method: "POST",
          body: queryArg.redactProcessEmployeeModel,
        }),
        invalidatesTags: ["Processes"],
      }),
      postProcessesAddProcess: build.mutation<
        PostProcessesAddProcessApiResponse,
        PostProcessesAddProcessApiArg
      >({
        query: (queryArg) => ({
          url: `/processes/addProcess`,
          method: "POST",
          params: { billId: queryArg.billId, cellId: queryArg.cellId },
        }),
        invalidatesTags: ["Processes"],
      }),
      getProcessesGetProcessBillById: build.query<
        GetProcessesGetProcessBillByIdApiResponse,
        GetProcessesGetProcessBillByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/processes/getProcessBillById`,
          params: { processBillId: queryArg.processBillId },
        }),
        providesTags: ["Processes"],
      }),
      getProcesses: build.query<GetProcessesApiResponse, GetProcessesApiArg>({
        query: () => ({ url: `/processes` }),
        providesTags: ["Processes"],
      }),
      getProcessesInspectionProcessModel: build.query<
        GetProcessesInspectionProcessModelApiResponse,
        GetProcessesInspectionProcessModelApiArg
      >({
        query: (queryArg) => ({
          url: `/processes/inspectionProcessModel`,
          params: { processId: queryArg.processId },
        }),
        providesTags: ["Processes"],
      }),
      postProcessesInspectionComplete: build.mutation<
        PostProcessesInspectionCompleteApiResponse,
        PostProcessesInspectionCompleteApiArg
      >({
        query: (queryArg) => ({
          url: `/processes/inspection/complete`,
          method: "POST",
          body: queryArg.inspectionCompleteModel,
        }),
        invalidatesTags: ["Processes"],
      }),
      postProductsCellsShip: build.mutation<
        PostProductsCellsShipApiResponse,
        PostProductsCellsShipApiArg
      >({
        query: (queryArg) => ({
          url: `/products/cells/ship`,
          method: "POST",
          body: queryArg.shipmentModel,
        }),
        invalidatesTags: ["ProductCells"],
      }),
      postProductsExcel: build.mutation<
        PostProductsExcelApiResponse,
        PostProductsExcelApiArg
      >({
        query: () => ({ url: `/products/excel`, method: "POST" }),
        invalidatesTags: ["Products"],
      }),
      getProducts: build.query<GetProductsApiResponse, GetProductsApiArg>({
        query: (queryArg) => ({
          url: `/products`,
          params: {
            notNullQuantity: queryArg.notNullQuantity,
            clientId: queryArg.clientId,
            warehouseId: queryArg.warehouseId,
            organizationId: queryArg.organizationId,
            cellId: queryArg.cellId,
            warehouseIdForCellsQuantity: queryArg.warehouseIdForCellsQuantity,
            isNotPackage: queryArg.isNotPackage,
            isNotBookedPackage: queryArg.isNotBookedPackage,
            isNotBookedShip: queryArg.isNotBookedShip,
            isAvailableForPackage: queryArg.isAvailableForPackage,
            isAvailableForTransit: queryArg.isAvailableForTransit,
            redactPlacementBillId: queryArg.redactPlacementBillId,
            redactPackageBillId: queryArg.redactPackageBillId,
          },
        }),
        providesTags: ["Products"],
      }),
      postProducts: build.mutation<PostProductsApiResponse, PostProductsApiArg>(
        {
          query: (queryArg) => ({
            url: `/products`,
            method: "POST",
            body: queryArg.productCreateModel,
          }),
          invalidatesTags: ["Products"],
        }
      ),
      deleteProducts: build.mutation<
        DeleteProductsApiResponse,
        DeleteProductsApiArg
      >({
        query: (queryArg) => ({
          url: `/products`,
          method: "DELETE",
          params: { id: queryArg.id },
        }),
        invalidatesTags: ["Products"],
      }),
      getProductsById: build.query<
        GetProductsByIdApiResponse,
        GetProductsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/products/${queryArg.id}` }),
        providesTags: ["Products"],
      }),
      getProductsByIdDetails: build.query<
        GetProductsByIdDetailsApiResponse,
        GetProductsByIdDetailsApiArg
      >({
        query: (queryArg) => ({
          url: `/products/${queryArg.id}/details`,
          params: {
            warehouseId: queryArg.warehouseId,
            isAvailableForTransit: queryArg.isAvailableForTransit,
            redactPlacementBillId: queryArg.redactPlacementBillId,
          },
        }),
        providesTags: ["Products"],
      }),
      getProductsByProductIdCells: build.query<
        GetProductsByProductIdCellsApiResponse,
        GetProductsByProductIdCellsApiArg
      >({
        query: (queryArg) => ({ url: `/products/${queryArg.productId}/cells` }),
        providesTags: ["Products"],
      }),
      deleteProductsImage: build.mutation<
        DeleteProductsImageApiResponse,
        DeleteProductsImageApiArg
      >({
        query: (queryArg) => ({
          url: `/products/image`,
          method: "DELETE",
          body: queryArg.deleteProductImageInput,
        }),
        invalidatesTags: ["Products"],
      }),
      postProductsImage: build.mutation<
        PostProductsImageApiResponse,
        PostProductsImageApiArg
      >({
        query: (queryArg) => ({
          url: `/products/image`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Products"],
      }),
      postProductsKit: build.mutation<
        PostProductsKitApiResponse,
        PostProductsKitApiArg
      >({
        query: (queryArg) => ({
          url: `/products/kit`,
          method: "POST",
          body: queryArg.createKitModel,
        }),
        invalidatesTags: ["Products"],
      }),
      postProductsRedact: build.mutation<
        PostProductsRedactApiResponse,
        PostProductsRedactApiArg
      >({
        query: (queryArg) => ({
          url: `/products/redact`,
          method: "POST",
          body: queryArg.productRedactModel,
        }),
        invalidatesTags: ["Products"],
      }),
      getUsersUserOrganizations: build.query<
        GetUsersUserOrganizationsApiResponse,
        GetUsersUserOrganizationsApiArg
      >({
        query: () => ({ url: `/users/user/organizations` }),
        providesTags: ["Users"],
      }),
      getUsersMe: build.query<GetUsersMeApiResponse, GetUsersMeApiArg>({
        query: () => ({ url: `/users/me` }),
        providesTags: ["Users"],
      }),
      putUsersMe: build.mutation<PutUsersMeApiResponse, PutUsersMeApiArg>({
        query: (queryArg) => ({
          url: `/users/me`,
          method: "PUT",
          body: queryArg.updateUserProfileModel,
        }),
        invalidatesTags: ["Users"],
      }),
      getUsersProfile: build.query<
        GetUsersProfileApiResponse,
        GetUsersProfileApiArg
      >({
        query: () => ({ url: `/users/profile` }),
        providesTags: ["Users"],
      }),
      putUsersProfileImage: build.mutation<
        PutUsersProfileImageApiResponse,
        PutUsersProfileImageApiArg
      >({
        query: (queryArg) => ({
          url: `/users/profileImage`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Users"],
      }),
      putUsersSettings: build.mutation<
        PutUsersSettingsApiResponse,
        PutUsersSettingsApiArg
      >({
        query: (queryArg) => ({
          url: `/users/settings`,
          method: "PUT",
          body: queryArg.settingsModel,
        }),
        invalidatesTags: ["Users"],
      }),
      getUsersSettings: build.query<
        GetUsersSettingsApiResponse,
        GetUsersSettingsApiArg
      >({
        query: () => ({ url: `/users/settings` }),
        providesTags: ["Users"],
      }),
      getUsersEmployees: build.query<
        GetUsersEmployeesApiResponse,
        GetUsersEmployeesApiArg
      >({
        query: (queryArg) => ({
          url: `/users/employees`,
          params: { warehouseId: queryArg.warehouseId },
        }),
        providesTags: ["Users"],
      }),
      getWarehouseByIdScheme: build.query<
        GetWarehouseByIdSchemeApiResponse,
        GetWarehouseByIdSchemeApiArg
      >({
        query: (queryArg) => ({ url: `/warehouse/${queryArg.id}/scheme` }),
        providesTags: ["Warehouse"],
      }),
      putWarehouseSchemesBySchemeId: build.mutation<
        PutWarehouseSchemesBySchemeIdApiResponse,
        PutWarehouseSchemesBySchemeIdApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouse/schemes/${queryArg.schemeId}`,
          method: "PUT",
          body: queryArg.updateWarehouseSchemeInputModel,
        }),
        invalidatesTags: ["Warehouse"],
      }),
      deleteWarehouseSchemesBySchemeId: build.mutation<
        DeleteWarehouseSchemesBySchemeIdApiResponse,
        DeleteWarehouseSchemesBySchemeIdApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouse/schemes/${queryArg.schemeId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Warehouse"],
      }),
      postWarehouseByWarehouseIdCreateScheme: build.mutation<
        PostWarehouseByWarehouseIdCreateSchemeApiResponse,
        PostWarehouseByWarehouseIdCreateSchemeApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouse/${queryArg.warehouseId}/createScheme`,
          method: "POST",
          body: queryArg.body,
          params: {
            schemeName: queryArg.schemeName,
            Length: queryArg.length,
            Width: queryArg.width,
            Height: queryArg.height,
          },
        }),
        invalidatesTags: ["Warehouse"],
      }),
      getWarehouseByIdConsumables: build.query<
        GetWarehouseByIdConsumablesApiResponse,
        GetWarehouseByIdConsumablesApiArg
      >({
        query: (queryArg) => ({ url: `/warehouse/${queryArg.id}/consumables` }),
        providesTags: ["Warehouse"],
      }),
      getWarehouseClientsCooperationRequests: build.query<
        GetWarehouseClientsCooperationRequestsApiResponse,
        GetWarehouseClientsCooperationRequestsApiArg
      >({
        query: () => ({ url: `/warehouse/clients/cooperationRequests` }),
        providesTags: ["Warehouse"],
      }),
      getWarehouseClientsCooperations: build.query<
        GetWarehouseClientsCooperationsApiResponse,
        GetWarehouseClientsCooperationsApiArg
      >({
        query: () => ({ url: `/warehouse/clients/cooperations` }),
        providesTags: ["Warehouse"],
      }),
      postWarehouseClientsCreateCooperation: build.mutation<
        PostWarehouseClientsCreateCooperationApiResponse,
        PostWarehouseClientsCreateCooperationApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouse/clients/createCooperation`,
          method: "POST",
          params: { cooperationRequestId: queryArg.cooperationRequestId },
        }),
        invalidatesTags: ["Warehouse"],
      }),
      deleteWarehouseClientsDeleteCooperation: build.mutation<
        DeleteWarehouseClientsDeleteCooperationApiResponse,
        DeleteWarehouseClientsDeleteCooperationApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouse/clients/deleteCooperation`,
          method: "DELETE",
          params: { cooperationId: queryArg.cooperationId },
        }),
        invalidatesTags: ["Warehouse"],
      }),
      postWarehouseClientsCreateCooperationRequest: build.mutation<
        PostWarehouseClientsCreateCooperationRequestApiResponse,
        PostWarehouseClientsCreateCooperationRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouse/clients/createCooperationRequest`,
          method: "POST",
          params: {
            clientId: queryArg.clientId,
            organizationId: queryArg.organizationId,
          },
        }),
        invalidatesTags: ["Warehouse"],
      }),
      deleteWarehouseClientsDeleteCooperationRequest: build.mutation<
        DeleteWarehouseClientsDeleteCooperationRequestApiResponse,
        DeleteWarehouseClientsDeleteCooperationRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouse/clients/deleteCooperationRequest`,
          method: "DELETE",
          params: { cooperationRequestId: queryArg.cooperationRequestId },
        }),
        invalidatesTags: ["Warehouse"],
      }),
      deleteWarehouseImage: build.mutation<
        DeleteWarehouseImageApiResponse,
        DeleteWarehouseImageApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouse/image`,
          method: "DELETE",
          body: queryArg.deleteProductImageInput,
        }),
        invalidatesTags: ["Warehouse"],
      }),
      postWarehouseImage: build.mutation<
        PostWarehouseImageApiResponse,
        PostWarehouseImageApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouse/image`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Warehouse"],
      }),
      postWarehouseCreate: build.mutation<
        PostWarehouseCreateApiResponse,
        PostWarehouseCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouse/create`,
          method: "POST",
          body: queryArg.createWarehouseInputModel,
        }),
        invalidatesTags: ["Warehouse"],
      }),
      postWarehouseCreateOrganization: build.mutation<
        PostWarehouseCreateOrganizationApiResponse,
        PostWarehouseCreateOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouse/createOrganization`,
          method: "POST",
          params: { organizationName: queryArg.organizationName },
        }),
        invalidatesTags: ["Warehouse"],
      }),
      getWarehouseOrganizationsByUser: build.query<
        GetWarehouseOrganizationsByUserApiResponse,
        GetWarehouseOrganizationsByUserApiArg
      >({
        query: () => ({ url: `/warehouse/organizationsByUser` }),
        providesTags: ["Warehouse"],
      }),
      getWarehouseListByUser: build.query<
        GetWarehouseListByUserApiResponse,
        GetWarehouseListByUserApiArg
      >({
        query: () => ({ url: `/warehouse/listByUser` }),
        providesTags: ["Warehouse"],
      }),
      getWarehouse: build.query<GetWarehouseApiResponse, GetWarehouseApiArg>({
        query: (queryArg) => ({
          url: `/warehouse`,
          params: { Skip: queryArg.skip, Take: queryArg.take },
        }),
        providesTags: ["Warehouse"],
      }),
      getWarehouseById: build.query<
        GetWarehouseByIdApiResponse,
        GetWarehouseByIdApiArg
      >({
        query: (queryArg) => ({ url: `/warehouse/${queryArg.id}` }),
        providesTags: ["Warehouse"],
      }),
      getWarehouseEmployeeCooperationRequests: build.query<
        GetWarehouseEmployeeCooperationRequestsApiResponse,
        GetWarehouseEmployeeCooperationRequestsApiArg
      >({
        query: () => ({ url: `/warehouse/employee/cooperationRequests` }),
        providesTags: ["WarehouseEmployee"],
      }),
      getWarehouseEmployeeCooperations: build.query<
        GetWarehouseEmployeeCooperationsApiResponse,
        GetWarehouseEmployeeCooperationsApiArg
      >({
        query: () => ({ url: `/warehouse/employee/cooperations` }),
        providesTags: ["WarehouseEmployee"],
      }),
      postWarehouseEmployeeCreateCooperation: build.mutation<
        PostWarehouseEmployeeCreateCooperationApiResponse,
        PostWarehouseEmployeeCreateCooperationApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouse/employee/createCooperation`,
          method: "POST",
          params: { cooperationRequestId: queryArg.cooperationRequestId },
        }),
        invalidatesTags: ["WarehouseEmployee"],
      }),
      deleteWarehouseEmployeeDeleteCooperation: build.mutation<
        DeleteWarehouseEmployeeDeleteCooperationApiResponse,
        DeleteWarehouseEmployeeDeleteCooperationApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouse/employee/deleteCooperation`,
          method: "DELETE",
          params: { cooperationId: queryArg.cooperationId },
        }),
        invalidatesTags: ["WarehouseEmployee"],
      }),
      postWarehouseEmployeeCreateCooperationRequest: build.mutation<
        PostWarehouseEmployeeCreateCooperationRequestApiResponse,
        PostWarehouseEmployeeCreateCooperationRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouse/employee/createCooperationRequest`,
          method: "POST",
          params: {
            employeeId: queryArg.employeeId,
            organizationId: queryArg.organizationId,
          },
        }),
        invalidatesTags: ["WarehouseEmployee"],
      }),
      deleteWarehouseEmployeeDeleteCooperationRequest: build.mutation<
        DeleteWarehouseEmployeeDeleteCooperationRequestApiResponse,
        DeleteWarehouseEmployeeDeleteCooperationRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouse/employee/deleteCooperationRequest`,
          method: "DELETE",
          params: { cooperationRequestId: queryArg.cooperationRequestId },
        }),
        invalidatesTags: ["WarehouseEmployee"],
      }),
      getWarehouseOrganizations: build.query<
        GetWarehouseOrganizationsApiResponse,
        GetWarehouseOrganizationsApiArg
      >({
        query: () => ({ url: `/warehouseOrganizations` }),
        providesTags: ["WarehouseOrganizations"],
      }),
      getWarehouseOrganizationsByIdEmployees: build.query<
        GetWarehouseOrganizationsByIdEmployeesApiResponse,
        GetWarehouseOrganizationsByIdEmployeesApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouseOrganizations/${queryArg.id}/employees`,
        }),
        providesTags: ["WarehouseOrganizations"],
      }),
      getWarehouseOrganizationsByIdWarehouses: build.query<
        GetWarehouseOrganizationsByIdWarehousesApiResponse,
        GetWarehouseOrganizationsByIdWarehousesApiArg
      >({
        query: (queryArg) => ({
          url: `/warehouseOrganizations/${queryArg.id}/warehouses`,
        }),
        providesTags: ["WarehouseOrganizations"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as baseApi };
export type GetProductsReceivedActionsApiResponse =
  /** status 200 Success */ DifProductCellAction[];
export type GetProductsReceivedActionsApiArg = {
  skip?: number;
  take?: number;
};
export type PostProductsReceivedActionsActionApiResponse = unknown;
export type PostProductsReceivedActionsActionApiArg = {
  /** Идентификатор ячейки с товаром */
  receivedProductCellId?: number;
  /** Идентификатор типа действия */
  actionTypeId?: number;
};
export type PostAdminEmpoloyeeApiResponse =
  /** status 200 Success */ DifEmployee;
export type PostAdminEmpoloyeeApiArg = {
  createEmployeeModel: CreateEmployeeModel;
};
export type GetAdminEmployeesApiResponse =
  /** status 200 Success */ EmployeeOuttputModel[];
export type GetAdminEmployeesApiArg = {
  collectionFilter: CollectionFilter;
};
export type GetAdminEmployeesPaymentsApiResponse =
  /** status 200 Success */ DifEmployeePaymentsHistory[];
export type GetAdminEmployeesPaymentsApiArg = {
  collectionFilter: CollectionFilter;
};
export type GetAnalyticsRemoveDataApiResponse = unknown;
export type GetAnalyticsRemoveDataApiArg = void;
export type GetAnalyticsGenProcessesDataApiResponse = unknown;
export type GetAnalyticsGenProcessesDataApiArg = {
  length?: number;
};
export type GetAnalyticsGenPRoductsDataApiResponse = unknown;
export type GetAnalyticsGenPRoductsDataApiArg = {
  length?: number;
};
export type GetAnalyticsGenDataApiResponse = unknown;
export type GetAnalyticsGenDataApiArg = void;
export type GetAnalyticsMoneyDateApiResponse =
  /** status 200 Success */ AnalyticsMoneyDateData;
export type GetAnalyticsMoneyDateApiArg = void;
export type GetAnalyticsMoneyApiResponse =
  /** status 200 Success */ AnalyticsMoneyData;
export type GetAnalyticsMoneyApiArg = void;
export type GetAnalyticsProductsApiResponse =
  /** status 200 Success */ AnalyticsProductData;
export type GetAnalyticsProductsApiArg = void;
export type PostAnalyticsAddVisitApiResponse = unknown;
export type PostAnalyticsAddVisitApiArg = {
  visitInputModel: VisitInputModel;
};
export type GetAnalyticsAddRecallRequestApiResponse = unknown;
export type GetAnalyticsAddRecallRequestApiArg = {
  userId?: string;
  sessionId?: string;
  name?: string;
  number?: string;
  email?: string;
  telegram?: string;
};
export type PostAuthLoginApiResponse =
  /** status 200 Success */ AuthOutputModel;
export type PostAuthLoginApiArg = {
  /** Данные для входа */
  authCredentials: AuthCredentials;
};
export type PostAuthRegistrationApiResponse =
  /** status 200 Success */ AuthOutputModel;
export type PostAuthRegistrationApiArg = {
  /** Данные для регистрации */
  authCredentials: AuthCredentials;
};
export type PostBillsExcelHonestSignsApiResponse =
  /** status 200 Success */ QrCode[];
export type PostBillsExcelHonestSignsApiArg = {
  body: {
    File?: Blob;
  };
};
export type GetBillsDateApiResponse =
  /** status 200 Success */ DateBillsCount[];
export type GetBillsDateApiArg = void;
export type GetBillsApiResponse =
  /** status 200 Success */ MiniBillOutputModel[];
export type GetBillsApiArg = {
  date?: string;
  warehouseId?: number;
  billType?: BillType;
  billStatus?: BillStatus;
};
export type DeleteBillsApiResponse = unknown;
export type DeleteBillsApiArg = {
  /** Идентификатор накладной */
  id?: number;
};
export type GetBillsGetAcceptanceBillsByProductBarcodeApiResponse =
  /** status 200 Success */ DifBill[];
export type GetBillsGetAcceptanceBillsByProductBarcodeApiArg = {
  barcode?: string;
};
export type GetBillsGetShipmentBillsByProductBarcodeApiResponse =
  /** status 200 Success */ DifBill[];
export type GetBillsGetShipmentBillsByProductBarcodeApiArg = {
  barcode?: string;
};
export type GetBillsByIdApiResponse = /** status 200 Success */ DifBill;
export type GetBillsByIdApiArg = {
  id: number;
};
export type GetBillsPackageBillApiResponse =
  /** status 200 Success */ DifPackageBill;
export type GetBillsPackageBillApiArg = {
  billId?: number;
};
export type PostBillsPackageBillApiResponse = unknown;
export type PostBillsPackageBillApiArg = {
  /** Модель создания накладной */
  redactPackageBillModel: RedactPackageBillModel;
};
export type GetBillsPlacementBillApiResponse =
  /** status 200 Success */ DifPlacementBill;
export type GetBillsPlacementBillApiArg = {
  billId?: number;
};
export type PostBillsPlacementBillApiResponse = unknown;
export type PostBillsPlacementBillApiArg = {
  /** Модель создания накладной */
  redactPlacementBillModel: RedactPlacementBillModel;
};
export type PostBillsRedactPlacementBillApiResponse = unknown;
export type PostBillsRedactPlacementBillApiArg = {
  /** Модель создания накладной */
  redactPlacementBillModel: RedactPlacementBillModel;
};
export type PostBillsRedactPackageBillApiResponse = unknown;
export type PostBillsRedactPackageBillApiArg = {
  /** Модель создания накладной */
  redactPackageBillModel: RedactPackageBillModel;
};
export type PostBillsBillApiResponse =
  /** status 200 Success */ BillOutputModel;
export type PostBillsBillApiArg = {
  /** Модель создания накладной */
  redactBillModel: RedactBillModel;
};
export type GetBillsByIdDetailsApiResponse =
  /** status 200 Success */ ProductDetail[];
export type GetBillsByIdDetailsApiArg = {
  id: number;
};
export type PostBillsRedactBillApiResponse =
  /** status 200 Success */ BillOutputModel;
export type PostBillsRedactBillApiArg = {
  redactBillModel: RedactBillModel;
};
export type PostBillsFileExcelApiResponse =
  /** status 200 Success */ BillOutputModel;
export type PostBillsFileExcelApiArg = {
  body: {
    /** Идентификатор склада */
    WarehouseId?: number;
    ClientId?: number;
    /** Файл */
    File?: Blob;
  };
};
export type PostBillsEmployeeFileExcelApiResponse =
  /** status 200 Success */ BillOutputModel;
export type PostBillsEmployeeFileExcelApiArg = {
  body: {
    /** Идентификатор склада */
    WarehouseId?: number;
    ClientId?: number;
    /** Файл */
    File?: Blob;
  };
};
export type PutBillsStatusApiResponse = unknown;
export type PutBillsStatusApiArg = {
  /** Идентификатор налкдной */
  billId?: number;
  /** Новый статус */
  status?: BillStatus;
};
export type GetCellsApiResponse = /** status 200 Success */ CellModel[];
export type GetCellsApiArg = {
  cellType?: CellTypeEnum;
  warehouseId?: number;
};
export type PostCellsApiResponse = unknown;
export type PostCellsApiArg = {
  /** Модель создания ячейки */
  createCellRequest: CreateCellRequest;
};
export type GetCellsByIdApiResponse = /** status 200 Success */ DifCell;
export type GetCellsByIdApiArg = {
  /** Идентификатор ячейки */
  id: number;
};
export type GetClientsCooperationRequestsApiResponse =
  /** status 200 Success */ DifClientWarehouseOrganizationCooperationRequest[];
export type GetClientsCooperationRequestsApiArg = void;
export type GetClientsCooperationsApiResponse =
  /** status 200 Success */ DifClientWarehouseOrganizationCooperation[];
export type GetClientsCooperationsApiArg = void;
export type PostClientsCreateCooperationApiResponse = unknown;
export type PostClientsCreateCooperationApiArg = {
  cooperationRequestId?: number;
};
export type DeleteClientsDeleteCooperationApiResponse = unknown;
export type DeleteClientsDeleteCooperationApiArg = {
  cooperationId?: number;
};
export type PostClientsCreateCooperationRequestApiResponse = unknown;
export type PostClientsCreateCooperationRequestApiArg = {
  warehouseOrganizationId?: number;
  organizationId?: number;
};
export type DeleteClientsDeleteCooperationRequestApiResponse = unknown;
export type DeleteClientsDeleteCooperationRequestApiArg = {
  cooperationRequestId?: number;
};
export type GetConsumablesApiResponse =
  /** status 200 Success */ DifConsumables[];
export type GetConsumablesApiArg = void;
export type PostConsumablesApiResponse = unknown;
export type PostConsumablesApiArg = {
  /** Модель создания расходника */
  createConsumablesModel: CreateConsumablesModel;
};
export type GetConsumablesSpendingApiResponse =
  /** status 200 Success */ DifConsumablesProduct[];
export type GetConsumablesSpendingApiArg = {
  /** Фильтр */
  consumablesFilter: ConsumablesFilter;
};
export type GetConsumablesSpendingActualApiResponse =
  /** status 200 Success */ DifConsumablesProduct[];
export type GetConsumablesSpendingActualApiArg = {
  /** Фильтр по товарам и расходникам */
  body: ConsumablesProductsFilter[];
};
export type PutConsumablesByIdSpentQuantityApiResponse = unknown;
export type PutConsumablesByIdSpentQuantityApiArg = {
  id: number;
  spentQuantity?: number;
};
export type PutConsumablesByIdQuantityApiResponse = unknown;
export type PutConsumablesByIdQuantityApiArg = {
  id: number;
  quantity?: number;
};
export type PostDicAddVisitApiResponse = unknown;
export type PostDicAddVisitApiArg = {
  dicVisitInputModel: DicVisitInputModel;
};
export type GetDicAddRecallRequestApiResponse = unknown;
export type GetDicAddRecallRequestApiArg = {
  productId?: number;
  userId?: string;
  sessionId?: string;
  name?: string;
  number?: string;
  email?: string;
  telegram?: string;
};
export type GetDocumentsApiResponse = /** status 200 Success */ DifDocument[];
export type GetDocumentsApiArg = void;
export type GetDocumentsDocxBillsApiResponse = unknown;
export type GetDocumentsDocxBillsApiArg = {
  /** Идентификатор накладной */
  billId?: number;
  /** Идентификатор пользователя */
  userId?: number;
};
export type PostDocumentsExcelBillsApiResponse =
  /** status 200 Success */ DocumentOutput;
export type PostDocumentsExcelBillsApiArg = {
  /** Фильтр */
  billsFilter: BillsFilter;
};
export type PostDocumentsTemplateApiResponse = /** status 200 Success */ string;
export type PostDocumentsTemplateApiArg = {
  body: {
    file?: Blob;
  };
};
export type GetEmployeesCooperationRequestsApiResponse =
  /** status 200 Success */ DifEmployeeWarehouseOrganizationCooperationRequest[];
export type GetEmployeesCooperationRequestsApiArg = void;
export type GetEmployeesCooperationsApiResponse =
  /** status 200 Success */ DifEmployeeWarehouseOrganizationCooperation[];
export type GetEmployeesCooperationsApiArg = void;
export type PostEmployeesCreateCooperationApiResponse = unknown;
export type PostEmployeesCreateCooperationApiArg = {
  cooperationRequestId?: number;
};
export type DeleteEmployeesDeleteCooperationApiResponse = unknown;
export type DeleteEmployeesDeleteCooperationApiArg = {
  cooperationId?: number;
};
export type PostEmployeesCreateCooperationRequestApiResponse = unknown;
export type PostEmployeesCreateCooperationRequestApiArg = {
  organizationId?: number;
};
export type DeleteEmployeesDeleteCooperationRequestApiResponse = unknown;
export type DeleteEmployeesDeleteCooperationRequestApiArg = {
  cooperationRequestId?: number;
};
export type GetEventsApiResponse = /** status 200 Success */ DifEvent[];
export type GetEventsApiArg = void;
export type PostEventsApiResponse = unknown;
export type PostEventsApiArg = {
  title?: string;
  description?: string;
  userId?: number;
};
export type PostFilesUploadApiResponse =
  /** status 200 Success */ CreateFileResponse;
export type PostFilesUploadApiArg = {
  body: {
    file?: Blob;
  };
};
export type GetProductsInventorizeClientsApiResponse =
  /** status 200 Success */ ClientInventorizationModel[];
export type GetProductsInventorizeClientsApiArg = void;
export type PostIntegrationMoySkladProductApiResponse = unknown;
export type PostIntegrationMoySkladProductApiArg = {
  productId?: number;
};
export type PostIntegrationMoySkladDataApiResponse = unknown;
export type PostIntegrationMoySkladDataApiArg = {
  integrationDataModel: IntegrationDataModel;
};
export type GetNewsApiResponse = /** status 200 Success */ DifNew[];
export type GetNewsApiArg = void;
export type PostNewsApiResponse = unknown;
export type PostNewsApiArg = {
  title?: string;
  description?: string;
};
export type PostNotificationsSeenApiResponse =
  /** status 200 Success */ boolean;
export type PostNotificationsSeenApiArg = {
  notificationSeenModelInput: NotificationSeenModelInput;
};
export type GetNotificationsApiResponse =
  /** status 200 Success */ DifNotification[];
export type GetNotificationsApiArg = void;
export type PostNotificationsNewApiResponse = /** status 200 Success */ boolean;
export type PostNotificationsNewApiArg = {
  userId?: number;
  text?: string;
};
export type GetOrganizationsApiResponse =
  /** status 200 Success */ DifOrganization[];
export type GetOrganizationsApiArg = void;
export type PostPackageCompleteApiResponse = unknown;
export type PostPackageCompleteApiArg = {
  completePackageInputModel: CompletePackageInputModel;
};
export type GetProcessesPackageSetEmployeeInProcessAndGetEmployeesInProcessApiResponse =
  /** status 200 Success */ ProcessRealTimeEmployeeOutputModel[];
export type GetProcessesPackageSetEmployeeInProcessAndGetEmployeesInProcessApiArg =
  {
    processId?: number;
  };
export type PostProcessesPackageRedactProcessEmployeeApiResponse = unknown;
export type PostProcessesPackageRedactProcessEmployeeApiArg = {
  redactProcessEmployeeModel: RedactProcessEmployeeModel;
};
export type PostProcessesPackageAddProcessApiResponse =
  /** status 200 Success */ number;
export type PostProcessesPackageAddProcessApiArg = {
  billId?: number;
  cellId?: number;
};
export type GetProcessesPackageProcessModelApiResponse =
  /** status 200 Success */ ProcessModel;
export type GetProcessesPackageProcessModelApiArg = {
  processId?: number;
};
export type PostProcessesPackageCompleteApiResponse =
  /** status 200 Success */ boolean;
export type PostProcessesPackageCompleteApiArg = {
  inspectionCompleteModel: InspectionCompleteModel;
};
export type PostPaymentsGenApiResponse = unknown;
export type PostPaymentsGenApiArg = void;
export type GetPaymentsBillApiResponse =
  /** status 200 Success */ DifPaymentBill;
export type GetPaymentsBillApiArg = {
  billId?: number;
};
export type GetPaymentsBillsApiResponse =
  /** status 200 Success */ DifPaymentBill[];
export type GetPaymentsBillsApiArg = void;
export type PostPaymentsApiResponse = unknown;
export type PostPaymentsApiArg = {
  createPaymentBillModel: CreatePaymentBillModel;
};
export type PostPlacementCompleteApiResponse = unknown;
export type PostPlacementCompleteApiArg = {
  placementBillInputModel: PlacementBillInputModel;
};
export type GetProcessesPlacementSetEmployeeInProcessAndGetEmployeesInProcessApiResponse =
  /** status 200 Success */ ProcessRealTimeEmployeeOutputModel[];
export type GetProcessesPlacementSetEmployeeInProcessAndGetEmployeesInProcessApiArg =
  {
    processId?: number;
  };
export type PostProcessesPlacementRedactProcessEmployeeApiResponse = unknown;
export type PostProcessesPlacementRedactProcessEmployeeApiArg = {
  redactProcessEmployeeModel: RedactProcessEmployeeModel;
};
export type PostProcessesPlacementAddProcessApiResponse =
  /** status 200 Success */ number;
export type PostProcessesPlacementAddProcessApiArg = {
  billId?: number;
};
export type GetProcessesPlacementProcessModelApiResponse =
  /** status 200 Success */ PlacementProcessModel;
export type GetProcessesPlacementProcessModelApiArg = {
  processId?: number;
};
export type PostProcessesPlacementCompleteApiResponse =
  /** status 200 Success */ boolean;
export type PostProcessesPlacementCompleteApiArg = {
  inspectionCompleteModel: InspectionCompleteModel;
};
export type GetProcessesSetEmployeeInProcessAndGetEmployeesInProcessApiResponse =
  /** status 200 Success */ ProcessRealTimeEmployeeOutputModel[];
export type GetProcessesSetEmployeeInProcessAndGetEmployeesInProcessApiArg = {
  processId?: number;
};
export type PostProcessesRedactProcessEmployeeApiResponse = unknown;
export type PostProcessesRedactProcessEmployeeApiArg = {
  redactProcessEmployeeModel: RedactProcessEmployeeModel;
};
export type PostProcessesAddProcessApiResponse =
  /** status 200 Success */ number;
export type PostProcessesAddProcessApiArg = {
  billId?: number;
  cellId?: number;
};
export type GetProcessesGetProcessBillByIdApiResponse =
  /** status 200 Success */ DifProcessBill;
export type GetProcessesGetProcessBillByIdApiArg = {
  processBillId?: number;
};
export type GetProcessesApiResponse =
  /** status 200 Success */ MiniProcessModel[];
export type GetProcessesApiArg = void;
export type GetProcessesInspectionProcessModelApiResponse =
  /** status 200 Success */ ProcessModel;
export type GetProcessesInspectionProcessModelApiArg = {
  processId?: number;
};
export type PostProcessesInspectionCompleteApiResponse =
  /** status 200 Success */ boolean;
export type PostProcessesInspectionCompleteApiArg = {
  inspectionCompleteModel: InspectionCompleteModel;
};
export type PostProductsCellsShipApiResponse = unknown;
export type PostProductsCellsShipApiArg = {
  /** Модель отгрузки */
  shipmentModel: ShipmentModel;
};
export type PostProductsExcelApiResponse =
  /** status 200 Success */ DocumentOutput;
export type PostProductsExcelApiArg = void;
export type GetProductsApiResponse =
  /** status 200 Success */ ProductOutputModel[];
export type GetProductsApiArg = {
  notNullQuantity?: boolean;
  clientId?: number;
  warehouseId?: number;
  organizationId?: number;
  cellId?: number;
  warehouseIdForCellsQuantity?: number;
  isNotPackage?: boolean;
  isNotBookedPackage?: boolean;
  isNotBookedShip?: boolean;
  isAvailableForPackage?: boolean;
  isAvailableForTransit?: boolean;
  redactPlacementBillId?: number;
  redactPackageBillId?: number;
};
export type PostProductsApiResponse = /** status 200 Success */ number;
export type PostProductsApiArg = {
  /** Модель создания товара */
  productCreateModel: ProductCreateModel;
};
export type DeleteProductsApiResponse = unknown;
export type DeleteProductsApiArg = {
  /** Идентификтаор товара */
  id?: number;
};
export type GetProductsByIdApiResponse = /** status 200 Success */ DifProduct;
export type GetProductsByIdApiArg = {
  id: number;
};
export type GetProductsByIdDetailsApiResponse =
  /** status 200 Success */ ProductDetail[];
export type GetProductsByIdDetailsApiArg = {
  id: number;
  warehouseId?: number;
  isAvailableForTransit?: boolean;
  redactPlacementBillId?: number;
};
export type GetProductsByProductIdCellsApiResponse =
  /** status 200 Success */ DifCell[];
export type GetProductsByProductIdCellsApiArg = {
  productId: number;
};
export type DeleteProductsImageApiResponse = unknown;
export type DeleteProductsImageApiArg = {
  deleteProductImageInput: DeleteProductImageInput;
};
export type PostProductsImageApiResponse = unknown;
export type PostProductsImageApiArg = {
  body: {
    File?: Blob;
    ProductId?: number;
  };
};
export type PostProductsKitApiResponse = /** status 200 Success */ KitOutput;
export type PostProductsKitApiArg = {
  /** Модель создания товара */
  createKitModel: CreateKitModel;
};
export type PostProductsRedactApiResponse = unknown;
export type PostProductsRedactApiArg = {
  /** Модель создания товара */
  productRedactModel: ProductRedactModel;
};
export type GetUsersUserOrganizationsApiResponse =
  /** status 200 Success */ OrganizationOutputModel[];
export type GetUsersUserOrganizationsApiArg = void;
export type GetUsersMeApiResponse = /** status 200 Success */ MeOutput;
export type GetUsersMeApiArg = void;
export type PutUsersMeApiResponse = /** status 200 Success */ DifUser;
export type PutUsersMeApiArg = {
  updateUserProfileModel: UpdateUserProfileModel;
};
export type GetUsersProfileApiResponse = /** status 200 Success */ DifUser;
export type GetUsersProfileApiArg = void;
export type PutUsersProfileImageApiResponse = /** status 200 Success */ boolean;
export type PutUsersProfileImageApiArg = {
  body: {
    image?: Blob;
  };
};
export type PutUsersSettingsApiResponse = unknown;
export type PutUsersSettingsApiArg = {
  settingsModel: SettingsModel;
};
export type GetUsersSettingsApiResponse =
  /** status 200 Success */ DifUserSettings;
export type GetUsersSettingsApiArg = void;
export type GetUsersEmployeesApiResponse =
  /** status 200 Success */ DifEmployee[];
export type GetUsersEmployeesApiArg = {
  warehouseId?: number;
};
export type GetWarehouseByIdSchemeApiResponse =
  /** status 200 Success */ DifWarehouseScheme;
export type GetWarehouseByIdSchemeApiArg = {
  id: number;
};
export type PutWarehouseSchemesBySchemeIdApiResponse = unknown;
export type PutWarehouseSchemesBySchemeIdApiArg = {
  schemeId: number;
  updateWarehouseSchemeInputModel: UpdateWarehouseSchemeInputModel;
};
export type DeleteWarehouseSchemesBySchemeIdApiResponse = unknown;
export type DeleteWarehouseSchemesBySchemeIdApiArg = {
  schemeId: number;
};
export type PostWarehouseByWarehouseIdCreateSchemeApiResponse =
  /** status 200 Success */ number;
export type PostWarehouseByWarehouseIdCreateSchemeApiArg = {
  warehouseId: number;
  schemeName?: string;
  length?: number;
  width?: number;
  height?: number;
  body: DrowItemType[];
};
export type GetWarehouseByIdConsumablesApiResponse =
  /** status 200 Success */ DifConsumables[];
export type GetWarehouseByIdConsumablesApiArg = {
  id: number;
};
export type GetWarehouseClientsCooperationRequestsApiResponse =
  /** status 200 Success */ DifClientWarehouseOrganizationCooperationRequest[];
export type GetWarehouseClientsCooperationRequestsApiArg = void;
export type GetWarehouseClientsCooperationsApiResponse =
  /** status 200 Success */ DifClientWarehouseOrganizationCooperation[];
export type GetWarehouseClientsCooperationsApiArg = void;
export type PostWarehouseClientsCreateCooperationApiResponse = unknown;
export type PostWarehouseClientsCreateCooperationApiArg = {
  cooperationRequestId?: number;
};
export type DeleteWarehouseClientsDeleteCooperationApiResponse = unknown;
export type DeleteWarehouseClientsDeleteCooperationApiArg = {
  cooperationId?: number;
};
export type PostWarehouseClientsCreateCooperationRequestApiResponse = unknown;
export type PostWarehouseClientsCreateCooperationRequestApiArg = {
  clientId?: number;
  organizationId?: number;
};
export type DeleteWarehouseClientsDeleteCooperationRequestApiResponse = unknown;
export type DeleteWarehouseClientsDeleteCooperationRequestApiArg = {
  cooperationRequestId?: number;
};
export type DeleteWarehouseImageApiResponse = unknown;
export type DeleteWarehouseImageApiArg = {
  deleteProductImageInput: DeleteProductImageInput;
};
export type PostWarehouseImageApiResponse = unknown;
export type PostWarehouseImageApiArg = {
  body: {
    File?: Blob;
    WarehouseId?: number;
  };
};
export type PostWarehouseCreateApiResponse = /** status 200 Success */ number;
export type PostWarehouseCreateApiArg = {
  createWarehouseInputModel: CreateWarehouseInputModel;
};
export type PostWarehouseCreateOrganizationApiResponse =
  /** status 200 Success */ boolean;
export type PostWarehouseCreateOrganizationApiArg = {
  organizationName?: string;
};
export type GetWarehouseOrganizationsByUserApiResponse =
  /** status 200 Success */ DifWarehouseOrganization[];
export type GetWarehouseOrganizationsByUserApiArg = void;
export type GetWarehouseListByUserApiResponse =
  /** status 200 Success */ DifWarehouse[];
export type GetWarehouseListByUserApiArg = void;
export type GetWarehouseApiResponse = /** status 200 Success */ DifWarehouse[];
export type GetWarehouseApiArg = {
  skip?: number;
  take?: number;
};
export type GetWarehouseByIdApiResponse =
  /** status 200 Success */ DifWarehouse;
export type GetWarehouseByIdApiArg = {
  /** Мдентификатор склада */
  id: number;
};
export type GetWarehouseEmployeeCooperationRequestsApiResponse =
  /** status 200 Success */ DifEmployeeWarehouseOrganizationCooperationRequest[];
export type GetWarehouseEmployeeCooperationRequestsApiArg = void;
export type GetWarehouseEmployeeCooperationsApiResponse =
  /** status 200 Success */ DifEmployeeWarehouseOrganizationCooperation[];
export type GetWarehouseEmployeeCooperationsApiArg = void;
export type PostWarehouseEmployeeCreateCooperationApiResponse = unknown;
export type PostWarehouseEmployeeCreateCooperationApiArg = {
  cooperationRequestId?: number;
};
export type DeleteWarehouseEmployeeDeleteCooperationApiResponse = unknown;
export type DeleteWarehouseEmployeeDeleteCooperationApiArg = {
  cooperationId?: number;
};
export type PostWarehouseEmployeeCreateCooperationRequestApiResponse = unknown;
export type PostWarehouseEmployeeCreateCooperationRequestApiArg = {
  employeeId?: number;
  organizationId?: number;
};
export type DeleteWarehouseEmployeeDeleteCooperationRequestApiResponse =
  unknown;
export type DeleteWarehouseEmployeeDeleteCooperationRequestApiArg = {
  cooperationRequestId?: number;
};
export type GetWarehouseOrganizationsApiResponse =
  /** status 200 Success */ DifWarehouseOrganization[];
export type GetWarehouseOrganizationsApiArg = void;
export type GetWarehouseOrganizationsByIdEmployeesApiResponse =
  /** status 200 Success */ DifEmployee[];
export type GetWarehouseOrganizationsByIdEmployeesApiArg = {
  id: number;
};
export type GetWarehouseOrganizationsByIdWarehousesApiResponse =
  /** status 200 Success */ DifWarehouse[];
export type GetWarehouseOrganizationsByIdWarehousesApiArg = {
  id: number;
};
export type DifActionType = {
  id?: number;
  objectCreateDate?: string;
  type?: string | null;
  productActions?: DifProductCellAction[] | null;
};
export type DifEmployeeType = {
  id?: number;
  objectCreateDate?: string;
  type?: string | null;
  employees?: DifEmployee[] | null;
};
export type EmployeeAccessLevelEnum = 1 | 2 | 3 | 4;
export type GenderEnum = 0 | 1;
export type ProductTypeEnum = 0 | 1;
export type DifClient = {
  id?: number;
  objectCreateDate?: string;
  userId?: number;
  user?: DifUser;
  organizations?: DifOrganization[] | null;
};
export type DifWarehouseOwner = {
  id?: number;
  objectCreateDate?: string;
  userId?: number;
  user?: DifUser;
  organizations?: DifWarehouseOrganization[] | null;
};
export type DifImageWarehouseLink = {
  id?: number;
  objectCreateDate?: string;
  imageId?: number;
  image?: DifImage;
  warehouseId?: number;
  warehouse?: DifWarehouse;
};
export type DifWarehouseSchemeDrowItemFloor = {
  id?: number;
  objectCreateDate?: string;
  name: string;
  height: number;
  drowItemId: number;
  drowItem: DifWarehouseSchemeDrowItem;
};
export type DrowItemTypes = 0 | 1 | 2 | 3 | 4 | 5;
export type DifWarehouseSchemeDrowItem = {
  id?: number;
  objectCreateDate?: string;
  name: string;
  color: string;
  width: number;
  length: number;
  height: number;
  x: number;
  y: number;
  rotation: number;
  isSelected: boolean;
  schemeId: number;
  scheme?: DifWarehouseScheme;
  floors?: DifWarehouseSchemeDrowItemFloor[] | null;
  type: DrowItemTypes;
};
export type DifWarehouseScheme = {
  id?: number;
  objectCreateDate?: string;
  name: string;
  length: number;
  width: number;
  height: number;
  warehouseId: number;
  warehouse?: DifWarehouse;
  drowItems?: DifWarehouseSchemeDrowItem[] | null;
};
export type DifKitProduct = {
  id?: number;
  objectCreateDate?: string;
  parendId?: number;
  parent?: DifProduct;
  childId?: number;
  child?: DifProduct;
  quantity?: number;
  kitConsumables?: DifKitConsumable[] | null;
};
export type DifKitConsumable = {
  id?: number;
  objectCreateDate?: string;
  quantity?: number;
  consumableId?: number;
  consumable?: DifConsumables;
  kitProductId?: number;
  kitProduct?: DifKitProduct;
};
export type DifProductConsumable = {
  id?: number;
  objectCreateDate?: string;
  quantity?: number;
  consumableId?: number;
  consumable?: DifConsumables;
  productId?: number;
  product?: DifProduct;
};
export type CellTypeEnum = 0 | 1 | 2 | 3 | 4;
export type StoragePlaceStatus = 0 | 1;
export type DifProductCellStage = {
  id?: number;
  objectCreateDate?: string;
  stage?: string | null;
  cells?: DifCell[] | null;
};
export type BillStatus = 0 | 1 | 2 | 3 | 4;
export type DifProcessPlacementEmployee = {
  id?: number;
  objectCreateDate?: string;
  processBillProductId?: number;
  processBillProduct?: DifProcessPlacementBillProduct;
  employeeId?: number;
  employee?: DifEmployee;
  quantity?: number;
};
export type DifProcessPlacementBillProduct = {
  id?: number;
  objectCreateDate?: string;
  processBillId?: number;
  processBill?: DifProcessPlacementBill;
  productBillId?: number;
  productBill?: DifPlacementBillProduct;
  processEmployees?: DifProcessPlacementEmployee[] | null;
};
export type DifProcessPlacementRealTimeEmployee = {
  id?: number;
  objectCreateDate?: string;
  processBillId?: number;
  processBill?: DifProcessPlacementBill;
  employeeId?: number;
  employee?: DifEmployee;
};
export type DifProcessPlacementBill = {
  id?: number;
  objectCreateDate?: string;
  billId?: number;
  bill?: DifPlacementBill;
  dateEnd?: string | null;
  createdByEmployeeId?: number;
  createdByEmployee?: DifEmployee;
  processBillProducts?: DifProcessPlacementBillProduct[] | null;
  processRealTimeEmployees?: DifProcessPlacementRealTimeEmployee[] | null;
};
export type DifPlacementBill = {
  id?: number;
  objectCreateDate?: string;
  note?: string | null;
  realizationDate?: string | null;
  actualRealizationDate?: string | null;
  status?: BillStatus;
  billProducts?: DifPlacementBillProduct[] | null;
  warehouseId?: number;
  warehouse?: DifWarehouse;
  processPlacementBill?: DifProcessPlacementBill;
};
export type HonestSignStatusEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type DifProcessEmployee = {
  id?: number;
  objectCreateDate?: string;
  processBillProductId?: number;
  processBillProduct?: DifProcessBillProduct;
  employeeId?: number;
  employee?: DifEmployee;
  quantity?: number;
};
export type DifProcessBillProduct = {
  id?: number;
  objectCreateDate?: string;
  processBillId?: number;
  processBill?: DifProcessBill;
  productBillId?: number;
  productBill?: DifProductBill;
  processEmployees?: DifProcessEmployee[] | null;
};
export type DifProcessRealTimeEmployee = {
  id?: number;
  objectCreateDate?: string;
  processBillId?: number;
  processBill?: DifProcessBill;
  employeeId?: number;
  employee?: DifEmployee;
};
export type DifProcessBill = {
  id?: number;
  objectCreateDate?: string;
  billId?: number;
  bill?: DifBill;
  dateEnd?: string | null;
  createdByEmployeeId?: number;
  createdByEmployee?: DifEmployee;
  cellId?: number;
  processBillProducts?: DifProcessBillProduct[] | null;
  processRealTimeEmployees?: DifProcessRealTimeEmployee[] | null;
};
export type DifCar = {
  id?: number;
  objectCreateDate?: string;
  note?: string | null;
  bills?: DifBill[] | null;
};
export type BillType = 0 | 1 | 2 | 3 | 4;
export type DifBill = {
  id?: number;
  objectCreateDate?: string;
  transporter?: string | null;
  deliveryNote?: string | null;
  externalBillId?: string | null;
  sender?: string | null;
  boxexCount?: number | null;
  palletsCount?: number | null;
  productsBills?: DifProductBill[] | null;
  processBill?: DifProcessBill;
  organizationId?: number;
  organization?: DifOrganization;
  carId?: number | null;
  car?: DifCar;
  note?: string | null;
  warehouseId?: number;
  warehouse?: DifWarehouse;
  address?: string | null;
  realizationDate?: string | null;
  actualRealizationDate?: string | null;
  status?: BillStatus;
  type?: BillType;
  number?: number;
};
export type DifProductBill = {
  id?: number;
  objectCreateDate?: string;
  quantity?: number;
  actualQuantity?: number;
  packageTypeId?: number | null;
  packageType?: DifPackageType;
  productId?: number;
  product?: DifProduct;
  billId?: number;
  bill?: DifBill;
  processBillProducts?: DifProcessBillProduct[] | null;
  honestSigns?: DifHonestSign[] | null;
};
export type DifProcessPackageEmployee = {
  id?: number;
  objectCreateDate?: string;
  processBillProductId?: number;
  processBillProduct?: DifProcessPackageBillProduct;
  employeeId?: number;
  employee?: DifEmployee;
  quantity?: number;
};
export type DifProcessPackageBillProduct = {
  id?: number;
  objectCreateDate?: string;
  processBillId?: number;
  processBill?: DifProcessPackageBill;
  productBillId?: number;
  productBill?: DifPackageBillProduct;
  processEmployees?: DifProcessPackageEmployee[] | null;
};
export type DifProcessPackageRealTimeEmployee = {
  id?: number;
  objectCreateDate?: string;
  processBillId?: number;
  processBill?: DifProcessPackageBill;
  employeeId?: number;
  employee?: DifEmployee;
};
export type DifProcessPackageBill = {
  id?: number;
  objectCreateDate?: string;
  billId?: number;
  bill?: DifPackageBill;
  dateEnd?: string | null;
  createdByEmployeeId?: number;
  createdByEmployee?: DifEmployee;
  cellId?: number;
  processBillProducts?: DifProcessPackageBillProduct[] | null;
  processRealTimeEmployees?: DifProcessPackageRealTimeEmployee[] | null;
};
export type DifPackageBill = {
  id?: number;
  objectCreateDate?: string;
  externalBillId?: string | null;
  organizationId?: number;
  organization?: DifOrganization;
  processPackageBill?: DifProcessPackageBill;
  productsBills?: DifPackageBillProduct[] | null;
  note?: string | null;
  realizationDate?: string | null;
  actualRealizationDate?: string | null;
  status?: BillStatus;
  warehouseId?: number;
  warehouse?: DifWarehouse;
};
export type DifPackageBillProduct = {
  id?: number;
  objectCreateDate?: string;
  packageTypeId?: number;
  packageType?: DifPackageType;
  quantity?: number;
  actualQuantity?: number;
  productId?: number;
  product?: DifProduct;
  billId?: number;
  bill?: DifPackageBill;
  processBillProducts?: DifProcessPackageBillProduct[] | null;
  honestSigns?: DifHonestSign[] | null;
};
export type DifHonestSign = {
  id?: number;
  objectCreateDate?: string;
  status?: HonestSignStatusEnum;
  qrCode?: string | null;
  productCellId?: number | null;
  productCell?: DifProductCell;
  productBillId?: number | null;
  productBill?: DifProductBill;
  packageBillProductId?: number | null;
  packageBillProduct?: DifPackageBillProduct;
  placementBillProductId?: number | null;
  placementBillProduct?: DifPlacementBillProduct;
};
export type DifPlacementBillProduct = {
  id?: number;
  objectCreateDate?: string;
  placementBillId?: number;
  placementBill?: DifPlacementBill;
  productCellId?: number;
  productCell?: DifProductCell;
  toCellId?: number;
  toCell?: DifCell;
  quantity?: number;
  processBillProducts?: DifProcessPlacementBillProduct[] | null;
  honestSigns?: DifHonestSign[] | null;
};
export type DifCell = {
  id?: number;
  objectCreateDate?: string;
  cellType?: CellTypeEnum;
  width?: number;
  height?: number;
  length?: number;
  capacity?: number;
  place?: string | null;
  name?: string | null;
  description?: string | null;
  status?: StoragePlaceStatus;
  warehouseId?: number;
  warehouse?: DifWarehouse;
  productCells?: DifProductCell[] | null;
  stageId?: number;
  cellStage?: DifProductCellStage;
  placementBillProducts?: DifPlacementBillProduct[] | null;
};
export type DifBasket = {
  id?: number;
  objectCreateDate?: string;
  barcode?: string | null;
  cell?: string | null;
  productLinks?: DifBasketProductCellLink[] | null;
};
export type DifBasketProductCellLink = {
  id?: number;
  objectCreateDate?: string;
  productCellId?: number;
  productCell?: DifProductCell;
  basketId?: number;
  basket?: DifBasket;
};
export type DifProductCell = {
  id?: number;
  objectCreateDate?: string;
  packageTypeId?: number | null;
  packageType?: DifPackageType;
  cellId?: number;
  cell?: DifCell;
  productId?: number;
  product?: DifProduct;
  honestSigns?: DifHonestSign[] | null;
  quantity?: number;
  basketProducCelltLinks?: DifBasketProductCellLink[] | null;
  placementBillProducts?: DifPlacementBillProduct[] | null;
};
export type DifPackageType = {
  id?: number;
  objectCreateDate?: string;
  key?: string | null;
  productCells?: DifProductCell[] | null;
  productBills?: DifProductBill[] | null;
  packageConsumables?: DifPackageConsumable[] | null;
};
export type DifPackageConsumable = {
  id?: number;
  objectCreateDate?: string;
  packageTypeId?: number;
  packageType?: DifPackageType;
  consumableId?: number;
  consumable?: DifConsumables;
  quantity?: number;
};
export type DifConsumables = {
  id?: number;
  objectCreateDate?: string;
  name?: string | null;
  price?: number;
  measurementUnit?: string | null;
  quantity?: number;
  plannedQuantity?: number;
  spentQuantity?: number;
  warehouseId?: number;
  warehouse?: DifWarehouse;
  kitConsumables?: DifKitConsumable[] | null;
  productConsumables?: DifProductConsumable[] | null;
  packageConsumables?: DifPackageConsumable[] | null;
};
export type PaymentBillTypeDetailEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
export type PaymentType = 0 | 1;
export type PaymentStatusEnum = 0 | 1 | 2;
export type DifPaymentBillDetail = {
  id?: number;
  objectCreateDate?: string;
  paymentBillId?: number;
  paymentBill?: DifPaymentBill;
  name?: string | null;
  quantity?: string | null;
  paymentAmount?: number;
};
export type DifPaymentBill = {
  id?: number;
  objectCreateDate?: string;
  salaryForEmployeeId?: number | null;
  salaryForEmployee?: DifEmployee;
  detailType?: PaymentBillTypeDetailEnum;
  type?: PaymentType;
  organizationId?: number | null;
  organization?: DifOrganization;
  warehouseId?: number;
  warehouse?: DifWarehouse;
  createdByEmployeeId?: number;
  createdByEmployee?: DifEmployee;
  paymentStatus?: PaymentStatusEnum;
  note?: string | null;
  paymentBillDetails?: DifPaymentBillDetail[] | null;
};
export type DifUserSettings = {
  id?: number;
  objectCreateDate?: string;
  userId?: number;
  user?: DifUser;
  isTableStick?: boolean;
  organizationId?: number | null;
  organization?: DifOrganization;
  warehouseId?: number | null;
  warehouse?: DifWarehouse;
};
export type DifWarehouse = {
  id?: number;
  objectCreateDate?: string;
  imageLinks?: DifImageWarehouseLink[] | null;
  scheme?: DifWarehouseScheme;
  organizationId?: number;
  organization?: DifWarehouseOrganization;
  latitude?: string | null;
  longitude?: string | null;
  email?: string | null;
  telephone?: string | null;
  name?: string | null;
  description?: string | null;
  address?: string | null;
  country?: string | null;
  subject?: string | null;
  city?: string | null;
  street?: string | null;
  house?: string | null;
  consumables?: DifConsumables[] | null;
  employees?: DifEmployee[] | null;
  clients?: DifClient[] | null;
  cells?: DifCell[] | null;
  bills?: DifBill[] | null;
  placementBills?: DifPlacementBill[] | null;
  packageBills?: DifPackageBill[] | null;
  paymentBills?: DifPaymentBill[] | null;
  userSettings?: DifUserSettings[] | null;
};
export type ClientWarehouseCooperationRequestDirectionEnum = 0 | 1;
export type DifClientWarehouseOrganizationCooperationRequest = {
  id?: number;
  objectCreateDate?: string;
  clientId?: number;
  client?: DifOrganization;
  organizationId?: number;
  organization?: DifWarehouseOrganization;
  direction?: ClientWarehouseCooperationRequestDirectionEnum;
};
export type DifEmployeeWarehouseOrganizationCooperation = {
  id?: number;
  objectCreateDate?: string;
  employeeId?: number;
  employee?: DifEmployee;
  organizationId?: number;
  organization?: DifWarehouseOrganization;
};
export type EmployeeWarehouseCooperationRequestDirectionEnum = 0 | 1;
export type DifEmployeeWarehouseOrganizationCooperationRequest = {
  id?: number;
  objectCreateDate?: string;
  employeeId?: number;
  employee?: DifEmployee;
  organizationId?: number;
  organization?: DifWarehouseOrganization;
  direction?: EmployeeWarehouseCooperationRequestDirectionEnum;
};
export type DifWarehouseOrganization = {
  id?: number;
  objectCreateDate?: string;
  ownerId?: number;
  owner?: DifWarehouseOwner;
  name?: string | null;
  employees?: DifEmployee[] | null;
  warehouses?: DifWarehouse[] | null;
  clientCooperations?: DifClientWarehouseOrganizationCooperation[] | null;
  clientCooperationRequests?:
    | DifClientWarehouseOrganizationCooperationRequest[]
    | null;
  employeeCooperations?: DifEmployeeWarehouseOrganizationCooperation[] | null;
  employeeCooperationRequests?:
    | DifEmployeeWarehouseOrganizationCooperationRequest[]
    | null;
};
export type DifClientWarehouseOrganizationCooperation = {
  id?: number;
  objectCreateDate?: string;
  clientId?: number;
  client?: DifOrganization;
  organizationId?: number;
  organization?: DifWarehouseOrganization;
};
export type DifOrganization = {
  id?: number;
  objectCreateDate?: string;
  ownerId?: number;
  owner?: DifClient;
  name?: string | null;
  warehouseCooperations?: DifClientWarehouseOrganizationCooperation[] | null;
  warehouseCooperationRequests?:
    | DifClientWarehouseOrganizationCooperationRequest[]
    | null;
  clients?: DifClient[] | null;
  products?: DifProduct[] | null;
  bills?: DifBill[] | null;
  paymentBills?: DifPaymentBill[] | null;
  packageBills?: DifPackageBill[] | null;
  userSettings?: DifUserSettings[] | null;
};
export type DifProduct = {
  id?: number;
  objectCreateDate?: string;
  type?: ProductTypeEnum;
  organizationId?: number;
  organization?: DifOrganization;
  name?: string | null;
  factoryBarcode?: string | null;
  barcode?: string | null;
  article?: string | null;
  color?: string | null;
  weight?: number | null;
  capacity?: number;
  width?: number;
  height?: number;
  length?: number;
  externalId?: string | null;
  brand?: string | null;
  serviceProductPrice?: number | null;
  productCells?: DifProductCell[] | null;
  imageLinks?: DifImageProductLink[] | null;
  billProducts?: DifProductBill[] | null;
  packageBillProducts?: DifPackageBillProduct[] | null;
  childs?: DifKitProduct[] | null;
  parents?: DifKitProduct[] | null;
  productConsumables?: DifProductConsumable[] | null;
};
export type DifImageProductLink = {
  id?: number;
  objectCreateDate?: string;
  imageId?: number;
  image?: DifImage;
  productId?: number;
  product?: DifProduct;
};
export type DifImage = {
  id?: number;
  objectCreateDate?: string;
  name?: string | null;
  imageProductLinks?: DifImageProductLink[] | null;
  imageWarehouseLinks?: DifImageWarehouseLink[] | null;
  user?: DifUser;
};
export type DifNotification = {
  id?: number;
  objectCreateDate?: string;
  userId?: number;
  user?: DifUser;
  text?: string | null;
  isSeen?: boolean;
};
export type DifEvent = {
  id?: number;
  objectCreateDate?: string;
  userId?: number;
  user?: DifUser;
  title?: string | null;
  description?: string | null;
};
export type DocumentTypeEnum = 0 | 1 | 2 | 3 | 4 | 5;
export type DocumentExtensionEnum = 0 | 1 | 2;
export type DifDocument = {
  id?: number;
  objectCreateDate?: string;
  fileId?: number;
  file?: DifFile;
  documentType?: DocumentTypeEnum;
  documentExtension?: DocumentExtensionEnum;
};
export type DifFile = {
  id?: number;
  objectCreateDate?: string;
  userId?: number;
  user?: DifUser;
  fileName?: string | null;
  documents?: DifDocument[] | null;
};
export type DifUser = {
  id?: number;
  objectCreateDate?: string;
  name?: string | null;
  secondName?: string | null;
  gender?: GenderEnum;
  birtdayDate?: string | null;
  email?: string | null;
  imageId?: number | null;
  image?: DifImage;
  password?: string | null;
  telephone?: string | null;
  login?: string | null;
  otherContacts?: string | null;
  employee?: DifEmployee;
  client?: DifClient;
  warehouseOwner?: DifWarehouseOwner;
  settings?: DifUserSettings;
  notifications?: DifNotification[] | null;
  events?: DifEvent[] | null;
  files?: DifFile[] | null;
};
export type DifEmployee = {
  id?: number;
  objectCreateDate?: string;
  typeId?: number | null;
  type?: DifEmployeeType;
  accessLevel?: EmployeeAccessLevelEnum;
  userId?: number;
  user?: DifUser;
  workSchedule?: string | null;
  productActions?: DifProductCellAction[] | null;
  warehouseCooperations?: DifEmployeeWarehouseOrganizationCooperation[] | null;
  warehouseCooperationRequests?:
    | DifEmployeeWarehouseOrganizationCooperationRequest[]
    | null;
  processBills?: DifProcessBill[] | null;
  processPackageBills?: DifProcessPackageBill[] | null;
  processPlacementBills?: DifProcessPlacementBill[] | null;
  processEmployees?: DifProcessEmployee[] | null;
  processPackageEmployees?: DifProcessPackageEmployee[] | null;
  processPlacementEmployees?: DifProcessPlacementEmployee[] | null;
  processRealTimeEmployees?: DifProcessRealTimeEmployee[] | null;
  processPackageRealTimeEmployees?: DifProcessPackageRealTimeEmployee[] | null;
  processPlacementRealTimeEmployees?:
    | DifProcessPlacementRealTimeEmployee[]
    | null;
  paymentBills?: DifPaymentBill[] | null;
  salaries?: DifPaymentBill[] | null;
};
export type DifProductCellAction = {
  id?: number;
  objectCreateDate?: string;
  actionTime?: string;
  typeId?: number;
  type?: DifActionType;
  employeeId?: number;
  employee?: DifEmployee;
  receivedProductCellId?: number;
  productCell?: DifProductCell;
};
export type CreateEmployeeModel = {
  userId?: number;
  typeId?: number;
  warehouseId?: number;
};
export type EmployeeTypeOutputModel = {
  id?: number;
  type?: string | null;
};
export type UserModel = {
  /** Имя */
  name?: string | null;
  /** Почта */
  email?: string | null;
  /** Аватар */
  image?: string | null;
  /** Идентифиатор пользователя */
  id?: number;
  /** Идентификатор сотрудника */
  employeeId?: number | null;
  /** Идентификатор администратора */
  adminId?: number | null;
};
export type WarehouseOutputModel = {
  id?: number;
  /** Название склада */
  name?: string | null;
  /** Адрес склада */
  address?: string | null;
  /** Страна, в которой находится склад */
  country?: string | null;
  /** Субъект */
  subject?: string | null;
  /** Город, в котором находится склад */
  city?: string | null;
  /** Название улицы, на которой находится склад */
  street?: string | null;
  /** Номер дома */
  house?: string | null;
  /** Координаты */
  coordinates?: string | null;
};
export type EmployeeOuttputModel = {
  id?: number;
  /** Идентификатор типа сотрудника */
  typeId?: number;
  type?: EmployeeTypeOutputModel;
  /** Идентификатор пользователя */
  userId?: number;
  user?: UserModel;
  /** Идентификатор склада, на котром работает сотрудник */
  warehouseId?: number;
  warehouse?: WarehouseOutputModel;
  /** Расписание работы сотрудника */
  wortkSchedule?: string | null;
};
export type CollectionFilter = {
  /** Пропустить Skip объектов */
  skip?: number | null;
  /** Взять Take объектов */
  take?: number | null;
};
export type DifEmployeePaymentsHistory = {
  id?: number;
  objectCreateDate?: string;
  employeeId?: number;
  employee?: DifEmployee;
  rate?: number;
  amount?: number;
  workingHours?: number;
};
export type AnalyticsMoneyDateDetail = {
  number?: number;
  date?: string;
};
export type AnalyticsMoneyDateType = {
  name?: string | null;
  details?: AnalyticsMoneyDateDetail[] | null;
};
export type AnalyticsMoneyDateData = {
  dates?: string[] | null;
  profit?: AnalyticsMoneyDateType[] | null;
  expense?: AnalyticsMoneyDateType[] | null;
  all?: AnalyticsMoneyDateType[] | null;
};
export type AnalyticsMoneyDetail = {
  number?: number;
  name?: string | null;
};
export type AnalyticsMoney = {
  sum?: number;
  details?: AnalyticsMoneyDetail[] | null;
};
export type AnalyticsMoneyData = {
  profit?: AnalyticsMoney;
  expense?: AnalyticsMoney;
  all?: AnalyticsMoney;
};
export type AnalyticsProductType = 0 | 1 | 2 | 3 | 4;
export type AnalyticsProduct = {
  dateTime?: string;
  actualQuantity?: number;
  quantity?: number;
  type?: AnalyticsProductType;
};
export type AnalyticsProductData = {
  acceptance?: AnalyticsProduct[] | null;
  shipment?: AnalyticsProduct[] | null;
  package?: AnalyticsProduct[] | null;
  all?: AnalyticsProduct[] | null;
};
export type Position = {
  x?: number;
  y?: number;
};
export type VisitInputModel = {
  userId?: string;
  sessionId?: string;
  positions?: Position[] | null;
};
export type AuthOutputModel = {
  authToken?: string | null;
};
export type UserTypeEnum = 0 | 1 | 2;
export type AuthCredentials = {
  /** Почта */
  email?: string | null;
  /** Пароль */
  password?: string | null;
  userType?: UserTypeEnum;
};
export type QrCode = {
  honestSign?: string | null;
};
export type DateBillsCount = {
  date?: string;
  count?: number;
};
export type MiniBillOutputModel = {
  id?: number;
  organizationName?: string | null;
  warehouseName?: string | null;
  dateCreated?: string;
  realizationDate?: string | null;
  note?: string | null;
  type?: BillType;
  status?: BillStatus;
};
export type RedactPackageBillProductConsumable = {
  id?: number;
  consumableId?: number;
  quantity?: number;
};
export type RedactPackageBillProductModel = {
  id?: number;
  productId?: number;
  quantity?: number;
  consumables?: RedactPackageBillProductConsumable[] | null;
};
export type RedactPackageBillModel = {
  id?: number | null;
  /** Внешний идентификатор накладной */
  externalBillId?: string | null;
  /** Наименование склада куда приходит товар */
  warehouseName?: string | null;
  /** Идентификатор организации */
  organizationId?: number;
  /** Дата реализации */
  realizeDate?: string;
  /** Идентификатор склада */
  warehouseId?: number;
  /** Комментарий */
  note?: string | null;
  /** Список товаров */
  products?: RedactPackageBillProductModel[] | null;
};
export type RedactPlacementBillProduct = {
  id?: number;
  productCellId?: number;
  toCellId?: number;
  quantity?: number;
};
export type RedactPlacementBillModel = {
  warehouseId?: number | null;
  billId?: number | null;
  /** Текстовая заметка */
  note?: string | null;
  /** Запланированное время реализации */
  realizationDate?: string | null;
  billProducts?: RedactPlacementBillProduct[] | null;
};
export type ProductOutputModel = {
  /** Идентификатор товара */
  id?: number;
  name?: string | null;
  /** Внешний баркод */
  barcode?: string | null;
  /** Заводской баркод */
  factoryBarcode?: string | null;
  /** Артикул товара */
  article?: string | null;
  /** Количество товара у текущего пользователя */
  quantity?: number;
  packageQuantity?: number;
  /** Наименование организации */
  organizationName?: string | null;
  /** Стоимость услуги товара */
  serviceProductPrice?: number | null;
  capacity?: number;
  /** Цвет товара */
  color?: string | null;
  /** дата создания товара */
  objectCreateDate?: string;
  /** Изображения товара */
  images?: string[] | null;
  childs?: DifKitProduct[] | null;
  consumables?: DifProductConsumable[] | null;
};
export type ProductBillOutputModel = {
  id?: number;
  productId?: number;
  product?: ProductOutputModel;
  quantity?: number;
};
export type BillOutputModel = {
  /** Перевозчик */
  transporter?: string | null;
  /** Внешний идентификатор накладной */
  externalBillId?: string | null;
  /** Отправитель */
  sender?: string | null;
  /** Количество коробов */
  boxexCount?: number | null;
  /** Количество паллет */
  palletsCount?: number | null;
  id?: number;
  /** Количество товара */
  quantity?: number;
  /** Товары в накладной */
  productsBills?: ProductBillOutputModel[] | null;
  /** Идентификатор пользователя владельца товара */
  ownerId?: number;
  /** Идентификатор машины, увозящей товар */
  carId?: number | null;
  /** Идентификатор склада, с которого увозят товар */
  warehouseId?: number;
  warehouse?: WarehouseOutputModel;
  /** Текстовая заметка */
  note?: string | null;
  /** Комментарий к доставке */
  deliveryNote?: string | null;
  /** Адрес назначения товара */
  address?: string | null;
  /** Запланированное время отъезда машины */
  realizationDate?: string | null;
  /** Фактическое время отъезда машины */
  actualRealizationDate?: string | null;
  status?: BillStatus;
  type?: BillType;
  /** Номер  накладной */
  number?: number;
  /** Дата создания */
  objectCreateDate?: string;
  /** Наименование склада куда приходит товар */
  warehouseName?: string | null;
  /** Наименование организации */
  organizationName?: string | null;
};
export type ProductBillModel = {
  /** Идентификатор товара */
  id?: number;
  /** Количество */
  quantity?: number;
  packageTypeId?: number | null;
  honestSigns?: string[] | null;
};
export type RedactBillModel = {
  /** Идентификатор накладной */
  id?: number;
  /** Перевозчик */
  transporter?: string | null;
  /** Внешний идентификатор накладной */
  externalBillId?: string | null;
  /** Отправитель */
  sender?: string | null;
  /** Количество коробов */
  boxexCount?: number | null;
  /** Количество паллет */
  palletsCount?: number | null;
  /** Идентификатор организации */
  organizationId?: number;
  /** Дата реализации */
  realizeDate?: string;
  /** Идентфикатор машины доставки */
  carId?: number | null;
  /** Идентификатор склада */
  warehouseId?: number;
  type?: BillType;
  /** Комментарий */
  note?: string | null;
  /** Комментарий к доставке */
  deliveryNote?: string | null;
  /** Адрес */
  address?: string | null;
  /** Список товаров */
  products?: ProductBillModel[] | null;
  ownerId?: number | null;
};
export type ProductDetailConsumable = {
  name?: string | null;
  quantity?: number;
};
export type ProductDetailCell = {
  productId?: number;
  productCellId?: number;
  cellPlace?: string | null;
  cellId?: number;
  quantity?: number;
};
export type ProductDetail = {
  key?: string | null;
  quantity?: number;
  productId?: number;
  warehouseId?: number;
  warehouseName?: string | null;
  organizationName?: string | null;
  packageTypeId?: number | null;
  consumables?: ProductDetailConsumable[] | null;
  productDetailCells?: ProductDetailCell[] | null;
};
export type CellModel = {
  /** Место */
  place?: string | null;
  /** Идентификатор */
  id?: number;
  /** Имя */
  name?: string | null;
  status?: StoragePlaceStatus;
  /** Код ячейки */
  code?: string | null;
  /** Склад */
  warehouseName?: string | null;
  /** Склад */
  organizationName?: string | null;
  cellType?: CellTypeEnum;
  /** Процент заполненности ячейки */
  occupancyPercent?: number;
};
export type CreateCellRequest = {
  name?: string | null;
  type?: CellTypeEnum;
  length?: number;
  width?: number;
  height?: number;
  warehouseId?: number;
};
export type CreateConsumablesModel = {
  price?: number;
  measurementUnit?: string | null;
  name?: string | null;
  quantity?: number;
  warehouseId?: number;
};
export type DifConsumablesProduct = {
  id?: number;
  objectCreateDate?: string;
  employeeId?: number;
  employee?: DifEmployee;
  productId?: number;
  product?: DifProduct;
  consumablesId?: number;
  consumables?: DifConsumables;
  quantity?: number;
};
export type PeriodType = 0 | 1 | 2;
export type ConsumablesFilter = {
  /** Пропустить Skip объектов */
  skip?: number | null;
  /** Взять Take объектов */
  take?: number | null;
  /** Идентификатор сотрудника */
  employeeId?: number;
  /** Идентификаторы товаров, по которым нужны расходы */
  productsId?: number[] | null;
  byPeriodType?: PeriodType;
};
export type ConsumablesProductsFilter = {
  /** Идентификатор товара */
  productId?: number;
  /** Идентификатор расходника */
  consumablesId?: number;
};
export type DicPosition = {
  x?: number;
  y?: number;
};
export type DicVisitInputModel = {
  userId?: string;
  sessionId?: string;
  positions?: DicPosition[] | null;
};
export type DocumentOutput = {
  name?: string | null;
};
export type BillsFilter = {
  /** Сколько нужно пропустить */
  skip?: number | null;
  /** Сколько нужно вернуть */
  take?: number | null;
  status?: BillStatus;
  type?: BillType;
  /** Идентификатор накладной */
  billId?: number | null;
  /** Идентификатор владельца накладной */
  userId?: number | null;
  factoryBarcode?: string | null;
};
export type CreateFileResponse = {
  /** Путь к файлу */
  filePath?: string | null;
};
export type ClientInventorizationModel = {
  /** Текущее количество товара */
  actualQuantity?: number;
  /** Товар, который еще или уже не лежит на складе */
  undefinedQuantity?: number;
  /** Полное количество товара */
  quantity?: number;
  /** Почта владельца товраа */
  email?: string | null;
  /** Идентификатор владельца товра */
  clientId?: number;
};
export type IntegrationDataModel = {
  credentials?: string | null;
};
export type DifNew = {
  id?: number;
  objectCreateDate?: string;
  title?: string | null;
  description?: string | null;
};
export type NotificationSeenModelInput = {
  notificationId?: number;
};
export type ProductInputModel = {
  id?: number;
  productId?: number;
  quantity?: number;
};
export type CompletePackageInputModel = {
  billId?: number;
  cellId?: number;
  products?: ProductInputModel[] | null;
};
export type ProcessRealTimeEmployeeOutputModel = {
  processId?: number;
  employeeId?: number;
  name?: string | null;
  email?: string | null;
};
export type RedactProcessEmployeeModel = {
  processBillProductId?: number;
  isIncrement?: boolean;
  quantity?: number | null;
};
export type ProcessProductModel = {
  images?: string[] | null;
  productBillId?: number;
  productProcessId?: number;
  name?: string | null;
  article?: string | null;
  barcode?: string | null;
  factoryBarcode?: string | null;
  quantity?: number;
  acceptanceQuantity?: number;
};
export type ProcessModel = {
  products?: ProcessProductModel[] | null;
  percent?: number;
  empsName?: string[] | null;
};
export type InspectionCompleteModel = {
  processId?: number;
  requiredFull?: boolean;
};
export type CreatePaymentBillDetailModel = {
  name?: string | null;
  paymentAmount?: number;
};
export type CreatePaymentBillModel = {
  organizationId?: number | null;
  salaryForEmployeeId?: number | null;
  warehouseId?: number;
  detailType?: PaymentBillTypeDetailEnum;
  note?: string | null;
  createPaymentBillDetailModels?: CreatePaymentBillDetailModel[] | null;
};
export type PlacementProductInputModel = {
  productCellId?: number;
  quantity?: number;
};
export type PlacementBillInputModel = {
  products?: PlacementProductInputModel[] | null;
  id?: number;
};
export type PlacementProductModel = {
  images?: string[] | null;
  toCellName?: string | null;
  fromCellName?: string | null;
  productBillId?: number;
  productProcessId?: number;
  name?: string | null;
  article?: string | null;
  barcode?: string | null;
  factoryBarcode?: string | null;
  quantity?: number;
  acceptanceQuantity?: number;
};
export type PlacementProcessModel = {
  products?: PlacementProductModel[] | null;
  percent?: number;
  empsName?: string[] | null;
};
export type ProcessEmployeeOutputModel = {
  id?: number;
  email?: string | null;
  name?: string | null;
};
export type MiniProcessModel = {
  id?: number;
  name?: string | null;
  emps?: ProcessEmployeeOutputModel[] | null;
  percent?: number;
  dateStart?: string;
  dateEnd?: string | null;
};
export type ShipmentProductModel = {
  /** Наименование принятого товара */
  productName?: string | null;
  /** Идентификатор принятого товара */
  productId?: number;
  /** Количесвто */
  quantity?: number;
  packageTypeId?: number | null;
};
export type ShipmentModel = {
  models?: ShipmentProductModel[] | null;
  billId?: number;
  cellId?: number;
};
export type ProductCreateModel = {
  /** Цена обслуживания товара */
  serviceProductPrice?: number | null;
  /** Название товара */
  name?: string | null;
  /** Заводской баркод */
  factoryBarcode?: string | null;
  /** Артикул */
  article?: string | null;
  /** Цвет товара */
  color?: string | null;
  /** Внешний баркод */
  barcode?: string | null;
  /** Вес */
  weight?: number | null;
  /** Вес */
  height?: number;
  /** Вес */
  width?: number;
  /** Вес */
  length?: number;
  /** Внешний идентификатор */
  externalId?: string | null;
  /** Бренд */
  brand?: string | null;
  /** Идентификатор владельца товара */
  organizationId?: number;
};
export type DeleteProductImageInput = {
  productId?: number;
};
export type KitOutput = {
  kitId?: number;
};
export type CreateKitConsumableModel = {
  cunsumableId?: number;
  quantity?: number;
};
export type CreateKitProductModel = {
  createKitConsumableModels?: CreateKitConsumableModel[] | null;
  producId?: number;
  quantity?: number;
};
export type CreateKitModel = {
  /** Цена обслуживания товара */
  serviceProductPrice?: number | null;
  /** Название товара */
  name?: string | null;
  /** Заводской баркод */
  factoryBarcode?: string | null;
  /** Артикул */
  article?: string | null;
  /** Цвет товара */
  color?: string | null;
  /** Внешний баркод */
  barcode?: string | null;
  /** Вес */
  weight?: number | null;
  /** Объем */
  capacity?: number | null;
  /** Внешний идентификатор */
  externalId?: string | null;
  /** Бренд */
  brand?: string | null;
  /** Идентификатор владельца товара */
  organizationId?: number;
  childs?: CreateKitProductModel[] | null;
  createKitConsumableModels?: CreateKitConsumableModel[] | null;
};
export type ProductRedactModel = {
  id?: number;
  /** Цена обслуживания товара */
  serviceProductPrice?: number | null;
  /** Цена обслуживания товара */
  organizationId?: number | null;
  /** Название товара */
  name?: string | null;
  /** Заводской баркод */
  factoryBarcode?: string | null;
  /** Артикул */
  article?: string | null;
  /** Цвет товара */
  color?: string | null;
  /** Внешний баркод */
  barcode?: string | null;
  /** Вес */
  weight?: number;
  /** Вес */
  height?: number;
  /** Вес */
  width?: number;
  /** Вес */
  length?: number;
  /** Объем */
  capacity?: number;
  /** Внешний идентификатор */
  externalId?: string | null;
  /** Бренд */
  brand?: string | null;
};
export type OrganizationOutputModel = {
  id?: number;
  name?: string | null;
};
export type MeOutput = {
  id?: number;
  accessLevel?: EmployeeAccessLevelEnum;
  warehouseId?: number | null;
  warehouseOrganizationId?: number | null;
  organizationId?: number | null;
  employeeId?: number | null;
  clientId?: number | null;
  warehouseOwnerId?: number | null;
  isTableStick?: boolean;
};
export type UpdateUserProfileModel = {
  /** Имя */
  name?: string | null;
  /** Фамилия */
  secondName?: string | null;
  gender?: GenderEnum;
  /** Дата дня рождения */
  birtdayDate?: string | null;
  /** Номер телефона */
  telephone?: string | null;
  /** Электронная почта */
  email?: string | null;
  /** Пароль */
  password?: string | null;
  /** id складской организации */
  currentWarehouseOrganizationId?: number | null;
  /** id склада */
  currentWarehouseId?: number | null;
  /** id склада */
  currentOrganizationId?: number | null;
};
export type SettingsModel = {
  isTableStick?: boolean;
  organizationId?: number | null;
  warehouseId?: number | null;
  warehouseOrganizationId?: number | null;
};
export type DrowItemFloorType = {
  id: number;
  name: string;
  height: number;
};
export type DrowItemType = {
  id: number;
  name: string;
  color: string;
  width: number;
  length: number;
  x: number;
  y: number;
  rotation: number;
  isSelected: boolean;
  type: DrowItemTypes;
  floors?: DrowItemFloorType[] | null;
};
export type UpdateWarehouseSchemeInputModel = {
  newName?: string | null;
  length?: number;
  width?: number;
  height?: number;
  drowItems?: DrowItemType[] | null;
};
export type CreateWarehouseInputModel = {
  description?: string | null;
  organizationId?: number;
  email?: string | null;
  telephone?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  /** Название склада */
  name?: string | null;
  /** Адрес склада */
  address?: string | null;
  /** Страна, в которой находится склад */
  country?: string | null;
  /** Субъект */
  subject?: string | null;
  /** Город, в котором находится склад */
  city?: string | null;
  /** Название улицы, на которой находится склад */
  street?: string | null;
  /** Номер дома */
  house?: string | null;
};
export const {
  useGetProductsReceivedActionsQuery,
  usePostProductsReceivedActionsActionMutation,
  usePostAdminEmpoloyeeMutation,
  useGetAdminEmployeesQuery,
  useGetAdminEmployeesPaymentsQuery,
  useGetAnalyticsRemoveDataQuery,
  useGetAnalyticsGenProcessesDataQuery,
  useGetAnalyticsGenPRoductsDataQuery,
  useGetAnalyticsGenDataQuery,
  useGetAnalyticsMoneyDateQuery,
  useGetAnalyticsMoneyQuery,
  useGetAnalyticsProductsQuery,
  usePostAnalyticsAddVisitMutation,
  useGetAnalyticsAddRecallRequestQuery,
  usePostAuthLoginMutation,
  usePostAuthRegistrationMutation,
  usePostBillsExcelHonestSignsMutation,
  useGetBillsDateQuery,
  useGetBillsQuery,
  useDeleteBillsMutation,
  useGetBillsGetAcceptanceBillsByProductBarcodeQuery,
  useGetBillsGetShipmentBillsByProductBarcodeQuery,
  useGetBillsByIdQuery,
  useGetBillsPackageBillQuery,
  usePostBillsPackageBillMutation,
  useGetBillsPlacementBillQuery,
  usePostBillsPlacementBillMutation,
  usePostBillsRedactPlacementBillMutation,
  usePostBillsRedactPackageBillMutation,
  usePostBillsBillMutation,
  useGetBillsByIdDetailsQuery,
  usePostBillsRedactBillMutation,
  usePostBillsFileExcelMutation,
  usePostBillsEmployeeFileExcelMutation,
  usePutBillsStatusMutation,
  useGetCellsQuery,
  usePostCellsMutation,
  useGetCellsByIdQuery,
  useGetClientsCooperationRequestsQuery,
  useGetClientsCooperationsQuery,
  usePostClientsCreateCooperationMutation,
  useDeleteClientsDeleteCooperationMutation,
  usePostClientsCreateCooperationRequestMutation,
  useDeleteClientsDeleteCooperationRequestMutation,
  useGetConsumablesQuery,
  usePostConsumablesMutation,
  useGetConsumablesSpendingQuery,
  useGetConsumablesSpendingActualQuery,
  usePutConsumablesByIdSpentQuantityMutation,
  usePutConsumablesByIdQuantityMutation,
  usePostDicAddVisitMutation,
  useGetDicAddRecallRequestQuery,
  useGetDocumentsQuery,
  useGetDocumentsDocxBillsQuery,
  usePostDocumentsExcelBillsMutation,
  usePostDocumentsTemplateMutation,
  useGetEmployeesCooperationRequestsQuery,
  useGetEmployeesCooperationsQuery,
  usePostEmployeesCreateCooperationMutation,
  useDeleteEmployeesDeleteCooperationMutation,
  usePostEmployeesCreateCooperationRequestMutation,
  useDeleteEmployeesDeleteCooperationRequestMutation,
  useGetEventsQuery,
  usePostEventsMutation,
  usePostFilesUploadMutation,
  useGetProductsInventorizeClientsQuery,
  usePostIntegrationMoySkladProductMutation,
  usePostIntegrationMoySkladDataMutation,
  useGetNewsQuery,
  usePostNewsMutation,
  usePostNotificationsSeenMutation,
  useGetNotificationsQuery,
  usePostNotificationsNewMutation,
  useGetOrganizationsQuery,
  usePostPackageCompleteMutation,
  useGetProcessesPackageSetEmployeeInProcessAndGetEmployeesInProcessQuery,
  usePostProcessesPackageRedactProcessEmployeeMutation,
  usePostProcessesPackageAddProcessMutation,
  useGetProcessesPackageProcessModelQuery,
  usePostProcessesPackageCompleteMutation,
  usePostPaymentsGenMutation,
  useGetPaymentsBillQuery,
  useGetPaymentsBillsQuery,
  usePostPaymentsMutation,
  usePostPlacementCompleteMutation,
  useGetProcessesPlacementSetEmployeeInProcessAndGetEmployeesInProcessQuery,
  usePostProcessesPlacementRedactProcessEmployeeMutation,
  usePostProcessesPlacementAddProcessMutation,
  useGetProcessesPlacementProcessModelQuery,
  usePostProcessesPlacementCompleteMutation,
  useGetProcessesSetEmployeeInProcessAndGetEmployeesInProcessQuery,
  usePostProcessesRedactProcessEmployeeMutation,
  usePostProcessesAddProcessMutation,
  useGetProcessesGetProcessBillByIdQuery,
  useGetProcessesQuery,
  useGetProcessesInspectionProcessModelQuery,
  usePostProcessesInspectionCompleteMutation,
  usePostProductsCellsShipMutation,
  usePostProductsExcelMutation,
  useGetProductsQuery,
  usePostProductsMutation,
  useDeleteProductsMutation,
  useGetProductsByIdQuery,
  useGetProductsByIdDetailsQuery,
  useGetProductsByProductIdCellsQuery,
  useDeleteProductsImageMutation,
  usePostProductsImageMutation,
  usePostProductsKitMutation,
  usePostProductsRedactMutation,
  useGetUsersUserOrganizationsQuery,
  useGetUsersMeQuery,
  usePutUsersMeMutation,
  useGetUsersProfileQuery,
  usePutUsersProfileImageMutation,
  usePutUsersSettingsMutation,
  useGetUsersSettingsQuery,
  useGetUsersEmployeesQuery,
  useGetWarehouseByIdSchemeQuery,
  usePutWarehouseSchemesBySchemeIdMutation,
  useDeleteWarehouseSchemesBySchemeIdMutation,
  usePostWarehouseByWarehouseIdCreateSchemeMutation,
  useGetWarehouseByIdConsumablesQuery,
  useGetWarehouseClientsCooperationRequestsQuery,
  useGetWarehouseClientsCooperationsQuery,
  usePostWarehouseClientsCreateCooperationMutation,
  useDeleteWarehouseClientsDeleteCooperationMutation,
  usePostWarehouseClientsCreateCooperationRequestMutation,
  useDeleteWarehouseClientsDeleteCooperationRequestMutation,
  useDeleteWarehouseImageMutation,
  usePostWarehouseImageMutation,
  usePostWarehouseCreateMutation,
  usePostWarehouseCreateOrganizationMutation,
  useGetWarehouseOrganizationsByUserQuery,
  useGetWarehouseListByUserQuery,
  useGetWarehouseQuery,
  useGetWarehouseByIdQuery,
  useGetWarehouseEmployeeCooperationRequestsQuery,
  useGetWarehouseEmployeeCooperationsQuery,
  usePostWarehouseEmployeeCreateCooperationMutation,
  useDeleteWarehouseEmployeeDeleteCooperationMutation,
  usePostWarehouseEmployeeCreateCooperationRequestMutation,
  useDeleteWarehouseEmployeeDeleteCooperationRequestMutation,
  useGetWarehouseOrganizationsQuery,
  useGetWarehouseOrganizationsByIdEmployeesQuery,
  useGetWarehouseOrganizationsByIdWarehousesQuery,
} = injectedRtkApi;
