import { UserTypeEnum } from "api";

export const getUserTypeName = (type: UserTypeEnum) => {
    if (type == 0){
        return 'Клиент';
    }
    else if (type == 1){
        return 'Сотрудник склада';
    }
    else {
        return 'Владелец склада';
    }
}


export const getUserTypeKey = (name: string) => {
    if (name == 'Клиент'){
        return 0;
    }
    else if (name == 'Сотрудник склада'){
        return 1;
    }
    else {
        return 2;
    }
}