import React from 'react'; 
import { Input } from "shared/ui/Input";
import { Modal } from "shared/ui/Modal";
import styles from "./index.module.scss";
import { Button } from 'shared/ui/Button';

type Props = {
    isModalOpened: boolean;
    setModalOpened: (isOpen: boolean) => void;
    awaitQuantity: number;
    setAwaitQuantity: (awaitQuantity: number) => void;
    quantity: number;
    setQuantity: (quantity: number) => void;
    quantityValue: number;
    setQuantityValue: (quantityValue: number) => void;
    isAdd: boolean;
    onSomeIncrementPress: () => void;
    onSetPress: () => void;
}

const TerminalCounterModal = ({isAdd, onSetPress, onSomeIncrementPress, isModalOpened, setModalOpened, setAwaitQuantity, setQuantity, setQuantityValue, quantity, quantityValue, awaitQuantity}: Props) => (
    <Modal
        close={() => {
            setAwaitQuantity(0);
            setQuantity(0);
            setModalOpened(false);
        }} 
        isOpen={isModalOpened}
        >
            <>
                <div className={styles.tableContainer}>
                    <table className={styles.table}>
                        <tbody>
                            <tr>
                                <td>Ожид. кол-во</td>
                                <td>Скан. кол-во</td>
                                <td>Ост. кол-во</td>
                            </tr>
                            <tr>
                                <td>{awaitQuantity}</td>
                                <td>{quantity}</td>
                                <td>{awaitQuantity - quantity}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Input
                    value={quantityValue.toString()}
                    onChange={(e) => {
                        if (Number(e.target.value)) {
                            setQuantityValue(Number(e.target.value))
                        } else {
                            setQuantityValue(0)
                        }
                    }}
                    placeholder="Количество"
                />
                <Button 
                    inlineStyles={{margin: "10px 0 0 0"}}
                    text={isAdd ? "Добавить" : "Установить"}
                    onClick={() => {
                        isAdd 
                            ? onSomeIncrementPress()
                            : onSetPress();
                        setQuantityValue(0);
                    }}
                />
            </>
    </Modal>
)

export {
    TerminalCounterModal
}