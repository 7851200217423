import styles from "./index.module.scss"
import { TerminalCounter } from "entities/TerminalCounter"

type Props = {
    name : string,
    imgSrc: string,
    article: string,
    toCellName: string,
    fromCellName: string,
    factoryBarcode: string,
    barcode: string,
    awaitQuantity : number,
    onIncrement : () => void,
    onDecrement : () => void,
    onAdd?: () => void,
    onSet?: () => void,
    quantity: number
}

const PlacementProduct =  ({
    name,
    article,
    factoryBarcode,
    barcode,
    toCellName,
    fromCellName,
    awaitQuantity,
    onIncrement,
    onDecrement,
    imgSrc,
    quantity,
    onSet,
    onAdd}: Props) => {

    return <div className={styles.card}>
        <div className={styles.header}>
            <img src={imgSrc} className={styles.image}/>
            <div className={styles.info}>
                <div className={styles.nameAndText}>Название: <span className={styles.text}>{name}</span></div>
                <div className={styles.nameAndText}>Артикул: <span className={styles.text}>{article}</span></div>
                <div className={styles.nameAndText}>Штрихкод заводской: <span className={styles.text}>{factoryBarcode}</span></div>
                <div className={styles.nameAndText}>Штрихкод:  <span className={styles.text}>{barcode}</span></div>
            </div>
        </div>
        <div>
            Из: {fromCellName}
        </div>
        <div>
            В: {toCellName}
        </div>
        <TerminalCounter
            onAdd={onAdd}
            onSet={onSet}
            onDecrement={onDecrement}
            onIncrement={onIncrement}
            awaitQuantity={awaitQuantity}
            quantity={quantity}
            />
    </div>
}

export {PlacementProduct};