import clsx from "clsx";
import { BaseSharedComponentType } from "types";
import styles from "./index.module.scss";

type Props = {
    children: React.ReactNode,
} & BaseSharedComponentType;

const MiniTitle = ({inlineClasses, inlineStyles, children}: Props) => (
    <div className={clsx(styles.title, inlineClasses)} style={inlineStyles}>{children}</div>
);

export {
    MiniTitle
};