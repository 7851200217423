
/* eslint-disable @typescript-eslint/no-unused-expressions */
export const getBillStatus = (statusNumber?: number | null | undefined) => {
    if (statusNumber == 0) 
    {
        return 'Новая';
    }
    if (statusNumber == 1) 
    {
        return "Удалена";
    }
    if (statusNumber == 2)
    {
        return "В работе";
    }
    if (statusNumber == 3)
    {
        return "Принята";
    }
    if (statusNumber == 4)
    {
        return "Закрыта";
    }

    return "Неизвестно";
}