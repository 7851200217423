import Layout from "widgets/Layout/Layout";
import { useState } from "react";
import { tableHeadName } from "../consts";
// import { useGetInventoryQuery } from "../api/inventoryTableAPI";
// import { InventoryType } from "../model";
// import useChangeUpdate from "pages/AcceptanceBills/hooks/useChangeUpdate";
import styles from "./index.module.scss";
// import stylesm from "./index_mobile.module.scss";
import { OpenListIcon } from "shared/ui/icons/icons";
// import { Sort as MobileSort } from "shared/ui-mobile/Sort";
// import { SearchInputMobile } from "shared/ui/SearchInputMobile";
import { MultipleSelect } from "shared/ui/MultipleSelect";
// import { SelectedOptions } from "shared/ui-mobile/SelectedOptions";
import { SearchInput } from "shared/ui/SearchInput";
import { Sort } from "shared/ui/Sort";
// import { Options } from "shared/ui/Options";
import { Button } from "shared/ui/Button";
import ImportButton from "features/ImportButton/ImportButton";
import { Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { useGetProductsInventorizeClientsQuery, useGetProductsQuery } from "api";
import { OptionType } from "shared/ui/Select";
import { ProductModal } from "features/Modals/Product";

const options: OptionType[] = [
    {value: "ID", key: 1},
    {value: "Клиент", key: 2},
    {value: "В наличии", key: 3},
    {value: "Брак", key: 4},
    {value: "Неопознаный", key: 5},
    {value: "Всего", key: 6},
];

const InventoryPage = () => {
    const [clientId, setClientId] = useState<number | null>(null);

    const {data: clients, refetch, isFetching: isClientsFetching} = useGetProductsInventorizeClientsQuery();
    const {data: products, isFetching: isProductsFetching} = useGetProductsQuery({organizationId: clientId!}, {skip: clientId == null});

    const [isOpen, setIsOpen] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState<number[] | null>([2]);
    const [searchText, setSearchText] = useState("");

    const filteredData = clients?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el.clientId, key: 1},
        {value: el.email, key: 2},
        {value: el.actualQuantity, key: 3},
        {value: el.undefinedQuantity, key: 4},
        {value: el.quantity, key: 5},
    ]));
    
    const [selectedOption, setSelectedOption] = useState(3);

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2.clientId, value1: el1.clientId, key: 1},
        {value2: el2.email,  value1: el1.email, key: 2},
        {value2: el2.actualQuantity, value1: el1.actualQuantity, key: 3},
        {value2: el2.undefinedQuantity, value1: el1.undefinedQuantity, key: 4},
        {value2: el2.quantity, value1: el1.quantity, key: 5},
    ], selectedOption, isDesc));
    return (
        <Layout>
            <div className={styles.header}>
                <div style={{display: "flex"}}>
                <Button
                    inlineStyles={{marginRight: 10}}
                    text="Обновить"
                    onClick={refetch}
                    />
                <ImportButton
                    addFile={file => {}}
                    text="Импорт"
                    inlineStyles={{marginRight: 10}}
                    />
                </div>
                <div style={{display: 'flex'}}>
                    <SearchInput 
                        value={searchText} 
                        onChange={setSearchText}
                        inlineStyles={{marginRight: 10}}
                        classNames={styles.searchInput}
                        />
                    <MultipleSelect 
                        text="Параметры"
                        options={options} 
                        selectedOptionKeys={selectedOptions} 
                        setSelectedOptions={setSelectedOptions}
                        inlineStyles={{marginRight: 10, width: 135}}
                        />
                    <Sort 
                        options={options}
                        selectedOptionKey={selectedOption}
                        setSelectedOption={setSelectedOption}
                        setIsDesc={setIsDesc}
                        isDesc={isDesc}
                        selectClassNames={styles.select}
                        />
                </div>
            </div>
            {/* 
            <div className={clsx(stylesm.container, styles.mobile)}>
                <div style={{display:'flex'}}>
                    <SearchInputMobile 
                        inlineStyles={{flex: 1, minWidth: 50}}
                        text={searchText}
                        setText={setSearchText}
                        />
                    <MultipleSelect 
                        text="Параметры"
                        options={options} 
                        selectedOptions={selectedOptions} 
                        setSelectedOptions={setSelectedOptions}
                        inlineStyles={{marginRight: 10}}
                        />
                    <MobileSort 
                        isDesc={isDesc}
                        setIsDesc={setIsDesc}
                        options={options} 
                        selectedOption={selectedOption} 
                        setSelectedOption={setSelectedOption}
                        />
                </div>

                <div>
                    <SelectedOptions 
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        />
                </div>
            </div> */}

            {/* <div className={styles.mobile}>
                { sortedData?.map(el => (
                    <div className={styles.item_m}>
                        <div className={styles.bold} style={{marginBottom: 5}}>
                            ID:
                            <span style={{fontWeight: 500, marginLeft: 3}}>
                                {el.clientId}
                            </span>
                        </div>
                        <div style={{marginBottom: 5}} className={styles.bold}>
                            Клиент:
                            <span style={{color: "#C5A2E0", fontWeight: 500, marginLeft: 3}}>
                                {el.email}
                            </span>
                        </div>
                        <div style={{marginBottom: 5}} className={styles.bold}>
                            В наличии: 
                            <span style={{fontWeight: 500, marginLeft: 3}}>
                                {el.actualQuantity}
                            </span>
                        </div>
                        <div style={{marginBottom: 5}} className={styles.bold}>
                            Брак: 
                            <span style={{fontWeight: 500, marginLeft: 3}}>
                                {el.undefinedQuantity}
                            </span>
                        </div>
                        <div className={styles.bold} style={{marginBottom: 5}}>
                            Неопознанный:
                            <span style={{fontWeight: 500, marginLeft: 3}}>
                                {el.undefinedQuantity}
                            </span>
                        </div>
                        <div className={styles.bold} style={{marginBottom: 5}}>
                            Всего:
                            <span style={{fontWeight: 500, marginLeft: 3}}>
                                {el.quantity}
                            </span>
                        </div>
                        <div
                            onClick={() => {
                                setIsOpen(true);
                                setClientId(el.clientId);
                            }}
                            style={{cursor: 'pointer', position: 'absolute', top: 10, right: 10}}
                            >
                            <OpenListIcon/>
                        </div>
                    </div>
                ))
                }
            </div> */}

            <Table columns={tableHeadName} isFetching={isClientsFetching}>
                {sortedData?.map(el => (
                    <TableItem inlineStyles={{marginBottom: 10}}>
                        <TableItemContainer width={tableHeadName[0].width}>{el.clientId}</TableItemContainer>
                        <TableItemContainer width={tableHeadName[1].width}>{el.email}</TableItemContainer>
                        <TableItemContainer width={tableHeadName[2].width}>{el.actualQuantity}</TableItemContainer>
                        <TableItemContainer width={tableHeadName[3].width}>{el.undefinedQuantity}</TableItemContainer>
                        <TableItemContainer width={tableHeadName[4].width}>{el.quantity}</TableItemContainer>
                        <TableItemContainer width={tableHeadName[5].width}><div onClick={() => {  setIsOpen(true);  setClientId(el.clientId!); }}  style={{width: "15%", cursor: 'pointer'}}>  <OpenListIcon/> </div></TableItemContainer>
                    </TableItem>
                ))}
            </Table>
            <ProductModal
                isOpen={isOpen}
                close={() => setIsOpen(false)}
                products={products}
                isLoader={isProductsFetching}
                />
        </Layout>
    )
};

export {InventoryPage};