import Layout from "widgets/Layout/Layout";
import { useAppSelector } from "app/store/hooks/redux";
import { CooperationsPageClient } from "./components/client";
import { CooperationsPageEmployee } from "./components/employee";
import { CooperationsPageWarehouseOwner } from "./components/warehouseOwner";

const CooperationsPage = () => {
    const {isClient, isEmployee, isWarehouseOwner} = useAppSelector(el => el.userReducer);

    return (
        <Layout>
            {isClient && <CooperationsPageClient/>}
            {isEmployee && <CooperationsPageEmployee/>}
            {/* {isWarehouseOwner && <CooperationsPageWarehouseOwner/>} */}
        </Layout>
    );
};

export {
    CooperationsPage
}