import React, { useEffect, useLayoutEffect, useState } from "react";
import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import stylesm from "./index.m.module.scss";
import { Button } from "shared/ui/Button";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Input } from "shared/ui/Input";
import { OptionType, Select } from "shared/ui/Select";
import { TextArea } from "shared/ui/TextArea";
import clsx from "clsx";
import { ProductOutputModel, RedactBillModel, useGetBillsByIdQuery, useGetOrganizationsQuery, useGetProductsQuery, useGetWarehouseOrganizationsByIdWarehousesQuery, useGetWarehouseOrganizationsQuery, usePostBillsRedactBillMutation } from "api";
import { useAppSelector } from "app/store/hooks/redux";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { NavigateButton } from "shared/ui/NavigateButton";
import { MiniTitle } from "shared/ui/MiniTitle";
import { ProductModal } from "features/Modals/Product";
import { ProductModal as MobileProductModal } from "features/mobile/Modals/Product";
import { getImageUrl, getImageUrlByStrings } from "functions/image";
import { IS_PC } from "app/store/consts";
import { List } from "widgets/mobile/List";
import { ListItem } from "widgets/mobile/List/components/Item";
import { ListItemText } from "widgets/mobile/List/components/ListItemText";
import { ButtonsContainer } from "shared/ui-mobile/ButtonsContainer";

type Props = {
};

const columns: ColumnHeaderType[] = [
    {name: "Изображение", width: "15%"},
    {name: "Наименование", width: "20%"},
    {name: "Штрих-код", width: "15%"},
    {name: "Цвет", width: "10%"},
    {name: "Размер", width: "15%"},
    {name: "Цена услуги", width: "10%"},
    {name: "Количество", width: "10%"},
]

const transporters: OptionType[] = [
    {value: "Склад", key: 1},
    {value: "Самостоятельно", key: 2},
    {value: "Транспортная компания", key: 3},
];

const RedactAcceptanceBillPage = ({}: Props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    useLayoutEffect(() => {
        if (!searchParams.has('billId')) {
            navigate(LinkPagesEnum.AcceptanceBills);
        }
    }, [])

    const [addProductModalVisible, setAddProductModalVisible] = useState(false);
    const [addedProducts, setAddedProducts] = useState<ProductOutputModel[]>([]);
    const [quantities, setQuantities] = useState<{id: number, quantity: number}[]>([]);
    
    const {id: userId, isClient} = useAppSelector(el => el.userReducer);

    const [redactBillQuery] = usePostBillsRedactBillMutation();
    const {data: organizations} = useGetOrganizationsQuery();
    const {data: warehouseOrganizations} = useGetWarehouseOrganizationsQuery();

    const [selectedWarehouseOrganizationId, setSelectedWarehouseOrganizationId] = useState<number | null>();
    const {data: warehouses, isFetching: isWarehousesFetching} = useGetWarehouseOrganizationsByIdWarehousesQuery({id: selectedWarehouseOrganizationId!}, {skip: selectedWarehouseOrganizationId == null});

    const {data: productsByUser} = useGetProductsQuery({clientId: userId});
    const {data: bill} = useGetBillsByIdQuery({id: Number(searchParams.get('billId'))});

    const [redactedBill, setRedactedBill] = useState<RedactBillModel>({});

    useEffect(()=> {
        if (bill != null) {
            setAddedProducts(bill!.productsBills!.map(productBill => ({...productBill.product!, id: productBill.product?.id, organizationName: productBill.product?.organization?.name, images: [getImageUrl(productBill.product?.imageLinks)]})));
            setQuantities(bill!.productsBills!.map(productBill => ({id: productBill.product!.id!, quantity: productBill.quantity!})));
            setRedactedBill({
                id: bill?.id,
                realizeDate: (new Date(bill?.realizationDate!)).toISOString(),
                organizationId: bill.organizationId,
                palletsCount: bill.palletsCount,
                boxexCount  : bill.boxexCount,
                transporter: bill.transporter,
                warehouseId: bill.warehouseId,
                carId: bill.carId,
                note: bill.note,
                deliveryNote: bill.deliveryNote,
                externalBillId: bill.externalBillId,
                sender: bill.sender,
            });
            setSelectedWarehouseOrganizationId(bill.warehouse?.organizationId);
        }
    },[bill])

    const redactAcceptanceBill = async () => {
        try{
            await redactBillQuery({redactBillModel: {...redactedBill, products: addedProducts.map(product => {
                const quantity = quantities.find(quantity => quantity.id == product.id)?.quantity;
                return {...product, quantity: quantity};
            })}}).unwrap();
            navigate(LinkPagesEnum.AcceptanceBills);
        }
        catch(e) {
        }
    };

    if (IS_PC) {
    return (
        <Layout>
                <>
                    <div>
                        <div className={styles.header}>
                            <NavigateButton inlineStyles={{marginRight: 10}} text="Назад" to={LinkPagesEnum.AcceptanceBills}/>
                            {isClient && <Button text="Сохранить" onClick={redactAcceptanceBill}/>}
                        </div>
                        <div className={styles.main}>
                            <div className={styles.container}>
                                <MiniTitle inlineStyles={{marginBottom: 20}}>
                                    Информация
                                </MiniTitle>
                                <div className={styles.fieldsContainer}>
                                    <div className={styles.field}>
                                    <Select 
                                            placeholder="Складская организация"
                                            options={warehouseOrganizations?.map(el =>({value: el.name!, key: el.id!}))}
                                            onChange={val => setSelectedWarehouseOrganizationId(val.key)}
                                            selectedOptionKey={selectedWarehouseOrganizationId}
                                            inlineStyles={{marginBottom: 10}}
                                            />
                                        <Select 
                                            nullOptionsText={selectedWarehouseOrganizationId ? "Нет записей" : "Выберите складскую организацию"}
                                            isFetching={isWarehousesFetching}
                                            placeholder="Склад"
                                            options={warehouses?.map(el => ({value: el.name!, key: el.id!}))}
                                            onChange={val => setRedactedBill({...redactedBill, warehouseId: val.key})}
                                            selectedOptionKey={redactedBill.warehouseId}
                                            inlineStyles={{marginBottom: 10}}
                                            />
                                        <Select 
                                            placeholder="Организация"
                                            options={organizations?.map(el =>({value: el.name!, key: el.id!}))}
                                            onChange={val => setRedactedBill({...redactedBill, organizationId: val.key})}
                                            selectedOptionKey={redactedBill.organizationId}
                                            inlineStyles={{marginBottom: 10}}
                                            />
                                        <Input
                                            placeholder="Внешний номер накладной"
                                            value={redactedBill.externalBillId}
                                            onChange={e => setRedactedBill({...redactedBill, externalBillId: e.target.value})}
                                            />
                                    </div>
                                    <TextArea
                                        classNamesContainer={clsx(styles.field, styles.comment)}
                                        placeholder="Комментарий"
                                        onChange={e => setRedactedBill({...redactedBill, note: e.target.value})}
                                        value={redactedBill.note}
                                        />
                                </div>
                            </div>
                            <div className={styles.container}>
                                <MiniTitle inlineStyles={{marginBottom: 20}}>
                                    Доставка
                                </MiniTitle>
                                <div className={styles.fieldsContainer} style={{flexWrap: "wrap"}}>
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Отправитель"
                                        value={redactedBill.sender}
                                        onChange={e => setRedactedBill({...redactedBill, sender: e.target.value})}
                                        />
                                    <Select 
                                        classNames={styles.field}
                                        placeholder="Перевозчик"
                                        options={transporters}
                                        onChange={val => setRedactedBill({...redactedBill, transporter: val.value})}
                                        selectedOptionKey={transporters.find(x => x.value == redactedBill.transporter)?.key}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input 
                                        classNamesContainer={styles.field}
                                        placeholder="Ожидаемая дата доставки"
                                        type="date"
                                        value={redactedBill.realizeDate?.split('T')[0]}
                                        onChange={e => setRedactedBill({...redactedBill, realizeDate: (new Date(e.target.value).toISOString())})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Количество коробов"
                                        value={redactedBill.boxexCount?.toString()}
                                        type="number"
                                        onChange={e => setRedactedBill({...redactedBill, boxexCount: Number(e.target.value)})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Количество паллет"
                                        type="number"
                                        value={redactedBill.palletsCount?.toString()}
                                        onChange={e => setRedactedBill({...redactedBill, palletsCount: Number(e.target.value)})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <TextArea
                                        classNamesContainer={styles.field}
                                        inlineStylesContainer={{height: 100}}
                                        placeholder="Коментарий"
                                        value={redactedBill.deliveryNote}
                                        onChange={e => setRedactedBill({...redactedBill, deliveryNote: e.target.value})}
                                        />
                                </div>
                            </div>
                        </div>
                        <div className={styles.products}>
                            {isClient && <div className={styles.products__buttons}>
                                <Button 
                                    text="Добавить товар"
                                    onClick={() => {
                                        setAddProductModalVisible(true);
                                    }}
                                    />
                            </div>}
                            <Table columns={columns}>
                                {addedProducts?.map(product => <TableItem inlineStyles={{marginBottom: 10}}>
                                        <>
                                            <TableItemContainer width={columns[0].width}><img src={product.images![0]!} className={styles.img}/></TableItemContainer>
                                            <TableItemContainer width={columns[1].width}><div className={styles.text}>{product.name}</div></TableItemContainer>
                                            <TableItemContainer width={columns[2].width}><div className={styles.text}>{product.barcode}</div></TableItemContainer>
                                            <TableItemContainer width={columns[3].width}><div className={styles.text}>{product.color}</div></TableItemContainer>
                                            <TableItemContainer width={columns[4].width}><div className={styles.text}>-</div></TableItemContainer>
                                            <TableItemContainer width={columns[5].width}><div className={styles.text}>{product.serviceProductPrice}</div></TableItemContainer>
                                            <TableItemContainer width={columns[6].width}>
                                                <Input 
                                                    type="number"
                                                    onChange={e => 
                                                        setQuantities(prevState =>
                                                            {   
                                                                if (prevState.find(pv => pv.id == product.id)) {
                                                                    return prevState.map(pv => pv.id == product.id 
                                                                        ? {...pv, quantity: Number(e.target.value)} 
                                                                        : pv
                                                                        )
                                                                }
                                                                return [...prevState, {id: product.id!, quantity: Number(e.target.value)}]
                                                            } 
                                                            )
                                                        } 
                                                    value={quantities!.find(q => q.id == product.id)!.quantity.toString()}
                                                    />
                                            </TableItemContainer>
                                        </>
                                    </TableItem>)
                                }
                            </Table>
                        </div>
                    </div>
                    <ProductModal
                        isOpen={addProductModalVisible}
                        close={() => setAddProductModalVisible(false)}
                        products={productsByUser}
                        selectedProducts={addedProducts}
                        onProductClick={(el) => {
                            if (addedProducts.map(x => x.id).includes(el.id)) {
                                setAddedProducts(prevState => prevState.filter(el2 => el2.id != el.id));
                                setQuantities(prevState => prevState.filter(el2 => el2.id != el.id));
                            }
                            else {
                                setAddedProducts(prevState => [...prevState, el]);
                                setQuantities(prevState => [...prevState, {id: el.id!, quantity: 0}])
                            }}
                        }
                        />
                </>
        </Layout>
    )}

    return (
        <Layout>
            <ButtonsContainer>
                <NavigateButton inlineStyles={{marginRight: 10}} text="Назад" to={LinkPagesEnum.AcceptanceBills}/>
                {isClient && <Button text="Сохранить" onClick={redactAcceptanceBill}/>}
            </ButtonsContainer>
            <MiniTitle inlineStyles={{marginBottom: 10}}>
                Информация
            </MiniTitle>
            <Select 
                    placeholder="Складская организация"
                    options={warehouseOrganizations?.map(el =>({value: el.name!, key: el.id!}))}
                    onChange={val => setSelectedWarehouseOrganizationId(val.key)}
                    selectedOptionKey={selectedWarehouseOrganizationId}
                    inlineStyles={{marginBottom: 10}}
                    />
            <Select 
                nullOptionsText={selectedWarehouseOrganizationId ? "Нет записей" : "Выберите складскую организацию"}
                isFetching={isWarehousesFetching}
                placeholder="Склад"
                options={warehouses?.map(el => ({value: el.name!, key: el.id!}))}
                onChange={val => setRedactedBill({...redactedBill, warehouseId: val.key})}
                selectedOptionKey={redactedBill.warehouseId}
                inlineStyles={{marginBottom: 10}}
                />
            <Select 
                placeholder="Организация"
                options={organizations?.map(el =>({value: el.name!, key: el.id!}))}
                onChange={val => setRedactedBill({...redactedBill, organizationId: val.key})}
                selectedOptionKey={redactedBill.organizationId}
                inlineStyles={{marginBottom: 10}}
                />
            <Input
                placeholder="Внешний номер накладной"
                value={redactedBill.externalBillId}
                onChange={e => setRedactedBill({...redactedBill, externalBillId: e.target.value})}
                inlineStyles={{marginBottom: 10}}
                />
            <TextArea
                placeholder="Комментарий"
                onChange={e => setRedactedBill({...redactedBill, note: e.target.value})}
                value={redactedBill.note}
                inlineStyles={{marginBottom: 10}}
                />
            <MiniTitle inlineStyles={{marginBottom: 10}}>
                Доставка
            </MiniTitle>
            <Input
                placeholder="Отправитель"
                value={redactedBill.sender}
                onChange={e => setRedactedBill({...redactedBill, sender: e.target.value})}
                inlineStyles={{marginBottom: 10}}
                />
            <Select 
                placeholder="Перевозчик"
                options={transporters}
                onChange={val => setRedactedBill({...redactedBill, transporter: val.value})}
                selectedOptionKey={transporters.find(x => x.value == redactedBill.transporter)?.key}
                inlineStyles={{marginBottom: 10}}
                />
            <Input 
                placeholder="Ожидаемая дата доставки"
                type="date"
                value={redactedBill.realizeDate?.split('T')[0]}
                onChange={e => setRedactedBill({...redactedBill, realizeDate: (new Date(e.target.value).toISOString())})}
                inlineStyles={{marginBottom: 10}}
                />
            <Input
                placeholder="Количество коробов"
                value={redactedBill.boxexCount?.toString()}
                type="number"
                onChange={e => setRedactedBill({...redactedBill, boxexCount: Number(e.target.value)})}
                inlineStyles={{marginBottom: 10}}
                />
            <Input
                placeholder="Количество паллет"
                type="number"
                value={redactedBill.palletsCount?.toString()}
                onChange={e => setRedactedBill({...redactedBill, palletsCount: Number(e.target.value)})}
                inlineStyles={{marginBottom: 10}}
                />
            <TextArea
                inlineStylesContainer={{height: 100,marginBottom: 20}}
                placeholder="Коментарий"
                value={redactedBill.deliveryNote}
                onChange={e => setRedactedBill({...redactedBill, deliveryNote: e.target.value})}
                />
            {isClient && <Button 
                inlineStyles={{marginBottom: 20}}
                text="Добавить товар"
                onClick={() => {
                    setAddProductModalVisible(true);
                }}
                />}
            <List>
                {addedProducts.map((product) => 
                    <ListItem key={product.id}>
                        <img className={stylesm.img} src={getImageUrlByStrings(product.images)}/>
                        <ListItemText
                            name="Наименование:"
                            value={product.name}
                            inlineStyles={{marginBottom: 5}}
                            />
                        <ListItemText
                            name="Штрихкод:"
                            value={product.barcode}
                            inlineStyles={{marginBottom: 5}}
                            />
                        <ListItemText
                            name="Юр лицо:"
                            value={product.organizationName}
                            inlineStyles={{marginBottom: 5}}
                            />
                        <ListItemText
                            name="Цвет:"
                            value={product.color}
                            inlineStyles={{marginBottom: 5}}
                            />
                        <ListItemText
                            name="Объём:"
                            value={product.capacity}
                            inlineStyles={{marginBottom: 5}}
                            />
                        <ListItemText
                            name="Цена услуги:"
                            value={product.serviceProductPrice}
                            inlineStyles={{marginBottom: 5}}
                            />
                        <Input 
                            placeholder="Количество"
                            type="number"
                            onChange={e => 
                                setQuantities(prevState =>
                                    {   
                                        if (prevState.find(pv => pv.id == product.id)) {
                                            return prevState.map(pv => pv.id == product.id 
                                                ? {...pv, quantity: Number(e.target.value)} 
                                                : pv
                                                )
                                        }
                                        return [...prevState, {id: product.id!, quantity: Number(e.target.value)}]
                                    } 
                                    )
                                } 
                            value={quantities!.find(q => q.id == product.id)!.quantity.toString()}
                            />
                    </ListItem>
                )}
            </List>
            <MobileProductModal
                isOpen={addProductModalVisible}
                close={() => setAddProductModalVisible(false)}
                products={productsByUser}
                selectedProducts={addedProducts}
                onProductClick={(el) => {
                    if (addedProducts.map(x => x.id).includes(el.id)) {
                        setAddedProducts(prevState => prevState.filter(el2 => el2.id != el.id));
                        setQuantities(prevState => prevState.filter(el2 => el2.id != el.id));
                    }
                    else {
                        setAddedProducts(prevState => [...prevState, el]);
                        setQuantities(prevState => [...prevState, {id: el.id!, quantity: 0}])
                    }}
                }
                />
        </Layout>
    )
};

export {RedactAcceptanceBillPage};