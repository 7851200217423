import styles from "./Terminal.module.scss";
import { itemsTerminalCard } from "../consts";
import ItemCard from "../components/ItemTerminal/ItemTerminal";
import Layout from "widgets/Layout/Layout";

const TerminalPage = () => {
    return (
        <Layout>
            <div className={styles.page}>
                <div className={styles.card}>
                    <div className={styles.content}>
                        {itemsTerminalCard.map(item => (
                            <ItemCard key={item.title} item={item}/>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export {TerminalPage};