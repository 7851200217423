import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type HeaderDataForPushState = {
    isHeaderSearh: boolean;
};

const initialState: HeaderDataForPushState = {
    isHeaderSearh: false
};

export const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setIsHeaderSearch(state, action: PayloadAction<boolean>) {
            return {...state, isHeaderSearh: action.payload}
        }
    }
});

export default headerSlice.reducer;