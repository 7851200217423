import { DocumentTypeEnum } from "api";

export const getDocumentTypeName = (documentType: DocumentTypeEnum) => {
    switch(documentType) 
    {
        case 0:
            return 'Приходная накладная';
        case 1:
            return 'Отходная накладная';
        case 2:
            return 'Приходная накладная';
        case 3:
            return 'Отходная накладная';
        case 4:
            return 'Товар';
        case 5:
            return 'Cчёт';
        default:
            return 'Unknown';
    }
}