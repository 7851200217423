import { CSSProperties, ReactNode, useEffect } from "react";
import styles from "./index.module.scss";
import { BackIcon, CloseIcon, CopyIcon } from "../icons/icons";
import clsx from "clsx";
import { Loader } from "../Loader";

type Props = {
    children: ReactNode,
    title?: string | null,
    toClose?: string,
    isOpen?: boolean,
    close: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    copy?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    back?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    isCloseCloseIcon?: boolean,
    inlineStyles?: CSSProperties;
    classNames?: string;
    isLoader?: boolean;
    onOpen?: () => void;
};

const Modal = ({classNames, inlineStyles, children, close, isOpen, back, copy, isLoader, onOpen} : Props) => {

    useEffect(() => {
        if (isOpen && onOpen) {
            onOpen();
        }
    }, [isOpen])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") 
            {
                close();
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const handlerClose = (event: React.SyntheticEvent) => {
        event.preventDefault();
        close();
    };

    const handlerCopy = (event: React.SyntheticEvent) => {
        event.preventDefault();
        copy!();
    };

    const handlerBack = (event: React.SyntheticEvent) => {
        event.preventDefault();
        back!();
    };

    // todo 
    return (
        <div className={isOpen ? styles.modalWrapper : styles.modalClose} onClick={close}>
            <div onClick={e => e.stopPropagation()} className={clsx(styles.modal, classNames)} style={inlineStyles}>
                <div className={styles.header}>
                    {copy && <div className={clsx(styles.header__item, styles.copy)} onClick={(event) => handlerCopy(event)}>
                        <CopyIcon/>
                    </div>}
                    {back && <div className={clsx(styles.header__item, styles.back)} onClick={(event) => handlerBack(event)}>
                        <BackIcon/>
                    </div>}
                    <div className={clsx(styles.header__item, styles.close)} onClick={(event) => handlerClose(event)}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className={styles.scroll} style={{minHeight: isLoader ? 200 : "auto", minWidth: isLoader ? 200 : "auto"}}>
                    {children}
                    {isLoader && <Loader inlineContainerClasses={styles.loader}/>}
                </div>
            </div>
        </div>
    );
};

export {Modal};