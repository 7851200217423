export enum LinkPagesEnum {
    WarehouseScheme = '/warehouseScheme',
    WarehouseDesigner = '/warehouseDesigner',
    Support = '/support',
    AddingWarehouse = '/addingWarehouse',
    InformationBase = '/informationBase',
    RedactProduct = '/redactProduct',
    AddingProduct = '/addingProduct',
    Calender = '/calendar',
    Analytics = '/analytics',
    Integrations = '/integrations',
    Consumables = '/consumables',
    Organizations = '/organizations',
    Cooperations = '/cooperations',
    PackageProcess = '/packageProcess',
    Package = '/package',
    RedactPlacementBill = '/redactPlacementBill',
    RedactPackageBill = '/redactPackageBill',
    PlacementBills = '/placementBills',
    AddingPlacementBill = '/addingPlacementBill',
    PackageBills = '/packageBills',
    AddingPackageBill = '/addingPackageBill',
    AddingKit = '/addingKit',
    PaymentBill = '/paymentBill',
    PaymentBills = '/paymentBills',
    Home = '/',
    Documents = '/reports',
    AcceptanceBills = '/acceptanceBills',
    AddingAcceptanceBill = '/addingAcceptanceBill',
    RedactAcceptanceBill = '/redactAcceptanceBill',
    Products = '/products',
    Inventory = '/inventory',
    Cells = '/cells',
    Terminal = '/terminal',
    Inspection = '/inspection',
    ShipmentBills = '/shipmentBills',
    Settings = '/settings',
    UserProfile = '/profile',
    Placement = '/placement',
    PlacementProcess = '/placementProcess',
    AddingShipmentBill = '/addingShipmentBill',
    RedactShipmentBill = '/redactShipmentBill',
    Ship = '/ship',
    Employees = '/employees',
    Users = '/users',
    UsersAccess = '/usersAccess',
    Soon = '/soon',
    Information = '/information',
    Processes = '/processes',
    ShipProcess = '/shipProcess',
    InspectionProcess = '/inspectionProcess',
    Registration = '/registration',
    Authorization = '/authorization',
}