import { BaseSharedComponentType } from "types";
import styles from "./index.module.scss";
import clsx from "clsx";

type Props = {
    inlineContainerClasses?: string;
    backgroundColor?: boolean;
} & BaseSharedComponentType;

const Loader = ({inlineStyles, inlineClasses, inlineContainerClasses, backgroundColor}: Props) => {
    
    return (
        <div className={clsx(styles.container, backgroundColor && styles.container_backgroundColor, inlineContainerClasses)}>
            <span className={clsx(styles.loader, inlineClasses)} style={inlineStyles}></span>
        </div>
    );
};

export {
    Loader
};