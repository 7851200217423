import clsx from "clsx";
import { ReactNode } from "react";
import styles from "./index.module.scss";
import { BaseSharedComponentType } from "types";

type Props = {
    children: ReactNode;
} & BaseSharedComponentType;

const ButtonsContainer = ({children, inlineStyles, inlineClasses}: Props) => (
    <div className={clsx(styles.container, inlineClasses)} style={inlineStyles}>
        <div style={{display: "flex", width: "max-content"}}>
            {children}
        </div>
    </div>
);

export {
    ButtonsContainer
}