export const getMinutesBetweenDates = (startDate: Date, endDate: Date) => {
    const diff = endDate.getTime() - startDate.getTime();

    return Math.round(diff / 60000);
}

export const getLocalDate = (dateString?: string | null | undefined) => {
    if (dateString == null) {
        return "-";
    }

    return (new Date(dateString)).toLocaleDateString();
}

export const getLocalDateTime = (dateString?: string | null | undefined) => {
    if (dateString == null) {
        return "-";
    }

    return (new Date(dateString)).toLocaleString();
}

export const getDayName = (day: number | Date) => {
    var weekday=new Array(7);
    weekday[0]="Воскресенье";
    weekday[1]="Понедельник";
    weekday[2]="Вторник";
    weekday[3]="Среда";
    weekday[4]="Четверг";
    weekday[5]="Пятница";
    weekday[6]="Суббота";

    if (typeof day === "number") {
        return weekday[day];
    }

    return weekday[day.getDay()];
}

export const getDayShortName = (day: number | Date) => {
    var weekday=new Array(7);
    weekday[0]="Вс";
    weekday[1]="Пн";
    weekday[2]="Вт";
    weekday[3]="Ср";
    weekday[4]="Чт";
    weekday[5]="Пт";
    weekday[6]="Сб";
    
    if (typeof day === "number") {
        return weekday[day];
    }

    return weekday[day.getDay()];
}

export const getMonthDays = (date: Date, isShortNames?: boolean) => {
    let array: {number: number, weekDayNumber: number; weekDayName: string, date: Date}[] = [];

    const date2 = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    for (let index = 1; index < date2.getDate() + 1; index++) {
        let date3 = new Date(date.getFullYear(), date.getMonth(), index);
        let day = (date3).getDay();

        array.push({number: index, weekDayNumber: day, weekDayName: isShortNames ? getDayShortName(day) : getDayName(day), date: date3});
    }

    return array;
}